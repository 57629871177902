import { ITicketWizard, TicketWizardKey } from './ticket-wizard';
import { NavigationInstruction, RouteConfig } from 'aurelia-router';
import { RedirectToRoute } from 'aurelia-router';
import { autoinject, inject } from "aurelia-framework";
import { DateTime } from "luxon";
import { TicketConfigAccessor } from './ticket-config';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';

@autoinject()
export class Flow {
    flowGroupId?: string;
    booking!: Booking;
    flowAliases!: string[];

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private config: TicketConfigAccessor) {
    }

    canActivate(params: { booking: string }, _config: RouteConfig, navigationInstruction: NavigationInstruction) {
        this.booking = this.bookingSerializer.deserialize(params.booking);

        // Find the flows that matches the region for all tickets up until now
        // Only consider the two first regions when filtering
        const regionIds = this.booking.getIncludedRegions().slice(0, 2);

        this.flowGroupId = this.wizard.params.getFlowGroup(navigationInstruction);
        this.flowAliases = this.wizard.config.value.getCandidateFlowAliases(regionIds, this.flowGroupId);

        if (this.flowGroupId) {
            const candidateFlows = this.wizard.config.value.getCandidateFlows(regionIds, this.flowGroupId);
            if (candidateFlows.length === 1) {
                this.setFlow(candidateFlows[0].icon);

                const [route, params] = this.wizard.getNextStep(this.booking, navigationInstruction);
                return new RedirectToRoute(route, params);
            }
        }

        return true;
    }

    private setFlow(flowAlias: string) {
        const flow = this.booking.getPreferredFlowByAlias(flowAlias);
        let tickets = this.booking.getTickets();

        // Remove all tickets that are not included by the flow
        if (tickets.length > flow.regionIds.length / 2) {
            const ticketIndex = flow.regionIds.length / 2;
            const noOfMismatchTickets = tickets.length = ticketIndex;
            this.booking.remove(ticketIndex, noOfMismatchTickets);
            tickets = this.booking.getTickets();
        }

        // Remove all tickets after the first one that has mismatch between flow and ticket
        for (let fromRegionIndex = 0; fromRegionIndex < Math.min(flow.regionIds.length, tickets.length * 2); fromRegionIndex += 2) {
            const ticketIndex = fromRegionIndex / 2;
            const flowFromRegionId = flow.regionIds[fromRegionIndex];
            const flowToRegionId = flow.regionIds[fromRegionIndex + 1];
            const ticketFromRegionId = tickets[ticketIndex].fromRegionId;
            const ticketToRegionId = tickets[ticketIndex].toRegionId;

            if (flowFromRegionId !== ticketFromRegionId || flowToRegionId !== ticketToRegionId) {
                // There is a mismatch between the selected flow and the current tickets
                const noOfMismatchTickets = tickets.length - ticketIndex;
                this.booking.remove(ticketIndex, noOfMismatchTickets);
                tickets = this.booking.getTickets();
                break;
            }
        }

        const nextFromRegionIndex = tickets.length * 2;

        for (let fromRegionIndex = nextFromRegionIndex; fromRegionIndex < flow.regionIds.length - 1; fromRegionIndex+=2) {
            const fromRegionId = flow.regionIds[fromRegionIndex];
            const toRegionId = flow.regionIds[fromRegionIndex + 1];
            this.booking.addContinuationTicket(fromRegionId, toRegionId);
        }
        // Re-get tickets after possible addition of continuation tickets.
        tickets = this.booking.getTickets();

        // Determine if departure date should be set for all incomplete tickets to a value offset by the last set departure date.
        if (this.flowGroupId) {
            const flowGroup = this.wizard.config.value.getFlowGroup(this.flowGroupId);
            const dayOffset = flowGroup.forceReturnDaysAfterOutDate;
            const firstIncompleteTicketIndex = this.booking.indexOfFirstIncompleteTicket();
            // Strictly larger so that we know that there is at least one complete ticket.
            if (firstIncompleteTicketIndex > 0 && typeof dayOffset === "number" && dayOffset >= 0) {
                const lastCompleteTicketIndex = firstIncompleteTicketIndex - 1;
                const lastCompleteTicket = tickets[lastCompleteTicketIndex];
                const departureDate = lastCompleteTicket.departureDate.plus({ days: dayOffset });
                for (let i = firstIncompleteTicketIndex; i < tickets.length; i++) {
                    const ticket = this.booking.getTicket(i);
                    ticket.departureDate = departureDate;
                    ticket.departureId = undefined;                  
                }
            }
        }

        this.booking.askForFlow = false;
    }

    selectFlow(flowAlias: string) {
        this.setFlow(flowAlias);

        return this.wizard.navigateToNextStep(this.booking);
    }
}