import { autoinject } from "aurelia-framework";
import { getParameter } from "./query-string";
import { I18N } from "aurelia-i18n";
import { LocaleId, LOCALES } from "./locales";

export const EA_I18N_LOCALE_CHANGED = "i18n:locale:changed";

@autoinject()
export class LocaleService {
    locale: string = "en";

    constructor(private i18n: I18N) {
    }

    autodetect() {
        const locale = (getParameter('lang') || window.navigator.language || (<any>window.navigator).userLanguage).substr(0, 2);
        if (locale && isSupportedLocale(locale)) {
            this.locale = locale;
        }

        return this.locale;
    }

    setLanguage(localeId: LocaleId) {
        this.locale = localeId;
        return this.i18n.setLocale(localeId);
    }
}

function isSupportedLocale(id: string) {
    return !!LOCALES.find(x => x.id === id);
}