import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { MessageModel } from '../../models/message-model';
import { OrdersApi } from '../../api/orders-api';
import { LogApi } from '../../api/log-api';
import { BookingFactory } from '../../booking-factory';
import { BookingSerializer } from '../../booking-serializer';
import lowercase from '../../lowercase';
import { OnPayApi } from '../../api/onpay-api';


@autoinject()
export class PaymentErrorOnPayComponent {

    packagekey!: string;
    onpay_amount!: number;
    message!: MessageModel;
    paymethod: string | undefined;

    constructor(private router: Router, private messageModel: MessageModel, private i18n: I18N, private log: LogApi,
        private ordersApi: OrdersApi, private onpayApi: OnPayApi, private bookingFactory: BookingFactory, private bookingSerializer: BookingSerializer) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async activate(params: { packagekey: string, paymethod: string, onpay_amount: number, onpay_acquirercode: string, onpay_errorcode: string }) {
        params = lowercase(params);

        this.log.LogOrder(params.packagekey, 
            "Params: packagekey=" + params.packagekey 
            + ", paymethod=" + params.paymethod               
            + ", onpay_amount=" + params.onpay_amount     
            + ", onpay_acquirercode=" + params.onpay_acquirercode
            + ", onpay_errorcode=" + params.onpay_errorcode, 
            "payment-error-onpay", 1);

        const newOrder = await this.ordersApi.getBaseInfo(params.packagekey);

        this.onpay_amount = params.onpay_amount;
        this.paymethod = params.paymethod;

        //  Tjek på parameter when user cancelled

        if (newOrder !== undefined && newOrder.status !== 'missing' && newOrder.status !== 'deleted') {

            this.packagekey = params.packagekey;

            // if(params.actioncode && params.actioncode == 18)
            // {
            //     this.message = new MessageModel("warning",
            //     this.i18n.tr('checkout:payment-error.message.payment-actioncode-18.header', { orderId: newOrder.orderId }),
            //     this.i18n.tr('checkout:payment-error.message.payment-actioncode-18.text'));
            // }
            // else
            // {
            this.message = new MessageModel("warning",
                this.i18n.tr('checkout:payment-error.message.payment-declined.header', { orderId: newOrder.orderId }),
                this.i18n.tr('checkout:payment-error.message.payment-declined.text'));
            //}

            this.log.LogOrder(params.packagekey, "order.status=" + newOrder.status + ", Guest got WARNING message.header=" + this.message.header, "payment-error-onpay", 6);  
        }
        else {
            this.message = new MessageModel("error",
                this.i18n.tr('checkout:payment-error.message.no-package.header'),
                this.i18n.tr('checkout:payment-error.message.no-package.text'));

            this.log.LogOrder(params.packagekey, "package-missing or deleted, Guest got ERROR message.header=" + this.message.header, "payment-error-onpay", 6);  
        }
    }

    async goTickets() {

        const ticketsFromOrder = await this.ordersApi.getTickets(this.packagekey);
        const booking = this.bookingFactory.load(ticketsFromOrder.ticketsFromOrder);

        return this.router.navigateToRoute("summary", { booking: this.bookingSerializer.serialize(booking) });

    }

    async goNext() {

        await this.onpayApi.PostOnPay(this.packagekey, false, this.paymethod);
    }
}  
