import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { DateTime } from "luxon";
import { jsonProperty } from "ur-json";
import { Http } from "ur-http";
import { luxonConverter } from "./converters/luxon-converter";

@autoinject()
export class TimeTableApi {
    constructor(private client: ApiClient) {
    }

        // takes guestId from login  
        async getTimeTable(date: DateTime) {
            return Http.get('/timetable/days/' + date.toISODate())
            .expectJsonArray(TimeTableCrossing)
            .transfer();
        }
    }

    export class TimeTableDeparture {
        @jsonProperty()
        public departureId!: number;
        @jsonProperty({converter: luxonConverter})
        public depart!: DateTime;
        @jsonProperty({converter: luxonConverter})
        public arrival!: DateTime;
        @jsonProperty()
        public availableCars!: number;  
        @jsonProperty()
        public availablePax!: number;  
        @jsonProperty()
        public maxPax!: number;  
        @jsonProperty()
        public showAvailableCars!: boolean;
        @jsonProperty()
        public showAvailablePersons!: boolean;
        @jsonProperty()
        public showBookedPax!: boolean;        
        @jsonProperty()
        public note!: string;
        @jsonProperty()
        public bookUrl!: string;
        @jsonProperty()
        public cssClass!: string;
        @jsonProperty()
        public isDangerousGoods!: boolean;
    }

    export class TimeTableCrossing {
        @jsonProperty()
        ferryRouteId!: number;
        @jsonProperty()
        crossingName!: string;     
        @jsonProperty({type: TimeTableDeparture})
        departures!: TimeTableDeparture[];
    }
    
   
