import { clone, every, last, reject, flatten, isEqual, orderBy } from "lodash-es";

import { DateTime } from "luxon";
import { TicketConfigAccessor } from "./ticket-designer/ticket-config";

export class Booking {
    private tickets: Ticket[] = [];
    email?: string;
    name?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    carRegistration?: string;
    couponCode?: string;
    newsLetter?: boolean;
    termsAccepted?: boolean;
    note?: string;
    clipAsPrice0?: boolean;
    cvr?: string;
    onlyReservation?: boolean;
    onlyOnAccount?: boolean;
    askForFlow: boolean = false;

    get length() {
        return this.tickets.length;
    }

    get isComplete() {
        return this.tickets.length > 0 && every(this.tickets.map(x => x.isComplete));
    }

    constructor(private config: TicketConfigAccessor, ...tickets: { departureDate?: DateTime, transportId?: number, trailerId?: number, totalWeights?: number; passengers?: { [id: string]: number }, toRegionId?: string, fromRegionId?: string, departureId?: number, disabled?: boolean, cancelled?: boolean, waitListPriority?: number }[]) {
        if (tickets && tickets.length) {
            for (const ticket of tickets) {
                this.addTicket(ticket);
            }
        }
    }

    addTicket(ticketInit?: { departureDate?: DateTime, transportId?: number, trailerId?: number, totalWeights?: number; passengers?: { [id: string]: number }, meals?: { [id: string]: number }, toRegionId?: string, fromRegionId?: string, departureId?: number, disabled?: boolean, cancelled?: boolean, waitListPriority?: number }) {
        const ticket = ticketInit
            ? new Ticket(this.config, ticketInit.departureDate, ticketInit.transportId, ticketInit.trailerId, ticketInit.totalWeights, ticketInit.passengers, ticketInit.meals, ticketInit.fromRegionId, ticketInit.toRegionId, ticketInit.departureId, ticketInit.disabled, ticketInit.cancelled, ticketInit.waitListPriority)
            : new Ticket(this.config);

        this.tickets.push(ticket);
        return ticket;
    }

    addContinuationTicket(fromRegionId: string, toRegionId: string) {
        const previous = last(this.tickets);

        if (!previous) {
            throw new Error("There is no previous ticket to continue");
        }
        //  previous isComplete = false
        //const ticket = previous.createCopy(fromRegionId, toRegionId);
        const ticket = this.tickets[0].createCopy(fromRegionId, toRegionId);

        this.tickets.push(ticket);

        return ticket;
    }

    remove(index: number, count = 1) {
        this.tickets.splice(index, count);
    }

    getTicket(index: number) {
        return this.tickets[index];
    }

    getTickets() {
        return this.tickets.map(x => {
            return {
                departureDate: x.departureDate,
                transportId: x.transportId,
                trailerId: x.trailerId,
                totalWeights: x.totalWeights,
                passengers: clone(x.passengers),
                meals: clone(x.meals),
                fromRegionId: x.fromRegionId,
                toRegionId: x.toRegionId,
                departureId: x.departureId,
                disabled: x.disabled,
                cancelled: x.cancelled,
                waitListPriority: x.waitListPriority
            };
        });
    }

    getIncludedRegions(): string[] {
        const regionIds = <string[]>flatten(this.tickets.map(x => [x.fromRegionId, x.toRegionId])).filter(x => !!x);

        return regionIds;
    }

    getPreferredFlowByAlias(alias: string) {
        const flows = this.config.value.flows
            .filter(x => x.icon === alias);

        if (flows.length === 0) {
            throw new Error(`Unable to find any flows with alias '${alias}'`);
        }

        const regionIds = <string[]>flatten(this.tickets.map(x => [x.fromRegionId, x.toRegionId])).filter(x => !!x);

        const lastTicket = last(this.tickets);
        if (lastTicket && lastTicket.toRegionId) {
            regionIds.push(lastTicket.toRegionId);
        }

        const prioritizedFlows = orderBy(flows, x => indexOfNotSame(x.regionIds, regionIds)).reverse();
        return prioritizedFlows[0];

        function indexOfNotSame<T>(a: T[], b: T[]) {
            for (var i = 0; i < Math.min(a.length, b.length); i++) {
                if (a[i] !== b[i]) {
                    return i;
                }
            }

            return a.length === b.length ? -1 : i;
        }
    }

    indexOfFirstIncompleteTicket() {
        for (let i = 0; i < this.tickets.length; i++) {
            const ticket = this.tickets[i];

            if (!ticket.isComplete) {
                return i;
            }
        }

        return -1;
    }

    areFullSelected() {
        return !(this.tickets.filter(x => x.departureId === undefined).length > 0);
    }
}

class Ticket {
    private _transportId?: number;
    public departureDate: DateTime;
    public passengers: { [id: number]: number };
    public meals: { [id: number]: number };

    get transportId() {
        return this._transportId;
    }

    get isComplete() {

        let trailerIsValid: Boolean = this.trailerId !== undefined;
        let totalWeightsIsValid: Boolean = this.totalWeights !== undefined;
        let passengersAreValid: Boolean = this.passengers !== undefined;

        if (this.transportId) {

            const transport = this.config.value.getTransport(this.transportId);

            if (!trailerIsValid) {
                trailerIsValid = transport.enabledTrailerIds.length === 0;
            }

            if (!totalWeightsIsValid) {
                totalWeightsIsValid = !transport.weightId || this.totalWeights !== undefined;
            }

            if (!passengersAreValid) {
                passengersAreValid = transport.enabledPassengerIds.length === 0;
            }
        }

        return this.departureDate.isValid && this.transportId !== undefined && trailerIsValid && totalWeightsIsValid && passengersAreValid && this.departureId !== undefined;
    }

    constructor(private config: TicketConfigAccessor, departureDate?: DateTime, transportId?: number, public trailerId?: number, public totalWeights?: number, passengers?: { [id: string]: number }, meals?: { [id: string]: number }, public fromRegionId?: string, public toRegionId?: string, public departureId?: number, public disabled?: boolean, public cancelled?: boolean, public waitListPriority?: number) {
        this._transportId = transportId;
        this.departureDate = departureDate || DateTime.invalid("undefined");
        this.passengers = passengers ? JSON.parse(JSON.stringify(passengers)) : undefined;
        this.meals = meals ? JSON.parse(JSON.stringify(meals)) : {};
    }

    setTransportId(id: number) {
        this._transportId = id;

        const transport = this.config.value.getTransport(id);

        if (this.trailerId && !transport.enabledTrailerIds.includes(this.trailerId)) {
            delete this.trailerId;
        }

        if (!this.trailerId && transport.enabledTrailerIds.length === 1) {
            this.trailerId = transport.enabledTrailerIds[0];
        }

        delete this.totalWeights;

        if (this.passengers) {
            for (const passengerId in this.passengers) {
                if (!transport.enabledPassengerIds.includes(parseInt(passengerId))) {
                    delete this.passengers;
                    this.meals = {};
                    break;
                }
            }
        }
    }

    setDeparture(departure: { id: number, fromRegionId: string, toRegionId: string, departs: DateTime }) {
        this.departureId = departure.id;
        this.fromRegionId = departure.fromRegionId;
        this.toRegionId = departure.toRegionId;
        this.departureDate = departure.departs.startOf("day");
    }

    clearTransportId() {

        if (this._transportId) {

            const transport = this.config.value.getTransport(this._transportId);

            if (this.passengers) {
                for (const passengerId in this.passengers) {
                    if (!transport.enabledPassengerIds.includes(parseInt(passengerId))) {
                        delete this.passengers;
                        this.meals = {};
                        break;
                    }
                }
            }

            delete this._transportId;
            delete this.trailerId;
            delete this.totalWeights;
        }
    }

    createCopy(fromRegionId: string, toRegionId: string) {

        if (!this.isComplete) {
            throw new Error("The ticket is incomplete");
        }

        // return new Ticket(this.config, DateTime.invalid("undefined"), this.transportId, this.trailerId, this.totalWeights, clone(this.passengers), fromRegionId, toRegionId);
        
        const pass = this.totalWeights !== undefined ? undefined : clone(this.passengers);
        
        return new Ticket(this.config, DateTime.invalid("undefined"), this.transportId, this.trailerId, undefined, pass, clone(this.meals), fromRegionId, toRegionId);
    }
}