import { autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { OpenIdConnect } from "aurelia-open-id-connect";
import { GuestApi } from "../api/guest-api";
import { LocaleService } from "../locale-service";
import { LocaleId } from "../locales";

@autoinject()
export class Settings {
    userIsAuthenticated: boolean = false;
    email: string = "";
    name: string = "";
    selectedLanguage!: LocaleId;
    
    constructor(private router: Router, private oidc: OpenIdConnect, private guestApi: GuestApi, 
        private localeService: LocaleService) {
    }

    async activate() {

        const user = await this.oidc.getUser();

        if (user && !user.expired && user.access_token) {
            this.userIsAuthenticated = true;

            const guest = await this.guestApi.getCurrentUser();

            if(guest && guest.mail){
                //  User exist in database
                this.email = guest.mail;
                this.name = guest.name;
                this.selectedLanguage = guest.language;
       
            }
        }
    }

    async submit() {

        await this.guestApi.updateGuest(this.selectedLanguage);

        this.localeService.setLanguage(this.selectedLanguage);

        return this.router.navigateToRoute("dashboard");

        }
    }

