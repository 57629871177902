import { autoinject, bindable, computedFrom } from "aurelia-framework";

import { DateTime } from 'luxon';
import { DeparturesApi } from '../../api/departures-api';
import { Router } from "aurelia-router";
import { defaults } from "lodash-es";

@autoinject()
export class CrossingSummaryCustomElement {
    @bindable()
    departureId?: number;

    @bindable()
    href?: string;

    @bindable()
    ticketIndex?: number;

    imageUrl?: string;

    constructor(private router: Router, private departuresApi: DeparturesApi) {
    }

    attached() {
        if (!this.href && typeof this.ticketIndex === "number" && this.ticketIndex >= 0) {
            //  used only on summary page
            const params = defaults({
                ticketIndex: this.ticketIndex
            }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
        
            this.href = this.router.generate("departure", params);
        }

        this.departureIdChanged();
    }

    departureIdChanged() {
        if (this.departureId) {
            this.departuresApi.getDepartureBaseInfo(this.departureId).then(departure => {
                this.imageUrl = "https://tbferry.blob.core.windows.net/icons/routes/default/" + departure.icon;
                // this.imageUrl = require("./crossings/" + departure.icon);
            });
        }
    }
}