import { autoinject, bindable, Disposable } from "aurelia-framework";

import { Router } from "aurelia-router";
import { TicketConfigAccessor } from "../ticket-config";
import { defaults } from "lodash-es";
import { EventAggregator } from "aurelia-event-aggregator";
import { PassengerCountChanged } from "../notifications/passenger-count-changed";

@autoinject()
export class PassengersSummaryCustomElement {
    private disposables!: Disposable[];
    
    @bindable({changeHandler: "changeHandler"})
    transportId?: number;

    @bindable({changeHandler: "changeHandler"})
    passengers?: {[id: number]: number};

    @bindable()
    href?: string;

    @bindable()
    ticketIndex?: number;

    @bindable()
    selected = false;

    items: ItemViewModel[] = [];
    anyPassengers?: boolean;

    constructor(private router: Router, private config: TicketConfigAccessor, private eventAggregator: EventAggregator) {
    }

    attached() {
        if (!this.href && typeof this.ticketIndex === "number" && this.ticketIndex >= 0) {
            const params = defaults({
                ticketIndex: this.ticketIndex
            }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
        
            this.href = this.router.generate("passengers", params);
        }

        this.disposables = [
            this.eventAggregator.subscribe(PassengerCountChanged, (event: PassengerCountChanged) => {
                if (event.ticketIndex === this.ticketIndex) {
                    const item = this.items.find(x => x.id === event.passengerId);

                    if (item) {
                        item.count = event.newCount;
                        this.updateAnyPassengers();
                    }
                }
            })
        ];
    }

    detached() {
        for (const disposable of this.disposables) {
            disposable.dispose();
        }
    }

    changeHandler() {
        console.log("Invoking changeHandler()")
        if (this.transportId) {
            const transport = this.config.value.getTransport(this.transportId);

            const items: ItemViewModel[] = [];
            for (const id of transport.enabledPassengerIds) {
                const icon = this.config.value.getPassenger(id).icon;
                const count = this.passengers && id in this.passengers && this.passengers[id] ? this.passengers[id] : 0;
                items.push({
                    id: id,
                    icon: icon,
                    count: count
                });
            }

            this.items = items;
            this.updateAnyPassengers();
        }
    }

    private updateAnyPassengers() {
        this.anyPassengers = this.items.findIndex(x => x.count > 0) >= 0;
    }
}

interface ItemViewModel {
    id: number;
    icon: string;
    count: number;
}