import { DateTime } from "luxon";
import { isEqual, groupBy, uniqBy, flatten, uniq } from "lodash-es";

export class TicketConfig {
    flowGroups!: {
        id: string;
        forceReturnDaysAfterOutDate?: number;
        autoFillNextDepart: boolean;          
        hideDeparturePrice: boolean;        
        flowIds: number[];
        blockedPeriods: BlockedPeriod[];
    }[];
    transports!: {
        id: number;
        groupId: string;
        groupIsPublic: boolean;
        visible: boolean;
        icon: string;
        height?: number | [number, number];
        length?: number | [number, number];
        weight?: number;
        enabledTrailerIds: number[];
        enabledPassengerIds: number[];
        isMenuOnly: boolean;
        driverIsMandatory: boolean;
        minWeight: number;
        weightId: number;
        weightIcon: string;
        trailerTitleAbbrev: string;
        blockedPeriods: BlockedPeriod[];
    }[];
    trailers!: {
        id: number;
        groupId: string;
        visible: boolean;
        icon: string;
        height?: number | [number, number];
        length?: number | [number, number];
    }[];
    passengers!: {
        id: number;
        groupId: string;
        visible: boolean;
        icon: string;
        isDriver: boolean;
        minQty: number;
        maxQty?: number
    }[];
    flows!: {
        id: number;
        icon: string;
        regionIds: string[];
        isFinal: boolean;
    }[];
    firstDepartureDate!: DateTime;
    lastDepartureDate!: DateTime;

    constructor(init: Partial<TicketConfig> | {firstDepartureDate: string,  lastDepartureDate: string }) {
        Object.assign(this, init);
        if (typeof init.firstDepartureDate === "string") {
            this.firstDepartureDate = DateTime.fromISO(init.firstDepartureDate);
        }
        if (typeof init.lastDepartureDate === "string") {
            this.lastDepartureDate = DateTime.fromISO(init.lastDepartureDate);
        }

        for (const flowGroup of this.flowGroups) {
            flowGroup.blockedPeriods = flowGroup.blockedPeriods.map(x => new BlockedPeriod(x));
        }
        
        for (const transport of this.transports) {
            transport.blockedPeriods = transport.blockedPeriods.map(x => new BlockedPeriod(x));
        }
    }

    getTransport(id: number) {
        const transport = this.transports.find(x => x.id === id);

        if (!transport) {
            throw new Error(`Unable to find transport '${id}'`);
        }

        return transport;
    }

    getTrailer(id: number) {
        const trailer = this.trailers.find(x => x.id === id);

        if (!trailer) {
            throw new Error(`Unable to find trailer '${id}'`);
        }

        return trailer;
    }

    getPassenger(id: number) {
        const passenger = this.passengers.find(x => x.id === id);

        if (!passenger) {
            throw new Error(`Unable to find passenger '${id}'`);
        }

        return passenger;
    }

    getFlow(id: number) {
        const flow = this.flows.find(x => x.id === id);

        if (!flow) {
            throw new Error(`Unable to find flow '${id}'`);
        }

        return flow;
    }

    getFlowGroup(id: string) {
        const flowGroup = this.flowGroups.find(x => x.id === id);

        if (!flowGroup) {
            throw new Error(`Unable to find flow group '${id}'`);
        }

        return flowGroup;
    }

    getCandidateFlows(includedRegionIds: string[], flowGroupId?: string) {
        const flowGroup = this.flowGroups.find(x => x.id === flowGroupId);

        return this.flows
            .filter(x => !flowGroup || flowGroup.flowIds.includes(x.id))
            .filter(x => isEqual(includedRegionIds, x.regionIds.slice(0, includedRegionIds.length)));
    }

    getCandidateFlowAliases(includedRegionIds: string[], flowGroupId?: string) {
        const flows = this.getCandidateFlows(includedRegionIds, flowGroupId);

        return uniq(flows.map(x => x.icon));
    }

    getFlowGroupFromRegionSequence(regionIds: string[]) {

        let flowId: number = 1;

        if(this.flows){
            if(regionIds.length === 1){
                const flowStep0 = this.flows.find(x => x.regionIds[0] === regionIds[0]);
                if(flowStep0)
                    flowId = flowStep0.id;
            }
            else if(regionIds.length === 2){
                const flowStep01 = this.flows.find(x => x.regionIds[0] === regionIds[0] && x.regionIds[1] === regionIds[1]);
                if(flowStep01)
                    flowId = flowStep01.id;
            }
            else if(regionIds.length === 4){
                const flowStep23 = this.flows.find(x => x.regionIds[0] === regionIds[0] && x.regionIds[1] === regionIds[1]
                    && x.regionIds[2] == regionIds[2] && x.regionIds[3] === regionIds[3]);
                if(flowStep23)
                    flowId = flowStep23.id;
            }
            else if(regionIds.length === 6){
                const flowStep45 = this.flows.find(x => x.regionIds[0] === regionIds[0] && x.regionIds[1] === regionIds[1]
                    && x.regionIds[2] == regionIds[2] && x.regionIds[3] === regionIds[3]
                    && x.regionIds[4] == regionIds[4] && x.regionIds[5] === regionIds[5]);
                if(flowStep45)
                    flowId = flowStep45.id;
            }
        }

        if(!flowId){
            throw new Error(`Unable to find flow from regions`);
        }

        return this.flowGroups.find(x => x.flowIds.includes(flowId));
    }

    getFirstDepartureDate(){
        return this.firstDepartureDate;
    };

    getLastDepartureDate(){
        return this.lastDepartureDate;
    };

    arePassengersValid(transportId: number, passengers: {[passengerId: number]: number}) {
        const transport = this.getTransport(transportId);

        if (!transport.driverIsMandatory) {
            return true;
        }

        for (const passengerId in passengers) {
            const passenger = this.getPassenger(parseInt(passengerId));
            
            if (passenger.isDriver && passengers[passengerId] > 0) {
                return true;
            }
        }

        return false;
    }
}

export class TicketConfigAccessor {
    value!: TicketConfig;
}

export class BlockedPeriod {
    public startDate!: DateTime;
    public endDate!: DateTime;

    constructor(init: Partial<BlockedPeriod> | { startDate: string, endDate: string }) {
        Object.assign(this, init);
        if (typeof init.startDate === "string") {
            this.startDate = DateTime.fromISO(init.startDate);
        }
        if (typeof init.endDate === "string") {
            this.endDate = DateTime.fromISO(init.endDate);
        }
    }
}