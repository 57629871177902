import { Booking } from "../booking";
import { BookingSerializer } from '../booking-serializer';
import { autoinject, inject } from "aurelia-framework";
import { RedirectToRoute, RouteConfig, Router } from "aurelia-router";
import { DeparturesApi } from "../api/departures-api";
import { MessageModel } from "../models/message-model";
import { ITicketWizard, TicketWizardKey } from "./ticket-wizard";
import lowercase from "../lowercase";
import { OrdersApi } from "../api/orders-api";
import { I18N } from "aurelia-i18n";
import { DateTime } from "luxon";

@autoinject()
export class Meal {
    private booking!: Booking;
    currency!: string;
    message: MessageModel;
    mealDepartures: MealDeparturesViewModel[] = [];

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private router: Router,        
        private departuresApi: DeparturesApi, private ordersApi: OrdersApi, private i18n: I18N) {
            this.message = new MessageModel('hide', undefined, undefined)
    }

    async canActivate(params: {booking: string}) {
        this.booking = this.bookingSerializer.deserialize(params.booking);

        for (let ticket of this.booking.getTickets()) {
            if(ticket.departureId !== undefined) {
                let meals = await this.departuresApi.getMeals(ticket.departureId);
                if(meals.length > 0){
                    return true;
                }
            }
        }

        return new RedirectToRoute("contact", { booking: params.booking});
    }

    async activate(params: {packagekey: string,  booking: string }, _config: RouteConfig) {
        params = lowercase(params);

        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppMealsInfoMessage');

        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }

        const orderLocale = this.ordersApi.locale(params.packagekey);
        this.currency = (await orderLocale).currency;

        this.booking = this.bookingSerializer.deserialize(params.booking);

        for (let ticket of this.booking.getTickets()) {
            if(ticket.departureId !== undefined){
                let mealsMenu = await this.departuresApi.getMeals(ticket.departureId);

                const meals = mealsMenu.map(x => {
                    return {
                        mealId: x.mealId,
                        txt: x.txt,
                        description: x.description,
                        icon: x.icon,
                        price: x.price,
                        quantity: 0,
                        possibleIncrease: x.possibleIncrease
                    };
                });

                for(let meal of meals)
                {
                    meal.quantity = ticket.meals[meal.mealId] !== undefined ? ticket.meals[meal.mealId] : 0;
                }

                if(meals.length > 0){

                    const depBaseInfo = await this.departuresApi.getDepartureBaseInfo(ticket.departureId);

                    this.mealDepartures.push({
                        departureId: ticket.departureId,
                        departs: depBaseInfo.departs,
                        crossing: depBaseInfo ? depBaseInfo.crossing : "",
                        meals: meals
                    });
                }
            }
        }
    }

    stepUp(departureId: number, mealId: number)
    {
        for(let departure of this.mealDepartures.filter(x => x.departureId == departureId))
        {
            for(let meal of departure.meals.filter(x => x.mealId == mealId))
            {
                    meal.quantity += 1;
            }
        }
    }

    stepDown(departureId: number, mealId: number)
    {
        for(let departure of this.mealDepartures.filter(x => x.departureId == departureId))
        {
            for(let meal of departure.meals.filter(x => x.mealId == mealId))
            {
                    meal.quantity -= 1;
            }
        }
    }

    async next() {

        //  Rasmus
        let tickets = this.booking.getTickets();
        let i = 0;
        for(let tick of tickets){

            const ticket = this.booking.getTicket(i);

            if(ticket.departureId != undefined){

                const mealDepartures = this.mealDepartures.find(x => x.departureId == ticket.departureId);//.meals;

                if(mealDepartures && mealDepartures.meals.length > 0){

                    ticket.meals = {};

                    for(let meal of mealDepartures.meals.filter(x => x.quantity > 0)){

                        ticket.meals[meal.mealId] = meal.quantity;
                    }

                }

            }

            i ++;
        }

        await this.wizard.navigateToNextStep(this.booking);
    }

    
}

export interface MealDeparturesViewModel {
    departureId: number;
    departs: DateTime;
    crossing: string;
    meals:  MealViewModel[];
}

export interface MealViewModel {
    mealId: number;
    txt: string;
    description: string;
    icon: string;
    price: number;
    quantity: number;
    possibleIncrease: boolean;
}


