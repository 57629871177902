import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { defaults } from "lodash-es";

@autoinject()
export class WizardI18N {
    constructor(private i18n: I18N) {
    }

    tr(key: string, options?: any) {
        options = defaults({}, options);

        if (options.fromRegionId) {
            defaults(options, {
                context: "crossing",
                fromRegion: this.i18n.tr(`region:${options.fromRegionId}`)
            });
        }

        if (options.toRegionId) {
            defaults(options, {
                toRegion: this.i18n.tr(`region:${options.toRegionId}`)
            });
        }

        return this.i18n.tr(key, options);
    }
}