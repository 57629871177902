import { autoinject, computedFrom } from "aurelia-framework";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { CouponCodeDto, CouponCodesApi } from "../api/coupons-api";
import lowercase from "../lowercase";

@autoinject()
export class CreateAccount {

    couponCode!: CouponCodeDto;
    message: MessageModel;
 
    constructor(private i18n:I18N, private router: Router, private couponCodeApi: CouponCodesApi) {
        this.message = new MessageModel('hide', undefined, undefined)
    }

    async activate(params: {id: number; guestkey: string; code: string;}) {

    //  https://localhost:5001/account/coupon-code-accept?id=17&guestkey=49168181-2BAB-4543-8DCE-28344B98B303&code=fyDdsK

        params = lowercase(params);

        await this.couponCodeApi.acceptCode(params.id, params.guestkey, params.code);

        this.message.status = "success";
        // this.message.header = this.i18n.tr("account:create-guest.message.guest-create-error.header");
        this.message.header = this.i18n.tr("account:accept-coupon-code.message.accept.header");
        this.message.text = this.i18n.tr("account:accept-coupon-code.message.accept.text");
    }
}
