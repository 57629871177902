import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { DateTime } from "luxon";
import { Booking } from "../booking";
import { ApiTicket } from "./api-ticket";
import { jsonProperty } from "ur-json";
import { Http } from "ur-http";
import { luxonConverter } from "./converters/luxon-converter";

type CreateUpdateError = "fullybooked" | "closed";

@autoinject()
export class OrdersApi {
    constructor(private client: ApiClient) {
    }

    async locale(editPackageKey?: string) {

        let url = '/orders/locale/';
        
        if(editPackageKey)
            url = url + "?editpackagekey=" + editPackageKey;

        return Http.get(url)
        .expectJson(LocaleDto)
        .transfer();
    }


    async preview(booking: Booking, editPackageKey?: string) {

        const query: {tickets: ApiTicket[],
            mail: string | undefined,
            couponCode: string | undefined,
            clipAsPrice0: boolean | undefined} = {
            tickets: booking.getTickets().map(x => {
                return {
                    transportId: x.transportId,
                    trailerId: x.trailerId,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers,
                    meals: x.meals,
                    departureId: x.departureId,
                    disabled: x.disabled ? x.disabled : false,
                    cancelled: x.cancelled ? x.cancelled : false,
                    waitListPriority: x.waitListPriority
                };
            }),
            mail: booking.email,
            couponCode: booking.couponCode,
            clipAsPrice0: booking.clipAsPrice0
        };

        let url = '/orders/preview/';
        
        if(editPackageKey)
            url = url + "?editpackagekey=" + editPackageKey;

        return Http.post(url)
        .withJson(query)
        .expectJson(BasketDto)
        .transfer();
    }

    async create(booking: Booking, noPayment: boolean, currency: string): Promise<OrderCreateDto | CreateUpdateError>  {

        const command: {
            tickets: ApiTicket[],
            contactName: string | undefined,
            contactZip: string | undefined,
            contactCity: string | undefined,
            contactCountry: string | undefined,
            contactMail: string | undefined,
            contactPhone: string | undefined,
            carRegistration: string | undefined,
            couponCode: string | undefined,
            note: string | undefined,    
            clipAsPrice0: boolean | undefined,  
            newsLetter: boolean | undefined,              
            cvr: string | undefined;         
            noPayment: boolean | undefined,
            currency: string | undefined} = {
            tickets: booking.getTickets().map(x => {
                return {
                    transportId: x.transportId,
                    trailerId: x.trailerId,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers,
                    meals: x.meals,
                    departureId: x.departureId,
                    disabled: x.disabled ? x.disabled : false,
                    cancelled: x.cancelled ? x.cancelled : false,
                    waitListPriority: x.waitListPriority
                };
            }),
            contactName: booking.name,
            contactZip: booking.postalCode,
            contactCity: booking.city,
            contactCountry: booking.country,
            contactMail: booking.email,
            contactPhone: booking.phone,
            carRegistration: booking.carRegistration,
            couponCode: booking.couponCode,
            note: booking.note,
            clipAsPrice0: booking.clipAsPrice0,
            newsLetter: booking.newsLetter,
            cvr: booking.cvr,
            noPayment: noPayment,
            currency: currency
        };

        const url = '/orders/create';

        const response = await this.client.post(url, JSON.stringify(command));

        if (response.status === 422) {
            const status = await response.text();
            return <CreateUpdateError>status;
        }
        else if (response.status != 200) {
            throw Error("Error creating order");
        }

        const createOrderResponse = await response.json() as OrderCreateDto;

        return createOrderResponse;
    }

    
        async update(booking: Booking, noPayment: boolean, currency: string, editPackageKey: string): Promise<OrderUpdateDto | CreateUpdateError> {

            const command: {
                tickets: ApiTicket[],
                contactName: string | undefined,
                contactZip: string | undefined,
                contactCity: string | undefined,
                contactCountry: string | undefined,
                contactMail: string | undefined,
                contactPhone: string | undefined,
                carRegistration: string | undefined,
                couponCode: string | undefined,
                note: string | undefined,       
                clipAsPrice0: boolean | undefined, 
                newsLetter: boolean | undefined, 
                cvr: string | undefined;            
                noPayment: boolean | undefined,
                currency: string | undefined} = {
                tickets: booking.getTickets().map(x => {
                    return {
                        transportId: x.transportId,
                        trailerId: x.trailerId,
                        totalWeights: x.totalWeights,
                        passengers: x.passengers,
                        meals: x.meals,
                        departureId: x.departureId,
                        disabled: x.disabled ? x.disabled : false,
                        cancelled: x.cancelled ? x.cancelled : false,
                        waitListPriority: x.waitListPriority
                    };
                }),
                contactName: booking.name,
                contactZip: booking.postalCode,
                contactCity: booking.city,
                contactCountry: booking.country,
                contactMail: booking.email,
                contactPhone: booking.phone,
                carRegistration: booking.carRegistration,
                couponCode: booking.couponCode,
                note: booking.note,
                clipAsPrice0: booking.clipAsPrice0,
                newsLetter: booking.newsLetter,
                cvr: booking.cvr,
                noPayment: noPayment,
                currency: currency
            };

            const url = '/orders/update/?editpackagekey=' + editPackageKey;
            const response = await this.client.post(url, JSON.stringify(command));
    
            if (response.status === 422) {
                    const status = await response.text();
                    return <CreateUpdateError>status;
            }
            else if (response.status != 200) {
                throw Error("Error creating order");
            }

            const updateOrderResponse = await response.json() as OrderUpdateDto;

            return updateOrderResponse;
        }

        async getBaseInfo(packagekey: string, bypassCache?: boolean) {

            let url = '/orders/' + packagekey + '/baseinfo';

            if (bypassCache) {
                url += '?bypassCache=' + (new Date().valueOf());
            }

            const response = await this.client.get(url);            
    
            if (response.status != 200) {
                throw Error("Error getting order baseinfo");
            }
   
            const baseInfo = await response.json() as OrderBaseInfoDto;

            return baseInfo;
        }

        async getPaySummary(packagekey: string) {

            return Http.get('/orders/' + packagekey + '/paysummary')
                .expectJson(PaySummaryOrderDto)
                .transfer();
        }


        async getPaymentInfo(packagekey: string) {

            const url = '/orders/' + packagekey + '/paymentinfo';
            const response = await this.client.get(url);            
    
            if (response.status != 200) {
                throw Error("Error getting order baseinfo");
            }
   
            const paymentInfo = await response.json() as PaymentInfoDto;

            return paymentInfo;
        }

        async getTickets(packagekey: string) {
            return Http.get('/orders/' + packagekey + '/tickets')
                .expectJson(TicketsFromOrderDto)
                .transfer();
        }

        async getContactInfo(packagekey: string) {

            const url = '/orders/' + packagekey + '/contactinfo';
            const response = await this.client.get(url);            
    
            if (response.status != 200) {
                throw Error("Error getting order baseinfo");
            }

            const contactInfo = await response.json() as ContactInfoDto;

            return contactInfo;
        }

        async getEcommercePurchase(packagekey: string) {

            const url = '/orders/' + packagekey + '/EcommercePurchase';
            const response = await this.client.get(url);            
    
            if (response.status != 200) {
                throw Error("Error getting order EcommercePurchase");
            }

            return await response.json();
        }

        async getEcommerceGA4Purchase(packagekey: string) {

            const url = '/orders/' + packagekey + '/EcommerceGA4Purchase';
            const response = await this.client.get(url);            
    
            if (response.status != 200) {
                throw Error("Error getting order EcommerceGA4Purchase");
            }

            return await response.json();
        }

        // takes guestId from login  
        async getFutureOrders() {
            return Http.get('/orders/future')
            .expectJsonArray(OrderDto)
            .transfer();
        }

        // takes guestId from login  
        async getPreviousOrders() {
            return Http.get('/orders/previous')
            .expectJsonArray(OrderDto)
            .transfer();
        }

        // async sendMail(packagekey: string) {
        //     const response = await this.client.post('/orders/' + packagekey + '/sendmail');

        //     if (response.status != 200) {
        //         throw Error("Error sending mail");
        //     }
        // }
    }

    export class LocaleDto{
        @jsonProperty()
        languageCode!: string;
        @jsonProperty()
        currency!: string;       
        @jsonProperty()
        currencySign!: string;
    }

    export class Reservation {
        @jsonProperty()
        public reservationId!: number;
        @jsonProperty()
        public reservationNote!: string;
        @jsonProperty()
        public waitListPriorityIndex!: number;       
        @jsonProperty()
        public fromPortId!: string;
        @jsonProperty()
        public toPortId!: string;
        @jsonProperty({converter: luxonConverter})
        public departs!: DateTime;
        @jsonProperty()
        public ticketAbbrev!: string;
        @jsonProperty()
        public status!: string;
    }

    export class OrderDto {
        @jsonProperty()
        orderId!: number;
        @jsonProperty()
        packagekey!: string;
        @jsonProperty()
        carRegistration!: string;
        @jsonProperty()
        barcodeId!: number;
        @jsonProperty()
        note!: string;
        @jsonProperty()
        accountCouponCodeName!: string;
        @jsonProperty()
        voucherUrl!: string;        
        @jsonProperty({type: Reservation})
        reservations!: Reservation[];
    }
    
   
    export class BasketRecord {
        @jsonProperty()
        name!: string;
        @jsonProperty({converter: luxonConverter})
        depart!: DateTime;
        @jsonProperty()
        qty!: number;
        @jsonProperty()
        unit!: string;
        @jsonProperty()
        price!: number;
        @jsonProperty()
        amount!: number;
        @jsonProperty()
        isSubItem!: boolean;
    } 

    export class Vendor {
        @jsonProperty()
        name!: string;
        @jsonProperty({type: BasketRecord})
        records!: BasketRecord[];
    }

    export class BasketDto{
        @jsonProperty({type: Vendor})
        vendors!: Vendor[];
        @jsonProperty()
        totalAmount!: number;
        @jsonProperty()
        totalDiscount!: number;
        @jsonProperty()    
        totalCouponDiscount!: number;
        @jsonProperty()
        totalCoupon!: number;
        @jsonProperty()
        totalPrePayed!: number;
        @jsonProperty()    
        totalNet!: number;
        @jsonProperty()
        totalOnAccount!: number;
        @jsonProperty()
        couponType!: string;
        @jsonProperty()
        couponName!: string;       
        @jsonProperty()
        currency!: string;
    }

export interface OrderCreateDto {
    orderId: number;
    packagekey: string;
}

export interface OrderUpdateDto {
    orderId: number;
    packagekey: string;
}

export interface OrderBaseInfoDto {
    orderId: number;
    voucherUrl: string;
    mail: string;
    lastWannafindTransacknum: number;
    status: string;     //  deleted | temp | cancelled | paid | confirmed | reserved
}



export class PaySummaryTicket {
    @jsonProperty()
    public qty!: number;
    @jsonProperty()
    public ticketName!: string;
    @jsonProperty()
    public price!: number;
    @jsonProperty()
    public amount!: number;
    @jsonProperty()
    public discount!: number;
    @jsonProperty()
    public isSubItem!: boolean;
}
export class PaySummaryReservation {
    @jsonProperty()
    public reservationId!: number;
    @jsonProperty()
    public reservationNote!: string;
    @jsonProperty()
    public crossingName!: number;
    @jsonProperty({converter: luxonConverter})
    departs!: DateTime;
    @jsonProperty()
    public isSwaitListPriority!: number;
    @jsonProperty({type: PaySummaryTicket})
    tickets!: PaySummaryTicket[];
}
export class PaySummaryOrderDto {
    @jsonProperty()
    public orderId!: number;
    @jsonProperty()
    public guestName!: string;
    @jsonProperty()
    public cerRegistration!: string;
    @jsonProperty()
    public note!: string;
    @jsonProperty()
    public currency!: string;
    @jsonProperty({type: PaySummaryReservation})
    reservations!: PaySummaryReservation[];
}

export interface PaymentInfoDto {
    orderId: number;
    totalAmount: number;
    totalDiscount: number;
    totalCoupon: number;
    totalCouponAccount: number;
    couponAccountName: string;
    totalPrePayed: number;
    totalRefund: number;
    totalDue: number;
    exchangeRate: number;
    nextOrderIdPrefix: string;
    currency: string;
}


export class TicketFromOrder {
    @jsonProperty()
    public ticketIndex!: number;
    @jsonProperty({converter: luxonConverter})
    public departureDate!: DateTime;
    @jsonProperty()
    public transportId!: number;
    @jsonProperty()
    public trailerId!: number;
    @jsonProperty()
    public totalWeights!: number;
    @jsonProperty()
    public passengers!: { [id: string]: number };
    @jsonProperty()
    public meals!: { [id: string]: number };
    @jsonProperty()
    public fromRegionId!: string;
    @jsonProperty()
    public toRegionId!: string;
    @jsonProperty()
    public departureId!: number;
    @jsonProperty()
    public disabled!: boolean;
    @jsonProperty()
    public cancelled: boolean = false;
    @jsonProperty()
    public waitListPriority!: number;
}
export class TicketsFromOrderDto {
    @jsonProperty({type: TicketFromOrder})
    ticketsFromOrder!: TicketFromOrder[];
}

export interface ContactInfoDto {
    mail: string;
    name: string;
    postalCode: string;
    city: string;
    country: string;
    phone: string;
    carRegistration: string;
    couponCode: string;
    note: string;
    ean: string;
    newsLetter: boolean;
}

