import { autoinject, inject } from "aurelia-framework";

@autoinject()
export class Test {

    appName!: string;  
    appVersion!: string; 
    platform!: string;
    userAgent!: string;
    userAgentNoSpace!: string;
 
    constructor(){};

    async activate(params: {firstDate: string, date: string }) {

         this.appName = navigator.appName;
         this.appVersion = navigator.appVersion;
         this.platform = navigator.platform;
         this.userAgent = navigator.userAgent;
         this.userAgentNoSpace = navigator.userAgent.replace(/\s/g, "");

         const uas = navigator.userAgent.replace(/\s/g, "").indexOf('Chrome/70');
         const test3 =  navigator.platform == 'MacIntel'; 

    }

}
