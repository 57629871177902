import { PLATFORM, autoinject } from 'aurelia-framework';
import { RouteConfig, Router, RouterConfiguration } from 'aurelia-router';

const routes: RouteConfig[] = [
    {   //  virker ikke https://localhost:5001/my-booking/dashboard
        route: "/",
        name: "dashboard",
        moduleId: PLATFORM.moduleName("./dashboard")
    },
    {   
        route: "bookings",
        name: "bookings",
        moduleId: PLATFORM.moduleName("./bookings")
    },
    {   //  https://localhost:5001/my-booking/bookings/2166e4d7-d54c-47cc-8321-4cd2ed97cde9/summary
        route: "bookings/:packagekey",
        name: "booking",
        moduleId: PLATFORM.moduleName("./booking/router")
    },
    {   
        route: "coupons",
        name: "coupons",
        moduleId: PLATFORM.moduleName("./coupons")
    },
    {   //  https://localhost:5001/my-booking/coupons/xxxxxx
        route: "coupons/:id",
        name: "coupon-details",
        moduleId: PLATFORM.moduleName("./coupon-details")
    },
    {   
        route: "settings",
        name: "settings",
        moduleId: PLATFORM.moduleName("./settings")
    },
    {   
        route: "login",
        name: "login",
        moduleId: PLATFORM.moduleName("./login")
    }
];

@autoinject()
export class MyBookingRouter {
    private router!: Router;
    
    configureRouter(config: RouterConfiguration, router: Router) {
        config.map(routes);

        this.router = router;
    }
}