import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { DateTime } from "luxon";
import { DepartureStatus } from "../../api/departures-api";

@autoinject()
export class DepartureItemCustomElement {

    @bindable()
    value!: DepartureItemValue;

    @bindable()
    selected = false;

    @bindable()
    disabled = false;
    
    @bindable()
    showdepartureprice!: boolean;   

    @computedFrom("value")
    get imageUrl() {
        if (this.value) {
            return "https://tbferry.blob.core.windows.net/icons/routes/default/" + this.value.icon;
            // return require(`./crossings/${this.value.icon}`);
        }
    }

    @computedFrom("value")
    get noteClass() {
        if (this.value) {              
            return this.value.status.toLowerCase() == 'ticketblocked' 
                || this.value.status.toLowerCase() == 'blocked' 
                || this.value.status.toLowerCase() == 'closed' 
                || this.value.status.toLowerCase() == 'full' 
                ? 'content-time-note-red' 
                : this.value.status.toLowerCase() == 'warning'
                ? 'content-time-note-orange' : '';
        }
    }
   
    click(event: Event) {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
}
//  Just to force builderror if property is missing
export interface DepartureItemValue {
    id: number;
    group: string;
    icon: string;
    fromRegionId: string;
    toRegionId: string;
    header: string;
    departs: DateTime;
    arrives: DateTime;
    note: string;
    amount: number;
    currency: string;
    status: DepartureStatus;
    disabled?: boolean;
    waitListPriority?: number;
    waitListQty?: number;
    fromPortId: string;
    toPortId: string;
    fromRegionWarning: boolean;
    toRegionWarning: boolean;
}