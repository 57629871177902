import { JsonConverter} from "ur-json";
import { DateTime } from "luxon";

class LuxonConverter  implements JsonConverter 
{
    fromJson(value: string) {
        return DateTime.fromISO(value);
    }
}

export const luxonConverter = new LuxonConverter();