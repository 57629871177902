import { PipelineResult, NavigationInstruction } from 'aurelia-router';

import { Booking } from "../booking";
import { WizardParams } from './wizard-params';
import { WizardI18N } from './wizard-i18n';
import { TicketConfigAccessor } from './ticket-config';

export interface ITicketWizard {
    config: TicketConfigAccessor;
    params: WizardParams;
    i18n: WizardI18N;
    getNextStep(booking: Booking, navigationInstruction?: NavigationInstruction): [string, any];
    navigateToNextStep(tickets: Booking, params?: any): Promise<boolean | PipelineResult>;
}

export const TicketWizardKey = "ticket-wizard";