import "bootstrap";

import * as Backend from "i18next-xhr-backend";

import { Aurelia, PLATFORM } from "aurelia-framework";
import { I18N, TCustomAttribute } from "aurelia-i18n";

import { TicketConfig, TicketConfigAccessor } from "./ticket-designer/ticket-config";
import { TicketConfigApi } from './api/ticket-config-api';
import getConfig from './oidc-config';
import { Settings } from "luxon";
import { AppConfig } from "./api/app-config-api";
import { LocaleService } from "./locale-service";

if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        const registration = await navigator.serviceWorker.register('service-worker.js');
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
    });
}

export async function configure(aurelia: Aurelia) {
    // alert("Main configure");
    const appConfig: AppConfig = await (await fetch("api/appconfig")).json();

    //  IE10 does not support navigator.language
    const localeService: LocaleService = aurelia.container.get(LocaleService);
    localeService.autodetect();

    Settings.defaultLocale = localeService.locale;

    aurelia.use
        .standardConfiguration()
        .globalResources([
            PLATFORM.moduleName("resources/ean-barcode"),
            PLATFORM.moduleName("ticket-designer/resources/region-summary"),
            PLATFORM.moduleName("ticket-designer/resources/crossing-summary"),
            PLATFORM.moduleName("ticket-designer/resources/departure-date-item"),
            PLATFORM.moduleName("timetable/timetable-date-item"),
            PLATFORM.moduleName("ticket-designer/resources/departure-date-summary"),
            PLATFORM.moduleName("ticket-designer/resources/transport-group-class"),
            PLATFORM.moduleName("ticket-designer/resources/transport-item"),
            PLATFORM.moduleName("ticket-designer/resources/transport-summary"),
            PLATFORM.moduleName("ticket-designer/resources/trailer-item"),
            PLATFORM.moduleName("ticket-designer/resources/trailer-summary"),
            PLATFORM.moduleName("ticket-designer/resources/transport-extra-summary"),            
            PLATFORM.moduleName("ticket-designer/resources/passenger-input"),
            PLATFORM.moduleName("ticket-designer/resources/passengers-summary"),
            PLATFORM.moduleName("ticket-designer/resources/departure-item"),
            PLATFORM.moduleName("ticket-designer/resources/departure-summary"),
            PLATFORM.moduleName("ticket-designer/resources/flow-item"),
            PLATFORM.moduleName("ticket-designer/resources/ticket-navigation"),
            PLATFORM.moduleName("ticket-designer/resources/undefined-summary"),
            PLATFORM.moduleName("ticket-designer/resources/dimension-format"),
            PLATFORM.moduleName("ticket-designer/resources/mass-format"),
            PLATFORM.moduleName("ticket-designer/resources/wizard-href"),
            PLATFORM.moduleName("value-converters/date-format"),
            PLATFORM.moduleName("value-converters/number-value-converters"),
            PLATFORM.moduleName("resources/ticket-icon.html"),
            PLATFORM.moduleName("resources/ticket-icon-meal.html"),
            PLATFORM.moduleName("resources/route-icon.html")            
        ])
        .plugin(PLATFORM.moduleName("aurelia-i18n"), (i18n: I18N) => {
            TCustomAttribute.configureAliases(["t", "i18n"]);

            i18n.i18next.use(Backend);

            return i18n.setup({
                backend: {
                    loadPath: "locales/{{lng}}/{{ns}}.json",
                },
                lng: localeService.locale,
                fallbackLng: false,
                attributes: ["t", "i18n"],
                ns: ["flow-group", "region", "transport", "ticketgroup", "trailer", "passenger", "datetime", "trailertitle", "port", "flow", "portal-text", "weight",
                    "ticket-designer", "new-booking", "my-booking", "global", "checkout", "account"]
            });
        })
        .plugin(PLATFORM.moduleName("aurelia-open-id-connect"), () => getConfig(appConfig.oidcTenantId, appConfig.oidcClientId))
        .feature(PLATFORM.moduleName("resources/intl-tel-input/index"), {
            initialCountry: "DK",
            preferredCountries: ["dk", "no", "se", "de", "nl", "gb"],

        });

    if (__DEBUG__) {
        aurelia.use.developmentLogging();
    }

    await aurelia.start();

    const ticketConfigApi: TicketConfigApi = aurelia.container.get(TicketConfigApi);
    const ticketConfig = await ticketConfigApi.get();
    const accessor = new TicketConfigAccessor();
    accessor.value = ticketConfig;

    aurelia.container.registerSingleton(TicketConfigAccessor, () => accessor);

    await aurelia.setRoot(PLATFORM.moduleName("app"));
}