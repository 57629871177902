import { I18N } from "aurelia-i18n";
import { MessageModel } from "../../../models/message-model";
import { OrdersApi, PaymentInfoDto, PaySummaryOrderDto } from "../../../api/orders-api";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { BookingSerializer } from "../../../booking-serializer";
import { EditBookingWizard } from "./wizard";
import lowercase from "../../../lowercase";
import { AppConfigApi } from "../../../api/app-config-api";
import { OnPayApi } from "../../../api/onpay-api";

@autoinject()
export class pay {
    title!: string;
    message: MessageModel;
    packageKey!: string;
    nextButtonText!: string;
    paySummary!: PaySummaryOrderDto;
    paymentInfo!: PaymentInfoDto;
    payMethod: string | undefined;
    hasMobilePay!: boolean; 

    showContent: Boolean = true;

    constructor(private wizard: EditBookingWizard, private bookingSerializer: BookingSerializer, private i18n: I18N,
        private ordersApi: OrdersApi, private router: Router, private onPayApi: OnPayApi, private appConfigApi: AppConfigApi){
        this.message = new MessageModel('hide', undefined, undefined);
    }
    
    async activate(params: {packagekey: string , paymethod: string}) {
        params = lowercase(params);

        this.packageKey = params.packagekey;

        this.paymentInfo = await this.ordersApi.getPaymentInfo(this.packageKey);

        if(this.paymentInfo.orderId == 0){

            this.message = new MessageModel("error",
            this.i18n.tr('checkout:pay.message.no-package.header'),
            this.i18n.tr('checkout:pay.message.no-package.text'));

        }
        else{

            const appConfig = await this.appConfigApi.get();
            this.hasMobilePay = appConfig.defaultPayMethod == "Select" || appConfig.defaultPayMethod == "MobilePay";

            this.paySummary = await this.ordersApi.getPaySummary(this.packageKey);

            this.title = this.i18n.tr("my-booking:pay.title", { orderId: this.paymentInfo.orderId });

            this.payMethod = params.paymethod;

            this.setButtonText();
        }
    }


    async goNext() {

        const amountCurrency = this.paymentInfo.totalDue;
        const amountCurrencyInt = Math.trunc(amountCurrency * 100);

        await this.onPayApi.PostOnPay(this.packageKey, false, this.payMethod);

        }

    setButtonText(){

        this.nextButtonText = this.i18n.tr("my-booking:order.next-button-pay");

    }

}
