import { ITicketWizard, TicketWizardKey } from "./ticket-wizard";
import { Router, activationStrategy, NavigationInstruction, RedirectToRoute, RouteConfig } from "aurelia-router";
import { autoinject, inject } from "aurelia-framework";

import { DateTime } from "luxon";
import { Booking } from "../booking";
import { BookingSerializer } from '../booking-serializer';
import { defaults } from "lodash-es";
import { TicketConfigAccessor } from "./ticket-config";
import { DateFormatValueConverter } from "../value-converters/date-format";
import { FlowGroup } from "../new-booking/flow-group";
import { MessageApi } from "../api/message-api";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";

@autoinject()
export class DepartureDate {
    title!: string;
    message!: MessageModel;
    booking!: Booking;
    private ticketIndex!: number;
    selectedDate: DateTime | undefined;
    firstDate!: DateTime;
    dates!: DateItemValue[];
    ticketsFullSelected: boolean = false;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private router: Router,
        private ticketConfig: TicketConfigAccessor, private dateConverter: DateFormatValueConverter, private i18n: I18N, private messageApi: MessageApi) {
            this.message = new MessageModel('hide', undefined, undefined)
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    canActivate(params: { booking: string, ticketIndex: string }) {
        // Special handling for the case where the booking does not include the ticketIndex
        // This can happen when selecting a return ticket and then delete that last ticket from the bottom menu.
        // This causes a redirect to the summary page. If we navigate back from that page, we request with ticketIndex=1 which was just deleted

        this.booking = this.bookingSerializer.deserialize(params.booking);

        this.ticketIndex = parseInt(params.ticketIndex);
        const ticket = this.booking.getTicket(this.ticketIndex);

        if (!ticket) {
            return new RedirectToRoute("flow", { booking: params.booking }, { replace: true });
        }

        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppDepartureDateInfoMessage');
        
        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }

        return true;
    }

    activate(params: { booking: string, ticketIndex: string, firstDate: string, flowGroup?: string }, _config: RouteConfig, navigationInstruction: NavigationInstruction) {
        const ticket = this.booking.getTicket(this.ticketIndex);

        const selectedFlowGroup = this.ticketConfig.value.flowGroups.find(x => x.id === params.flowGroup);

        let blockedPeriods: BlockedPeriod[] = [];

        if(selectedFlowGroup){            
            blockedPeriods = selectedFlowGroup.blockedPeriods;
        }

        this.ticketsFullSelected = this.booking.areFullSelected();

        this.selectedDate = ticket.departureDate;

        const today = DateTime.local().startOf("day");

        if (params.firstDate) {
            this.firstDate = DateTime.fromISO(params.firstDate);
        }
        else if (this.selectedDate.isValid) {
            this.firstDate = this.selectedDate.minus({ days: 2 });
        }
        else if (this.ticketIndex > 0) {
            const prevTicket = this.booking.getTicket(this.ticketIndex - 1);
            if (prevTicket && prevTicket.departureDate) {
                this.firstDate = prevTicket.departureDate;
            }
        }
        else {
            this.firstDate = today;
            this.firstDate = this.ticketConfig.value.firstDepartureDate;
        }

        this.dates = getDateRange(this.firstDate, 14);

        const allTickets = this.booking.getTickets();
        const prevTicket = this.ticketIndex > 0 ? this.booking.getTicket(this.ticketIndex - 1) : undefined;
        const nextTicket = this.booking.getTicket(this.ticketIndex + 1);


        for (const date of this.dates) {

            let isBlocked = false;
            for (const blockedPeriod of blockedPeriods){
                if(date.date >= blockedPeriod.startDate && date.date <= blockedPeriod.endDate)
                    isBlocked = true;
            }
            
            date.isDisabled = date.date < this.ticketConfig.value.firstDepartureDate
                || (prevTicket !== undefined && prevTicket.departureDate.isValid && date.date < prevTicket.departureDate)
                || (nextTicket !== undefined && nextTicket.departureDate.isValid && date.date > nextTicket.departureDate)
                || isBlocked == true
                || date.date > this.ticketConfig.value.lastDepartureDate;

            date.hasDeparture = date.date >= this.ticketConfig.value.firstDepartureDate 
                                && date.date <= this.ticketConfig.value.lastDepartureDate
                                && isBlocked == false;

            const noteLines = [];

            for (const ticket of allTickets) {
                if (ticket.departureDate.equals(date.date)) {
                    noteLines.push(this.wizard.i18n.tr("ticket-designer:departure-date.from-region", {
                        fromRegionId: ticket.fromRegionId
                    }));
                }
            }

            date.note = noteLines.join("<br>");
        }

        // toLocaleString take date -1 when using Internet Explorer. We don't care here. It's only month text. 

        let monthText = this.dateConverter.toView(this.dates[0].date, "mo-short" );
        // let monthText = this.dates[0].date.toLocaleString({ month: "short" });


        //const lastDateMonthText = this.dates[this.dates.length - 1].date.toLocaleString({ month: "short" });
        const lastDateMonthText = this.dateConverter.toView(this.dates[this.dates.length - 1].date, "mo-short");

        if (monthText !== lastDateMonthText) {
            monthText += "-" + lastDateMonthText;
        }

        if (this.dates[this.dates.length - 1].date.year !== today.year) {
            //  Next year present, we show next year
            monthText = monthText + " " + this.dates[this.dates.length - 1].date.year;
        }

        this.title = this.wizard.i18n.tr("ticket-designer:departure-date.title", {
            fromRegionId: ticket.fromRegionId,
            month: monthText
        });
    }

    goNext() {

        return this.wizard.navigateToNextStep(this.booking);
    }

    step(days: number) {
        const params = defaults({
            firstDate: this.firstDate.plus({ days: days }).toISODate()
        }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);

        return this.router.navigateToRoute("departure-date", params);
    }

    selectDate(item: DateItemValue) {

        if (!item.isDisabled) {

            const ticket = this.booking.getTicket(this.ticketIndex);

            if (!ticket.departureDate.equals(item.date)) {

                ticket.departureDate = item.date;
                ticket.departureId = undefined;

                if (ticket.transportId) {
                    //  We have to check if transport is valid for new date
                    const blockedPeriods = this.ticketConfig.value.getTransport(ticket.transportId).blockedPeriods.find(b => b.startDate <= item.date && b.endDate >= item.date);

                    if (blockedPeriods) {
                        ticket.clearTransportId();
                    }
                }
            }

            return this.wizard.navigateToNextStep(this.booking);
        }
    }
}

function getDateRange(firstDate: DateTime, count: number) {
    const dates: DateItemValue[] = [];
    const today = DateTime.local().startOf("day");

    for (let dayOffset = 0; dayOffset < count; dayOffset++) {
        const loopDate = firstDate.plus({ days: dayOffset });

        dates.push({
            date: loopDate,
            isDisabled: false,
            hasDeparture: true,
            note: ""
        });
    }

    return dates;
}

export interface DateItemValue {
    date: DateTime;
    isDisabled: boolean;
    hasDeparture: boolean;
    note: string;
}

export interface BlockedPeriod {
    startDate: DateTime;
    endDate: DateTime;
}