import { DateTime } from "luxon";
import { NewBookingWizard } from "./wizard";
import { Booking } from "../booking";
import { BookingSerializer } from '../booking-serializer';
import { autoinject } from "aurelia-framework";
import { TicketsSummaryApi } from "../api/tickets-summary-api";
import { TicketConfigApi } from "../api/ticket-config-api";
import { RouteConfig, Router } from "aurelia-router";
import { DeparturesApi } from "../api/departures-api";
import { TicketConfig } from "../ticket-designer/ticket-config";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";

@autoinject()
export class Summary {
    private booking!: Booking;
    private ticketConfig!: TicketConfig;
    tickets: TicketsSummaryViewModel[] = [];
    weightIconUrl?: string;
    totalAmount!: number;
    currency!: string;
    message: MessageModel;

    constructor(private wizard: NewBookingWizard, private bookingSerializer: BookingSerializer, private router: Router,        
        private ticketSummaryApi: TicketsSummaryApi, private ticketConfigApi: TicketConfigApi, private departuresApi: DeparturesApi, private i18n: I18N) {
            this.message = new MessageModel('hide', undefined, undefined)
    }

    async activate(params: { booking: string }, _config: RouteConfig) {
        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppSummaryInfoMessage');

        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }

        this.booking = this.bookingSerializer.deserialize(params.booking);
        const selectedTickets = this.booking.getTickets();

        this.ticketConfig = await this.ticketConfigApi.get();

        const summary = await this.ticketSummaryApi.getTicketsSummary(this.booking, undefined);
    
        for (let ticket of selectedTickets){

            const ticketSummary = summary.ticketsSummary.find(x => x.departureId === ticket.departureId);

            if(ticket.departureId){

                const departureBaseInfo = await this.departuresApi.getDepartureBaseInfo(ticket.departureId);

                if(ticket.fromRegionId && ticket.transportId && ticket.departureId){
                    this.tickets.push({
                        departureDate: ticket.departureDate,                
                        transportId: ticket.transportId,
                        trailerId: ticket.trailerId,
                        totalWeights: ticket.totalWeights,
                        passengers: ticket.passengers,
                        fromRegionId: ticket.fromRegionId,
                        departureId: ticket.departureId,
                        crossingIcon: departureBaseInfo.icon,
                        amount: ticketSummary ? ticketSummary.amount : 0,
                        isMenuOnly: this.ticketConfig.getTransport(ticket.transportId).isMenuOnly
                    });
                }
            }
        }
        
        let totalAmount = 0;
        summary.ticketsSummary.forEach(element => {
            totalAmount += element.amount;
        });

        this.totalAmount = totalAmount;
        this.currency = summary.currency;

        const transport = this.ticketConfig.getTransport(this.tickets[0] && this.tickets[0].transportId ? this.tickets[0].transportId : 0);  

        this.weightIconUrl = "https://tbferry.blob.core.windows.net/icons/tickets/default/" + transport.weightIcon + ".svg";
    }

    showTrailer(ticketIndex: number){
        if (ticketIndex >= 0) {

            const ticket = this.tickets[ticketIndex];

            return this.ticketConfig.getTransport(ticket.transportId).enabledTrailerIds.length > 1;
        }

        return false;
    }
}

export interface TicketsSummaryViewModel {
    departureDate: DateTime;
    transportId: number;
    trailerId: number | undefined;
    totalWeights: number | undefined;
    passengers: { [id: string]: number } | undefined;
    fromRegionId: string;
    departureId: number;
    crossingIcon: string;
    amount: number;
    isMenuOnly: boolean;
}
