import { HttpClient, Interceptor } from "aurelia-fetch-client";

import { OpenIdConnect } from 'aurelia-open-id-connect';
import { autoinject } from "aurelia-framework";
import { LocaleService } from "../locale-service";
import {Http} from "ur-http";

@autoinject()
export class ApiClient {
    constructor(private inner: HttpClient, oidc: OpenIdConnect, localeService: LocaleService) {
        this.inner.configure(config => {
            config
                .withBaseUrl("/api")
                .withInterceptor(new AcceptLanguageInterceptor(localeService))
                .withInterceptor(new TokenInterceptor(oidc))
                .withInterceptor({
                    response: response => {
                        if (response.status === 401) {
                            window.location.href = "/";
                        }

                        return response;
                    }
                })
        });

        Http.defaults.fetch = this.inner.fetch.bind(this.inner);
    }

    get(url: string) {
        return this.inner.get(url);
    }

    post(url: string, body?: any) {
        return this.inner.post(url, body);
    }

    put(url: string, body?: any) {
        return this.inner.put(url, body);
    }
}

class AcceptLanguageInterceptor implements Interceptor {
    constructor(private localeService: LocaleService) {
    }

    request(request: Request) {
        const headers = request.headers || new Headers();

        headers.set("Accept-Language", this.localeService.locale);
        return new Request(request, {
            headers: headers
        });
    }
}

class TokenInterceptor implements Interceptor {
    constructor(private oidc: OpenIdConnect) {
    }

    async request(request: Request) {
        const user = await this.oidc.getUser();
        
        if (user && !user.expired && user.access_token) {
            request.headers.set('Authorization', `Bearer ${user.access_token}`);
        }

        return request;
    }
}