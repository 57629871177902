import { DateTime } from 'luxon';
import { Router} from 'aurelia-router';
import { Booking } from '../../booking';
import { autoinject, buildQueryString } from "aurelia-framework";
import { BookingSerializer } from '../../booking-serializer';
import { BookingFactory } from '../../booking-factory';
import { OrdersApi } from '../../api/orders-api';
import { I18N } from 'aurelia-i18n';
import { TicketConfigApi } from '../../api/ticket-config-api';
import { TicketsSummaryApi } from '../../api/tickets-summary-api';
import { DeparturesApi } from '../../api/departures-api';
import { MessageModel } from '../../models/message-model';
import { EditBookingWizard } from './edit/wizard';
import lowercase from '../../lowercase';
import { TicketConfig } from '../../ticket-designer/ticket-config';
import { LogApi } from '../../api/log-api';
import { AppConfigApi } from '../../api/app-config-api';

@autoinject()
export class BookingDetails {
    title: string = "";
    tickets: TicketViewModel[] = [];
    private booking!: Booking;
    private ticketConfig!: TicketConfig;
    orderId!: number;
    totalAmount!: number;
    currency!: string;
    message!: MessageModel;
    someCancelled: boolean = false;
    allDisabled: boolean = false;
    canBeCopied: boolean = true;
    ticketIsValidAllDay: boolean = true;
    hasPaymentGateway!: boolean;
    hrefIsDirtyCount = 0;

    constructor(private ordersApi: OrdersApi, private ticketConfigApi: TicketConfigApi, private ticketSummaryApi: TicketsSummaryApi, private departuresApi: DeparturesApi,
        private i18n: I18N, private bookingFactory: BookingFactory, private bookingSerializer: BookingSerializer, private router: Router, 
        private wizard: EditBookingWizard, private log: LogApi, private appConfigApi: AppConfigApi ){
            this.message = new MessageModel('hide', undefined, undefined);
    }

    async activate(params: { packagekey: string }) {
        params = lowercase(params);

        const tickets = await this.ordersApi.getTickets(params.packagekey);  
        const baseInfo = await this.ordersApi.getBaseInfo(params.packagekey);

        if (!baseInfo || baseInfo.status == 'missing' || baseInfo.status == 'deleted' || baseInfo.status == 'temp') {

            this.message.status = "error";
            this.message.header = this.i18n.tr("my-booking:booking-details.message.booking-not-found.header");
            this.message.text = this.i18n.tr("my-booking:booking-details.message.booking-not-found.text");

            const status = baseInfo ? baseInfo.status : "undefined";
            this.log.LogOrder(params.packagekey, "Baseinfo.status=" + status + ", Guest got message.header=" + this.message.header, "booking-details", 6);  
        }
        else if (baseInfo.status == 'cancelled') {

            this.title = this.i18n.tr("my-booking:booking-details.title", { orderId: baseInfo.orderId }); 

            this.message.status = "error";
            this.message.header = this.i18n.tr("my-booking:booking-details.message.booking-cancelled.header");
            this.message.text = this.i18n.tr("my-booking:booking-details.message.booking-cancelled.text");

            this.log.LogOrder(params.packagekey, "Baseinfo.status=" + baseInfo.status + ", Guest got message.header=" + this.message.header, "booking-details", 6);  
        }
        else if (tickets.ticketsFromOrder.length === 0 || !tickets.ticketsFromOrder[0].transportId) {
            // Error building tickets, show error with link from baseInfo

            this.title = this.i18n.tr("my-booking:booking-details.title", { orderId: baseInfo.orderId });  

            this.message.status = "warning";
            this.message.header = this.i18n.tr("my-booking:booking-details.message.tickets-build-error.header");
            this.message.text = this.i18n.tr("my-booking:booking-details.message.tickets-build-error.text");

            this.log.LogOrder(params.packagekey, "Baseinfo.status=" + baseInfo.status + ", Guest got message.header=" + this.message.header, "booking-details", 6);  
        }
        else{

            const appConfig = await this.appConfigApi.get();
            this.hasPaymentGateway = appConfig.paymentWindow.length > 0;
            this.ticketIsValidAllDay = appConfig.ticketIsValidAllDay;

            this.booking = this.bookingFactory.load(tickets.ticketsFromOrder);      

            this.orderId = baseInfo.orderId;

            this.ticketConfig = await this.ticketConfigApi.get();

            const summary = await this.ticketSummaryApi.getTicketsSummary(this.booking, params.packagekey);

            for (let ticket of this.booking.getTickets()) {

                const ticketSummary = summary.ticketsSummary.find(x => x.departureId === ticket.departureId);

                if (ticket.departureId) {

                    const departureBaseInfo = await this.departuresApi.getDepartureBaseInfo(ticket.departureId);

                    if (ticket.fromRegionId && ticket.transportId && (ticket.trailerId || ticket.passengers) && ticket.departureId) {
                        this.tickets.push({
                            departureDate: ticket.departureDate,
                            transportId: ticket.transportId,
                            trailerId: ticket.trailerId,
                            totalWeights: ticket.totalWeights,
                            passengers: ticket.passengers,
                            fromRegionId: ticket.fromRegionId,
                            toRegionId: ticket.toRegionId,
                            departureId: ticket.departureId,
                            crossingIcon: departureBaseInfo.icon,
                            amount: ticketSummary ? ticketSummary.amount : 0,
                            disabled: !!ticket.disabled,
                            cancelled: !!ticket.cancelled,
                            waitListPriority: ticket.waitListPriority,
                            isMenuOnly: this.ticketConfig.getTransport(ticket.transportId).isMenuOnly
                        });

                        //  Maybe user is not logged in, or the booking is not created online
                        const transport =  this.ticketConfig.transports.find(x => x.id === ticket.transportId);
                        if(transport && transport.visible === false){
                            this.canBeCopied = false;
                        }
                    }
                }
            }

            const contactInfo = await this.ordersApi.getContactInfo(params.packagekey);

            if (contactInfo) {    
                this.booking.email = contactInfo.mail;
                this.booking.name = contactInfo.name;
                this.booking.postalCode = contactInfo.postalCode;
                this.booking.city = contactInfo.city;
                this.booking.country = contactInfo.country;
                this.booking.phone = contactInfo.phone;
                this.booking.carRegistration = contactInfo.carRegistration;
                this.booking.couponCode = contactInfo.couponCode;
                this.booking.note = contactInfo.note;
                this.booking.newsLetter = contactInfo.newsLetter;
                this.booking.termsAccepted = true;
            }

            let totalAmount = 0;
            summary.ticketsSummary.forEach(element => {
                totalAmount += element.amount;
            });


            this.totalAmount = totalAmount;
            this.currency = summary.currency;
            
            this.allDisabled = this.tickets.length === this.tickets.filter(x => x.disabled).length;

            if(this.allDisabled)
                this.title = this.i18n.tr("my-booking:booking-details.title-all-disabled", { orderId: baseInfo.orderId });
            else
                this.title = this.i18n.tr("my-booking:booking-details.title", { orderId: baseInfo.orderId }); 
       
        }
    }

    showTrailer(ticketIndex: number){
        if (ticketIndex >= 0) {

            const ticket = this.tickets[ticketIndex];

            return this.ticketConfig.getTransport(ticket.transportId).enabledTrailerIds.length > 1;
        }

        return false;
    }

    cancel(ticketIndex: number) {

        const isPendingCancellation = !this.tickets[ticketIndex].cancelled;

        this.booking.getTicket(ticketIndex).cancelled = isPendingCancellation;
        this.tickets[ticketIndex].cancelled = isPendingCancellation;

        this.someCancelled = this.tickets.filter(x => x.cancelled).length > 0;

        this.hrefIsDirtyCount++;
    }

    getHref(route: string, ticketIndex: number, hrefIsDirtyCount: boolean) {

        return this.router.generate(route, {
            booking: this.bookingSerializer.serialize(this.booking),
            ticketIndex: ticketIndex
        });
    }

    goCancel() {

        let i = 0;
        for (let ticket of this.tickets) {
            if (!ticket.disabled) {
                ticket.cancelled = true;
                this.booking.getTicket(i).cancelled = true;
            }
            i ++;
        } 

        return this.wizard.navigateToRoute("basket", this.booking);
    }

    goCopy() {
        
        const copy = this.bookingFactory.create(0);
        let regionIds: string[] = [];

        for (const src of this.tickets) {
            if (src.fromRegionId && src.toRegionId) {
                regionIds.push(src.fromRegionId);
                regionIds.push(src.toRegionId);
    
                copy.addTicket({
                    fromRegionId: src.fromRegionId,
                    toRegionId: src.toRegionId,
                    transportId: src.transportId,
                    trailerId: src.trailerId,
                    totalWeights: src.totalWeights,
                    passengers: src.passengers
                });
                copy.email = this.booking.email;
                copy.name = this.booking.name;
                copy.postalCode = this.booking.postalCode;
                copy.city = this.booking.city;
                copy.country = this.booking.country;
                copy.phone = this.booking.phone;
                copy.carRegistration = this.booking.carRegistration;
                copy.couponCode = this.booking.couponCode;
                copy.newsLetter = this.booking.newsLetter;
                copy.termsAccepted = true;
            }
        }

        const flowGroup = this.ticketConfig.getFlowGroupFromRegionSequence(regionIds);

        if(flowGroup){
           
            this.router.navigate("/new-booking/0/departure-date?" + buildQueryString({
                booking: this.bookingSerializer.serialize(copy),
                flowGroup: flowGroup.id
            }));

            //("departure-date", { ticketIndex: 0, booking: this.bookingSerializer.serialize(this.booking), flowGroup: flowGroup.id});
        }
    }

    goBasket() {

        return this.wizard.navigateToRoute("basket", this.booking);
    }

    confirm(){
        if(this.someCancelled){
            return this.wizard.navigateToRoute("basket", this.booking);            
        }
        else{
            return this.wizard.navigateToRoute("meals", this.booking);
        }

    }

    isToday(dateTime: DateTime) {
        return dateTime.hasSame(DateTime.local(), "day");
    }
}

interface TicketViewModel {
    departureDate: DateTime;
    transportId: number;
    trailerId: number | undefined;
    totalWeights: number | undefined;
    passengers: { [id: string]: number } | undefined;
    fromRegionId: string;
    toRegionId: string | undefined;
    departureId: number;
    crossingIcon: string;
    amount: number;
    isMenuOnly: boolean;
    disabled: boolean;
    cancelled: boolean;
    waitListPriority: number | undefined;
}