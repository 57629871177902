import "./theme/css/style.css";
import "./css/own.css";
import * as $ from "jquery";

import { PLATFORM, autoinject, Aurelia } from 'aurelia-framework';
import { RouteConfig, RouterConfiguration, PipelineStep, NavigationInstruction, Next } from "aurelia-router";

import { OpenIdConnect } from "aurelia-open-id-connect";
import { AppConfigApi } from "./api/app-config-api";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogApi } from "./api/log-api";
import { TicketConfigApi } from "./api/ticket-config-api";
import { TicketConfigAccessor } from "./ticket-designer/ticket-config";
import { LocaleId, LOCALES } from "./locales";
import { I18N } from "aurelia-i18n";
import { LocaleService } from "./locale-service";

const routes: RouteConfig[] = [
    {
        route: "/",
        name: "new-booking",
        redirect: "new-booking"
    },
    {
        route: "my-booking",
        name: "my-booking",
        moduleId: PLATFORM.moduleName("./my-booking/router")
    },
    {
        route: "new-booking",
        name: "new-booking",
        moduleId: PLATFORM.moduleName("./new-booking/router")
    },
    {
        route: "account",
        name: "account",
        moduleId: PLATFORM.moduleName("./account/router")
    },
    {
        route: "timetable",
        name: "timetable",
        moduleId: PLATFORM.moduleName("./timetable/timetable")
    },
    {
        route: "test",
        name: "test",
        moduleId: PLATFORM.moduleName("./test")
    }
];

@autoinject()
export class App {
    userIsAuthenticated!: boolean;
    brandIcon!: string;   //= require("./resources/brands/aeroefaergerne.png");
    homePageUrl!: string;
    loading: boolean = false;
    vendorFriendlyName!: string;
    showLogin: boolean = true;

    locales = LOCALES;
    currentLocale!: CurrentLocale | undefined;
    showFlags: boolean = false;

    constructor(private oidc: OpenIdConnect, private appConfigApi: AppConfigApi, private ticketConfig: TicketConfigAccessor, private ticketConfigApi: TicketConfigApi, eventAggregator: EventAggregator, 
        private logApi: LogApi, private locale: LocaleService,) {
        eventAggregator.subscribe('router:navigation:processing', () => this.loading = true);
        eventAggregator.subscribe('router:navigation:complete', () => this.loading = false);

        this.oidc.observeUser(async u => {
            this.userIsAuthenticated = u && !u.expired ? true : false;
            console.log("User userIsAuthenticated changed to " + this.userIsAuthenticated);

            if (u && !u.expired) {
                const config = await this.ticketConfigApi.get();
                this.ticketConfig.value = config;
            }
        });
    }
    
    configureRouter(config: RouterConfiguration) {
        config.options.pushState = true;
        config.options.root = "/";

        config.addPipelineStep('postcomplete', new PostCompleteStep());
        
        config.map(routes);
        this.oidc.configure(config);
    }

    async activate() {

        const appConfig = await this.appConfigApi.get();
        this.brandIcon = "https://tbferry.blob.core.windows.net/icons/brands/" + appConfig.vendorAlias +".png";
        this.homePageUrl = appConfig.homePageUrl !== undefined ? appConfig.homePageUrl : "/";

        this.vendorFriendlyName = appConfig.companyHeader;

        const user = await this.oidc.getUser();

        this.showLogin = appConfig.oidcTenantId != undefined && appConfig.oidcTenantId.length > 1;

        this.currentLocale = this.locales.find(x => x.id == this.locale.locale);

        //this.userIsAuthenticated = user && !user.expired ? true : false;

        // Update ticketconfig that was injected in main with version that possibly depends on an authenticated user
        // This code runs _before_ canActivate in components.
    }

    async signin() {

        await this.logApi.LogSession("Signin button pressed, Redirect to login. userIsAuthenticated=" + this.userIsAuthenticated, "Client_App_signin", 3);   
        return this.oidc.login();
    }

    async setLocale(locale: LocaleId) {
        await this.locale.setLanguage(locale);
        this.currentLocale = this.locales.find(x => x.id == locale);

        this.showFlags = false;
    }

    async selectLocale() {

        this.showFlags = !this.showFlags;
    }

    // signout() {
    //     return this.oidc.logout();
    // }
}

export class PostCompleteStep implements PipelineStep {
    private timerHandle: any = null;

    run(instruction: NavigationInstruction, next: Next) {
        $(window).scrollTop(0);

        if (this.timerHandle !== null) {
            clearTimeout(this.timerHandle);
        }

        this.timerHandle = setTimeout(() => {
            const links = document.querySelectorAll(".delayed-enable");
            
            for (let i = 0; i < links.length; i++) {
                const link = links[i];
                
                link.classList.remove("delayed-enable");
            }
        }, 600 /* milliseconds */);

        return next();
    }
}

interface CurrentLocale {
    id: LocaleId;
    name: string;
    flagImg: string;
}