import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";

@autoinject()
export class TermsApi {
    constructor(private client: ApiClient) {
    }

    async getTerms() {

        const url = '/locales/terms';
        const response = await this.client.get(url);            

        if (response.status != 200) {
            throw Error("Error getting order baseinfo");
        }

        const user = await response.json() as TermsDto;

        return user;
    }
}

export interface TermsDto {
    text: string;
}



