import {autoinject} from 'aurelia-framework';
import { TermsApi } from '../../../api/terms-api';
import { Booking } from '../../../booking';
import { EditBookingWizard } from './wizard';
import { BookingSerializer } from '../../../booking-serializer';
import lowercase from '../../../lowercase';

@autoinject()
export class TermsComponent {

    termsText!: string;
    private booking!: Booking;
    
    constructor (private termsApi: TermsApi, private wizard: EditBookingWizard, private bookingSerializer: BookingSerializer) {}

    async activate(params: {packagekey: string, booking: string}) {  
        params = lowercase(params);
         
        this.booking = this.bookingSerializer.deserialize(params.booking);  

        const terms = await this.termsApi.getTerms();

        this.termsText = terms.text;

    }

    acceptTerms() {
        this.booking.termsAccepted  = true;
        return this.wizard.navigateToRoute("contact", this.booking);
    }
}