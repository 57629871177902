import { TicketConfigAccessor } from "./ticket-designer/ticket-config";
import { uniqBy } from "lodash-es";
import { Booking } from "./booking";
import { autoinject } from "aurelia-framework";

@autoinject()
export class CrossingsComputer {
    constructor(private config: TicketConfigAccessor) {
    }

    getCrossings(booking: Booking, ticketIndex: number, flowGroupId?: string) {

        if(!flowGroupId){

            let regionIds: string[] = [];
            for(let ticket of booking.getTickets()){
                if(ticket.fromRegionId){
                    regionIds.push(ticket.fromRegionId);
                }
                if(ticket.toRegionId){
                    regionIds.push(ticket.toRegionId);
                }
            };

            const flowGroup = this.config.value.getFlowGroupFromRegionSequence(regionIds);

            if(flowGroup)
                flowGroupId = flowGroup.id;
        }

        const flows = flowGroupId
            ? this.config.value.getFlowGroup(flowGroupId).flowIds.map(x => this.config.value.getFlow(x))
            : this.config.value.flows;

        const prevTicket = booking.getTicket(ticketIndex - 1);
        const nextTicket = booking.getTicket(ticketIndex + 1);

        const prevToRegionIndex = 2 * ticketIndex - 1;
        const fromRegionIndex = 2 * ticketIndex;
        const toRegionIndex = 2 * ticketIndex + 1;
        const nextFromRegionIndex = 2 * ticketIndex + 2;

        const crossings: Crossing[] = [];

        for (const flow of flows) {
            if (prevTicket && prevToRegionIndex >= 0 && flow.regionIds[prevToRegionIndex] !== prevTicket.toRegionId) {
                // The to region does not match for the previous ticket, skip the flow
                continue;
            }

            if (nextTicket && nextFromRegionIndex < flow.regionIds.length && flow.regionIds[nextFromRegionIndex] !== nextTicket.fromRegionId) {
                // The from region does not match for the next ticket, skip the flow
                continue;
            }

            if (toRegionIndex < flow.regionIds.length) {
                crossings.push({
                    fromRegionId: flow.regionIds[fromRegionIndex],
                    toRegionId: flow.regionIds[toRegionIndex]
                });
            }
        }

        return uniqBy(crossings, x => `${x.fromRegionId}~${x.toRegionId}`);
    }
}

export interface Crossing {
    fromRegionId: string;
    toRegionId: string;
}