import { OpenIdConnect } from 'aurelia-open-id-connect';
import { autoinject } from "aurelia-framework";
import { I18N } from 'aurelia-i18n';
import { GuestApi, GuestDto } from '../api/guest-api';
import { LogApi } from '../api/log-api';

@autoinject()
export class Dashboard {
    userIsAuthenticated: boolean = false;
    user!: GuestDto;
    customCouponCodeDescription!: string | undefined;

    constructor(i18n: I18N, private oidc: OpenIdConnect, private guestApi: GuestApi, private logApi: LogApi) {        
        this.customCouponCodeDescription = i18n.tr('portal-text:FerryWebAppDashboardCouponCodeDescription', {
            defaultValue: i18n.tr('my-booking:dashboard.menu-items.coupon-codes.description')
        });
    }

    async activate(){
        const user = await this.oidc.getUser();

        //const logText = user ? "user.access_token=" + user.access_token : "user=" + user;
        const logText = user && user.access_token.length > 100 ? "user.access_token(first 10)=" + user.access_token.substring(0,99) : "user=" + user;
        await this.logApi.LogSession(logText, "Client_Dashboard", 3);

        if(user && !user.expired){

            const guest = await this.guestApi.getCurrentUser();

            if(guest && guest.mail && guest.name){
                //  User exist in database
                this.user = guest;
                this.userIsAuthenticated = true;
                await this.logApi.LogSession("guest && guest.mail && guest.name, user set OK", "Client_Dashboard", 3);              
            }
            else{        
                //  User mail doesn't exist in database  
                //  logout, - next login will be redirected to create user.    
                await this.logApi.LogSession("!(guest && guest.mail && guest.name), Redirect to logout", "Client_Dashboard", 3);              
                return this.oidc.logout();
            }
        }
        else{
            await this.logApi.LogSession("!(user && !user.expired), Redirect to login", "Client_Dashboard", 3);  
            return this.oidc.login();
        }
    }

    signout() {
        return this.oidc.logout();
    }
}
