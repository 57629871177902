import { autoinject } from "aurelia-framework";
import { MessageModel } from "../../models/message-model";
import { I18N } from "aurelia-i18n";
import { LogApi } from "../../api/log-api";
import lowercase from "../../lowercase";
import { AddInfoApi, AddInfoDirection, AddInfoFieldTxt } from "../../api/add-info-api";
import { forEach } from "lodash-es";
import { OrdersApi } from "../../api/orders-api";

@autoinject()
export class AddInfoComponent {

    message!: MessageModel;
    directions!: AddInfoDirection[];
    packageKey!: string; 

    constructor(private addInfoApi: AddInfoApi, private i18n: I18N, private log: LogApi) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async canActivate(params: { packagekey: string }) {
        params = lowercase(params);

        const addInfoFields = await this.addInfoApi.getFields(params.packagekey);

        this.directions = addInfoFields.directions;

        this.packageKey = params.packagekey;
       
    }

    copyDirection(){

        const txts: AddInfoFieldTxt[] = [];

        for(const direction of this.directions){ 

            for(const group of direction.fieldGroups){

                for(const field of group.fields){

                    txts.push({fieldId: field.fieldId, txt: field.txt});
                }
            }
        } 

        for(const direction of this.directions){ 

            for(const group of direction.fieldGroups){

                for(const field of group.fields){

                    if(field.fieldId > (txts.length/2)){

                        field.txt = txts[field.fieldId - (txts.length/2) - 1].txt;
                    }
                }
            }
        } 
    }

    async goNext() {

        var error: Boolean = false;

        for(const direction of this.directions){ 

            for(const group of direction.fieldGroups){

                for(const field of group.fields){

                    field.hasError = false;

                    if(field.minLength !== undefined && field.minLength > 0){
                        if(!field.txt || field.txt.length < field.minLength){
                            field.hasError = true;
                            error = true;
                        }
                    }

                    console.log("FieldId:" + field.fieldId + " Txt:" + field.txt + " HasError:" + field.hasError);

                }
            }
        }   
        
        if(!error){
            
            const addInfoFieldTxts: AddInfoFieldTxt[] = [];

            for(const direction of this.directions){ 

                for(const group of direction.fieldGroups){
    
                    for(const field of group.fields){
    
                        addInfoFieldTxts.push({fieldId: field.fieldId, txt: field.txt});
                    }
                }
            }  

            await this.addInfoApi.updateTxt(addInfoFieldTxts, this.packageKey);

            const newUrl = "https://" + window.location.host + "/my-booking/bookings/" + this.packageKey + "/receipt-no-payment" + "?lang=" + this.i18n.getLocale();

            window.location.href = newUrl;
        }
    }
}