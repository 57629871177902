import { Router, NavigationInstruction } from "aurelia-router";
import { autoinject } from "aurelia-framework";

import { ITicketWizard } from "../ticket-designer/ticket-wizard";
import { TicketConfigAccessor } from "../ticket-designer/ticket-config";
import { Booking } from "../booking";
import { defaults, extend } from "lodash-es";
import { WizardParams } from "../ticket-designer/wizard-params";
import { WizardI18N } from "../ticket-designer/wizard-i18n";
import { I18N } from "aurelia-i18n";
import { BookingSerializer } from "../booking-serializer";

@autoinject()
export class NewBookingWizard implements ITicketWizard {
    params: WizardParams;
    i18n: WizardI18N;

    constructor(private router: Router, public config: TicketConfigAccessor, private bookingSerializer: BookingSerializer, i18n: I18N) {
        this.params = new WizardParams(router);
        this.i18n = new WizardI18N(i18n);
    }

    getNextStep(booking: Booking, navigationInstruction?: NavigationInstruction): [string, any] {
        const params: { booking?: string, ticketIndex?: number, flowGroup?: string } = {};

        if (booking.length === 0) {
            // There are no tickets, start from the beginning
            return ["flow-group", params];
        }
        else {
            const incompleteTicketIndex = booking.indexOfFirstIncompleteTicket();
            params.booking = this.bookingSerializer.serialize(booking);
            params.flowGroup = this.params.getFlowGroup(navigationInstruction);

            if (incompleteTicketIndex >= 0) {
                const ticket = booking.getTicket(incompleteTicketIndex);
                params.ticketIndex = incompleteTicketIndex;

                if (!ticket.departureDate.isValid) {
                    return ["departure-date", params];
                }

                if (!ticket.transportId) {
                    return ["transport", params];
                }

                const transport = this.config.value.getTransport(ticket.transportId);

                //  Only if transport has more than one trailer
                if (!ticket.trailerId && transport.enabledTrailerIds.length > 1) {
                    return ["trailer", params];
                }
               
                if (transport.weightId && ticket.totalWeights === undefined){
                    return ["transport-extra", params];
                }

                //  Only if transport has passengers
                if (!ticket.passengers && transport.enabledPassengerIds.length > 0) {
                    return ["passengers", params];
                }   

                if (!ticket.departureId) {
                    return ["departure", params];
                }
            }
            else {
                if (booking.askForFlow) {
                    return ["flow", params];
                }
                else {
                    const currentRouteName = this.getCurrentRouteName();
                    switch (currentRouteName) {
                        case "summary": return ["meals", params];                    
                        case "meals": return ["contact", params];
                        default: return ["summary", params];
                    }
                }
            }
        }

        throw new Error("No next step found");
    }

    navigateToNextStep(booking: Booking, params?: any) {
        this.replaceHistory(booking);

        const [route, defaultStepParams] = this.getNextStep(booking);
        params = defaults(params || {}, defaultStepParams);

        return this.router.navigateToRoute(route, params);
    }

    navigateToRoute(route: string, booking: Booking) {
        this.replaceHistory(booking);

        return this.router.navigateToRoute(route, { booking: this.bookingSerializer.serialize(booking) });
    }

    private replaceHistory(booking: Booking) {
        const currentRoute = this.getCurrentRouteName();
        const params = defaults(
            { booking: this.bookingSerializer.serialize(booking) },
            this.router.currentInstruction.params,
            this.router.currentInstruction.queryParams);
        const url = this.router.generate(currentRoute, params);
        this.router.history.navigate(url, { replace: true });
    }

    private getCurrentRouteName() {
        const currentRoute = this.router.currentInstruction.config;

        if (!currentRoute.name) {
            throw new Error("The current route does not have a name");
        }

        return currentRoute.name;
    }
}