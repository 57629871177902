import { autoinject, bindable, computedFrom } from "aurelia-framework";

import { DateTime } from 'luxon';
import { DeparturesApi } from './../../api/departures-api';
import { Router } from "aurelia-router";
import { defaults } from "lodash-es";

@autoinject()
export class DepartureSummaryCustomElement {
    @bindable()
    departureId?: number;

    @bindable()
    href?: string;

    @bindable()
    ticketIndex?: number;

    fromPortId?: string;
    toPortId?: string;
    departs?: DateTime;

    // @computedFrom("departs")
    // get time() {
    //     if (this.departs) {
    //         return this.departs.toLocaleString(DateTime.TIME_24_SIMPLE);
    //     }
    // }

    constructor(private router: Router, private departuresApi: DeparturesApi) {
    }

    attached() {
        if (!this.href && typeof this.ticketIndex === "number" && this.ticketIndex >= 0) {
            const params = defaults({
                ticketIndex: this.ticketIndex
            }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
        
            this.href = this.router.generate("departure", params);
        }

        this.departureIdChanged();
    }

    departureIdChanged() {
        
//  Rasmus - den kører 4 gange når summary.ts loades. 
//  Kun nødvendigt når departure-summary vises i navigation.
//  Måske skal modulet ikke anvendes i summary.

        if (this.departureId) {
            this.departuresApi.getDepartureBaseInfo(this.departureId).then(departure => {
                this.fromPortId = departure.fromPortId;
                this.toPortId = departure.toPortId;
                this.departs = departure.departs;
            });
        }
    }
}