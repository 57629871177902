import { ITicketWizard, TicketWizardKey } from './ticket-wizard';
import { autoinject, inject } from 'aurelia-framework';
import { TicketConfigAccessor } from './ticket-config';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';
import { Router} from 'aurelia-router';
import { DeparturesApi } from '../api/departures-api';

@autoinject()
export class Trailer {
    title!: string;
    booking!: Booking;
    private ticketIndex!: number;
    groups!: GroupViewModel[];
    selectedTrailerId: number | undefined;
    ticketsFullSelected: boolean = false;
    packageKey!: string | undefined;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private router: Router,
        private config: TicketConfigAccessor, private departureApi: DeparturesApi) {
    }

    async activate(params: {packagekey?: string, booking: string,  tickets: string, ticketIndex: string, groupId?: string }) {
        
        this.packageKey = params.packagekey;

        this.booking = this.bookingSerializer.deserialize(params.booking);
        this.ticketIndex = parseInt(params.ticketIndex);

        const ticket = this.booking.getTicket(this.ticketIndex);
        this.ticketsFullSelected = this.booking.areFullSelected();

        if (!ticket.transportId) {
            throw new Error("There is no transport specified for the ticket");
        }

        const transport = this.config.value.getTransport(ticket.transportId);

        this.groups = [];
        for (const trailerId of transport.enabledTrailerIds) {
            const trailer = this.config.value.getTrailer(trailerId);

            if(trailer.visible){

                const group = this.groups.find(x => x.id === trailer.groupId);

                if (group) {
                    group.trailerIds.push(trailerId);
                }
                else {
                    this.groups.push({
                        id: trailer.groupId,
                        trailerIds: [trailerId]
                    });
                }
            }
        }

        this.selectedTrailerId = ticket.trailerId;

        if(transport.trailerTitleAbbrev != undefined){
            this.title = this.wizard.i18n.tr('trailertitle:' + transport.trailerTitleAbbrev) 

            if(ticket.fromRegionId){
                this.title  += " <b>" + this.wizard.i18n.tr('global:from') + " "    
                + this.wizard.i18n.tr(ticket.fromRegionId) + "</b>";
            }

        }
        else{
            this.title = this.wizard.i18n.tr('ticket-designer:trailer.title', { fromRegionId: ticket.fromRegionId });           
        }
    }

    goNext() {

        return this.wizard.navigateToNextStep(this.booking);
    }

    async selectTrailer(trailerId: number) {

        const ticket = this.booking.getTicket(this.ticketIndex);

        const trailerHasChanged = ticket.trailerId !== trailerId;

        ticket.trailerId = trailerId;

        if(trailerHasChanged){

            ticket.totalWeights = undefined;

            if (ticket.departureId && ticket.transportId && !ticket.waitListPriority) {

                const checkAvailability = await this.departureApi.checkAvailability(ticket.departureId, ticket.transportId, ticket.trailerId, ticket.totalWeights, ticket.passengers, this.packageKey );

                if (!checkAvailability.isAvailable) {
                    ticket.departureId = undefined;
                }
            }
        }

        return this.wizard.navigateToNextStep(this.booking);
    }
}

interface GroupViewModel {
    id: string;
    trailerIds: number[];
}
