import { Booking } from "../booking";
import { ContactConfirmApi } from "../api/contact-confirm-api.";
import { I18N } from "aurelia-i18n";
import { MessageModel } from '../models/message-model'
import { autoinject } from "aurelia-framework";
import { NewBookingWizard } from "./wizard";
import { Router } from "aurelia-router";
import { BookingSerializer } from "../booking-serializer";


@autoinject()
export class ContactConfirm {
    booking!: Booking;
    showNextButton: boolean = true;
    mail!: string | undefined;
    phone!: string | undefined;
    carRegistration!: string | undefined;
    note!: string | undefined;
    showClipAsPrice0: boolean = false;   
    clipAsPrice0: boolean = false;  
    message!: MessageModel;
    showNote: boolean = false;
    noteError: boolean = false;


    forceCVR: boolean = false;
    cvr!: string;
    cvrError: boolean = false;

    constructor(private wizard: NewBookingWizard, private bookingSerializer: BookingSerializer, private i18n: I18N, private router: Router,
        private contactConfirmApi: ContactConfirmApi) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async activate(params: { booking: string }) {

        this.booking = this.bookingSerializer.deserialize(params.booking);

        const contactConfirmMessage = await this.contactConfirmApi.getConfirmMessage(this.booking, undefined)

        const st = contactConfirmMessage.status;
        if (contactConfirmMessage.status != 'hide') {

            this.message.status = contactConfirmMessage.status;
            this.message.header = this.i18n.tr('global:' + contactConfirmMessage.status);
            this.message.text = contactConfirmMessage.text;

            this.showNextButton = contactConfirmMessage.status !== 'error';
        }

        if (this.showNextButton) {
            this.mail = this.booking.email;
            this.phone = this.booking.phone;
            this.carRegistration = this.booking.carRegistration;
            this.note = this.booking.note;
            this.clipAsPrice0 = this.booking.clipAsPrice0 || false;

            this.showNote = contactConfirmMessage.forceNote;
            this.showClipAsPrice0 = contactConfirmMessage.showClipAsPrice0;
            this.forceCVR = contactConfirmMessage.forceCVR;
        }
    }

    goNext() {


        this.noteError = (this.showNote && (this.note === undefined || this.note.length < 4));

        this.cvrError = (this.forceCVR && (this.cvr === undefined || this.cvr.length !== 8 ));



        if(!this.noteError && !this.cvrError){
            this.booking.note = this.note;
            this.booking.clipAsPrice0 = this.clipAsPrice0;
            this.booking.cvr = this.cvr;
            this.wizard.navigateToRoute("basket", this.booking);
        }
    }
}
