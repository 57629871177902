import { ITicketWizard, TicketWizardKey } from "../ticket-wizard";
import { autoinject, bindable, inject, Disposable } from "aurelia-framework";

import { DateTime } from 'luxon';
import { Router } from "aurelia-router";
import { Booking } from '../../booking';
import { defaults } from "lodash-es";
import { TicketConfigAccessor } from "../ticket-config";
import { EventAggregator } from "aurelia-event-aggregator";
import { PassengerCountChanged } from "../notifications/passenger-count-changed";
import { AppConfig, AppConfigApi } from "../../api/app-config-api";

@autoinject()
export class TicketNavigationCustomElement {
    private disposables!: Disposable[];
    tickets: TicketViewModel[] = [];
    flowGroupImageUrl?: string;
    weightIconUrl?: string;
    showPanel = true;
    
    @bindable()
    value!: Booking;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private router: Router, private ticketConfig: TicketConfigAccessor, private eventAggregator: EventAggregator) {
    }

    attached() {
        this.disposables = [
            this.eventAggregator.subscribe(PassengerCountChanged, (event: PassengerCountChanged) => {
                this.tickets[event.ticketIndex].showPassengers = true;
            })
        ];
    }

    detached() {
        for (const disposable of this.disposables) {
            disposable.dispose();
        }
    }

    valueChanged() {
        this.tickets = this.value.getTickets();

        const flowGroupId = this.wizard.params.getFlowGroup();
        this.flowGroupImageUrl = flowGroupId 
            // ? require(`./flow-groups/${flowGroupId}.png`) 
            ? "https://tbferry.blob.core.windows.net/icons/routes/default/" + flowGroupId + ".png"
            : undefined;



        if(this.tickets[0] && this.tickets[0].transportId){

            const transport = this.ticketConfig.value.getTransport(this.tickets[0].transportId);  
            this.weightIconUrl = "https://tbferry.blob.core.windows.net/icons/tickets/default/" + transport.weightIcon + ".svg";
        }
    }

    removeTicket(ticketIndex: number) {
        this.value.remove(ticketIndex);

        if(ticketIndex !== this.tickets.length -1){
            //  Not the last ticket, - start from scratch
            return this.router.navigateToRoute("flow-group");
        }

        return this.wizard.navigateToNextStep(this.value);
    }

    getHref(route: string, ticketIndex: number) {
        const params = defaults({
            ticketIndex: ticketIndex
        }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);

        return this.router.generate(route, params);
    }

    async showDelete(ticketIndex: number){

        const doShow = this.router.baseUrl.indexOf("new-booking") > -1;
        return doShow;
    }

    showTransport(ticketIndex: number){
        if (ticketIndex >= 0) {
            const ticket = this.tickets[ticketIndex];

            //  We want to show Transport after copy
            if (ticket.departureDate.isValid 
                || (ticket.trailerId || ticket.passengers)) {
                return true;
            }
        }

        return false;

    }

    showTrailer(ticketIndex: number){
        if (ticketIndex >= 0) {
            const ticket = this.tickets[ticketIndex];

            if (!ticket.transportId) {
                return false;
            }

            return this.ticketConfig.value.getTransport(ticket.transportId).enabledTrailerIds.length > 1;
        }

        return false;
    }

    showTransportExtra(ticketIndex: number){
        if (ticketIndex >= 0) {
            const ticket = this.tickets[ticketIndex];

            if (!ticket.transportId){
                return false;
            }        

            const transport = this.ticketConfig.value.getTransport(ticket.transportId);    
  
            if(!ticket.trailerId && transport.enabledTrailerIds.length > 0){
                return false;
            }           

            return transport.weightId && ticket.totalWeights !== undefined;
        }

        return false;
    }

    showPassengers(ticketIndex: number){
        if (ticketIndex >= 0) {
            const ticket = this.tickets[ticketIndex];

            if (!ticket.transportId){
                return false;
            }

            const transport = this.ticketConfig.value.getTransport(ticket.transportId);

            if(!ticket.trailerId && transport.enabledTrailerIds.length > 0){
                return false;
            }

            //  ticket.totalWeights not set from beginning
            if(transport.weightId && ticket.totalWeights === undefined){
                return false;
            }

            if(transport.enabledPassengerIds.length === 0){
                return false;
            }


            return true;
        }

        return false;
    }

    showDeparture(ticketIndex: number){
        if (ticketIndex >= 0) {
            const ticket = this.tickets[ticketIndex];

            if(!ticket.departureDate.isValid)
                return false;

            if (!ticket.transportId)
                return false;

            const transport = this.ticketConfig.value.getTransport(ticket.transportId);

            if(transport.enabledTrailerIds.length > 0 && !ticket.trailerId){
                return false;
            }

            //  ticket.totalWeights not set from beginning
            if(transport.weightId && ticket.totalWeights === undefined){
                return false;
            }

            if(transport.enabledPassengerIds.length > 0 && !ticket.passengers){
                return false;
            }

            if(ticket.passengers && !this.ticketConfig.value.arePassengersValid(ticket.transportId, ticket.passengers)){
                return false;
            }

            return true;
        }

        return false;
    }
    
    showHidePanel(){
        this.showPanel = !this.showPanel;
    }
}

interface TicketViewModel {
    departureDate: DateTime;
    transportId: number | undefined;
    trailerId: number | undefined;
    totalWeights: number | undefined;
    passengers: { [id: string]: number } | undefined;
    showPassengers?: boolean;
    fromRegionId: string | undefined;
    toRegionId: string | undefined;
    departureId: number | undefined;
}