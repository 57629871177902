export class MessageModel {

    status: string; //  hide | success | info | warning | error
    header: string | undefined;
    text: string | undefined;

    constructor(status: string, header: string | undefined, text: string | undefined){
        this.status = status;
        this.header = header;
        this.text = text;
    }
}