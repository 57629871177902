import { ApiClient } from "./api-http-client";
import { autoinject } from "aurelia-framework";

@autoinject()
export class LogApi {

    constructor(private client: ApiClient) { }

    async LogSession(logText: string, source: string, logLevel: number) {

        let command: {logText: string, source: string, logLevel: number } = {
            logText: logText,
            source: source,
            logLevel: logLevel
        };

        let url = '/log/session'
        await this.client.post(url, JSON.stringify(command));
    }

    async LogOrder(packagekey: string, logText: string, source: string, logLevel: number) {

        let command: {packagekey: string, logText: string, source: string, logLevel: number } = {
            packagekey: packagekey,
            logText: logText,
            source: source,
            logLevel: logLevel
        };

        let url = '/log/order'
        await this.client.post(url, JSON.stringify(command));
    }

    async VendorMessageAdmin(message: string, severity: number) {

        let command: {message: string, severity: number } = {
            message: message,
            severity: severity
        };

        let url = '/log/vendormessageadmin'
        await this.client.post(url, JSON.stringify(command));
    }
}