import { Booking } from "../booking";
import { NewBookingWizard } from "./wizard";
import { autoinject, computedFrom } from "aurelia-framework";
import { AppConfigApi } from "../api/app-config-api";
import { Router } from "aurelia-router";
import { ContactApi } from "../api/contact-api";
import { GuestApi } from "../api/guest-api";
import { OpenIdConnect } from "aurelia-open-id-connect";
import { CouponCodesApi, CouponCodesValidForTicketsDto } from "../api/coupons-api";
import { BookingSerializer } from "../booking-serializer";
import { utils as intlTelUtils } from "../resources/intl-tel-input";
import { I18N } from "aurelia-i18n";

@autoinject()
export class Contact {
    userIsAuthenticated: boolean = false;

    booking!: Booking;
    email: string = "";
    name: string = ""
    postalCode: string = "";
    city: string = "";
    country: string = "";
    phone: string = "";
    carRegistration: string = "";
    couponCode: string = "";
    newsLetterLabelText: string = "";
    newsLetter: boolean = false;    
    termsAccepted: boolean = false;

    countries!: Country[];
    selectedCountryId!: string;

    emailError: boolean = false;
    nameError: boolean = false;
    postalCodeError: boolean = false;
    cityError: boolean = false;
    phoneError: boolean = false;
    carRegistrationError: boolean = false;
    couponCodeError: boolean = false;  
    termsAcceptedError: boolean = false;

    showPostalCode: boolean = true;
    showCity: boolean = true;

    showCarRegistration: boolean = false;
    showCouponCode: boolean = false;
    showCouponCodeInfo: boolean = false;
    showPossibleSubscription: boolean = false;
    subscriptionUrl: string | undefined;
    couponCodesValidForTickets!: CouponCodesValidForTicketsDto[];

    constructor(private wizard: NewBookingWizard, private appConfigApi: AppConfigApi, private router: Router,
        private contactApi: ContactApi, private couponCodeApi: CouponCodesApi, private guestApi: GuestApi, private oidc: OpenIdConnect, private bookingSerializer: BookingSerializer, private i18n: I18N) {
    }

    async activate(params: { booking: string }) {


        const user = await this.oidc.getUser();
        if (user && !user.expired && user.access_token) {
            this.userIsAuthenticated = true;
        }

        const newsLetterLabelText =  this.i18n.tr('portal-text:FerryWebAppNewsLetterLabelText');
    
        //  Key is returned if value is missing
        if(newsLetterLabelText && newsLetterLabelText.indexOf(" ") > 0){
            this.newsLetterLabelText = newsLetterLabelText;
        }

        const appConfig = await this.appConfigApi.get();
        this.countries = appConfig.countries;

        this.showPostalCode = appConfig.usePostalCode;
        this.showCity = appConfig.useCity;

        this.booking = this.bookingSerializer.deserialize(params.booking);

        const additionalFields = await this.contactApi.getAdditionalFields(this.booking);

        this.showCarRegistration = additionalFields.forceCarRegistration;
        this.showCouponCode = additionalFields.showCouponCode;

        //  Take from booking if already set (Copy booking or second visit on page), 
        this.email = this.booking.email || "";
        this.name = this.booking.name || "";
        this.postalCode = this.booking.postalCode || "";
        this.city = this.booking.city || "";
        this.country = this.booking.country || "DK";
        this.phone = this.booking.phone || "";
        this.carRegistration = this.booking.carRegistration || "";
        this.couponCode = this.booking.couponCode || "";
        this.newsLetter = this.booking.newsLetter || false;        
        this.termsAccepted = this.booking.termsAccepted || false;

        if (this.userIsAuthenticated && !this.email) {
            //  No Email means first visit on NOT COPIED BOOKING
            const guest = await this.guestApi.getCurrentUser();

            if (guest && guest.name && guest.mail) {
                //  Guest found in database
                this.email = guest.mail;
                this.name = guest.name;
                this.postalCode = this.showPostalCode ? guest.zip : "";
                this.city = this.showCity ? guest.city : "";
                this.phone = guest.phone;
                this.carRegistration = this.showCarRegistration ? guest.carRegistration : "";
                this.newsLetter = guest.newsLetter;
            }
        }

        if (this.userIsAuthenticated) {
            //  Find users coupon relevant for ticket selection
            let couponCodesValidForTickets = await this.couponCodeApi.getValidForTickets(this.booking, undefined)

            if(!this.couponCode || this.couponCode.length === 0){
                for(var code of couponCodesValidForTickets.filter(x => x.isDefaultCouponCode)){
                    this.couponCode += code.code + " ";
                }
            }

            for(var co of couponCodesValidForTickets){
                co.isActive = this.couponCode.indexOf(co.code) !== -1;
            }

            this.couponCodesValidForTickets = couponCodesValidForTickets;
        }
    }

    getCouponCodeValidForTickets(code: string) {

        let selectedCouponCode = this.couponCodesValidForTickets.find(x => x.code == code);

        const existingCouponCode = this.couponCodesValidForTickets.find(x => x.code == this.couponCode);

        if (this.couponCode.length === 0 
            || (selectedCouponCode != undefined && !selectedCouponCode.allowMixedCodes)
            || (existingCouponCode && !existingCouponCode.allowMixedCodes)
            ) {
            this.couponCode = code;
        }
        else{
            this.couponCode = this.couponCode + " " + code;
        }

        for(var co of this.couponCodesValidForTickets){
            co.isActive = this.couponCode.indexOf(co.code) !== -1;
        }

        if(selectedCouponCode != undefined && selectedCouponCode.possibleSubscription){

            this.showPossibleSubscription = true;
            this.subscriptionUrl = selectedCouponCode.subscriptionUrl;
        }
        else{
            this.showPossibleSubscription = false;
            this.subscriptionUrl = undefined;
        }
    }

    async toggleNewsLetter(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.newsLetter = !this.newsLetter;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }
    
    async toggleTermsAccepted(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.termsAccepted = !this.termsAccepted;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    showTerms() {

    }

    showHideCouponCodeInfo(){
        this.showCouponCodeInfo = !this.showCouponCodeInfo;
    }

    submit() {

        let ok: boolean = true;
        this.emailError = false;
        this.nameError = false;
        this.postalCodeError = false;
        this.cityError = false;
        this.phoneError = false;
        this.carRegistrationError = false;
        this.couponCodeError = false;
        this.termsAcceptedError = false;

        if (!this.email || /.*@.*\.[a-zA-Z]{2,3}/.test(this.email) === false) {
            this.emailError = true;
            ok = false;
        }

        if (!this.name || this.name.length < 3) {
            this.nameError = true;
            ok = false;
        }

        if (this.showPostalCode) {
            if (this.postalCode === undefined || this.postalCode.length < 4) {
                this.postalCodeError = true;
                ok = false;
            }
        }

        if (this.showCity) {
            if (this.city === undefined || this.city.length < 2) {
                this.cityError = true;
                ok = false;
            }
        }

        if (!intlTelUtils.isValid(this.phone)) {
            this.phoneError = true;
            ok = false;
        }

        if (this.showCarRegistration) {
            if (this.carRegistration) {
                this.carRegistration = this.carRegistration.replace(/[^a-zA-Z0-9æøåÆØÅVüöäÜÖÄ]/g, '');
                this.carRegistration = this.carRegistration.toUpperCase();
            }
            if (!this.carRegistration || this.carRegistration.length < 3 || this.carRegistration.length > 13) {
                this.carRegistrationError = true;
                ok = false;
            }

            if(this.couponCodesValidForTickets){
                //  Logged in
                for(const lpc of this.couponCodesValidForTickets.filter(x => x.licensePlateMustMatchCode)){
                    if(this.couponCode.search(lpc.code) > -1 && lpc.code.toUpperCase() != this.carRegistration.trim().toUpperCase())
                    {
                        this.carRegistrationError = true;
                        this.couponCodeError = true;
                        ok = false;
                    }
                }
            }
        }

        if (!this.termsAccepted) {
            this.termsAcceptedError = true;
            ok = false;
        }

        this.country = this.selectedCountryId;

        if (ok) {

            this.booking.email = this.email;
            this.booking.name = this.name;
            this.booking.postalCode = this.postalCode;
            this.booking.city = this.city;
            this.booking.country = this.country;
            this.booking.phone = this.phone;
            this.booking.carRegistration = this.carRegistration;
            this.booking.couponCode = this.couponCode;
            this.booking.newsLetter = this.newsLetter;            
            this.booking.termsAccepted = this.termsAccepted;

            this.wizard.navigateToRoute("contact-confirm", this.booking);
        }
    }
}

export interface Country {
    id: string;
    name: string;
}