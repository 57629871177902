import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { Booking } from "../booking";
import { ApiTicket } from "./api-ticket";

@autoinject()
export class TicketsSummaryApi {
    constructor(private client: ApiClient) {
    }

    async getTicketsSummary(booking: Booking, editPackageKey?: string) {
        
        let dto: {tickets: ApiTicket[] } = {
            tickets: booking.getTickets().map(x => {
                return {
                    transportId: x.transportId,
                    trailerId: x.trailerId,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers,
                    departureId: x.departureId,
                    disabled: x.disabled ? x.disabled : false,
                    cancelled: x.cancelled ? x.cancelled : false
                };
            }),
        };

        let url = '/tickets/summary/'

        if(editPackageKey)
            url = url + "?editpackagekey=" + editPackageKey;

        const response = await this.client.post(url, JSON.stringify(dto));

        if (response.status != 200) {
            throw Error("Error fetching ticket-summary");
        }

        const ticketsSummary = await response.json() as TicketsSummary;

        return ticketsSummary;
    }
}

export class TicketsSummary {
    ticketsSummary!: TicketSummary[];
    currency!: string;

    constructor(init: any) {
        this.ticketsSummary = init.ticketsSummary.map((x: any) => new TicketSummary(x));
        this.currency = init.currency;
    }
}

export class TicketSummary {
    public departureId!: number;
    public amount!: number;

    constructor(init: Partial<TicketSummary>) {
        Object.assign(this, init);
    }
}
