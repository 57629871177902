import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { Booking } from "../booking";
import { ApiTicket } from "./api-ticket";

export interface ContactConfirmDto{
    tickets: ApiTicket[];
    carRegistration: string;
    couponCode: string;
    changingPackageId?: number;
}

@autoinject()
export class ContactApi {
    constructor(private client: ApiClient) {
    }

    async getAdditionalFields(booking: Booking) {
        const dto: {tickets: ApiTicket[] } = {
            tickets: booking.getTickets().map(x => {
                return {
                    transportId: x.transportId,
                    trailerId: x.trailerId,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers,
                    departureId: x.departureId,
                    disabled: x.disabled,
                    cancelled: x.cancelled
                };
            })
        };

        const url = '/contact/additionalfields/';
        const response = await this.client.post(url, JSON.stringify(dto));

        if (response.status != 200) {
            throw Error("Error fetching additionalFields");
        }

        const additionalFields = await response.json() as AdditionalFields;

        return additionalFields;
    }
}

export interface AdditionalFields {
    showCouponCode: boolean;
    forceCarRegistration: boolean;
}
