export type LocaleId = "da" | "de" | "en";

export const LOCALES: Locale[] = [
    { id: "da", name: "Dansk", flagImg: require("resources/flags/da.png") },
    { id: "de", name: "Tysk", flagImg: require("resources/flags/de.png") },
    { id: "en", name: "Engelsk", flagImg: require("resources/flags/en.png") },
];

interface Locale {
    id: LocaleId;
    name: string;
    flagImg: string;
}
