import { Router, activationStrategy, NavigationInstruction, RedirectToRoute, RouteConfig } from "aurelia-router";
import { autoinject, inject } from "aurelia-framework";
import { DateTime } from "luxon";
import { TimeTableApi, TimeTableCrossing } from "../api/timetable-api";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";
import { DateFormatValueConverter } from "../value-converters/date-format";
import { MessageApi } from "../api/message-api";
import { AppConfigApi } from "../api/app-config-api";

@autoinject()
export class TimeTable {

    title!: string;
    selectedDate: DateTime = DateTime.local().startOf("day");
    firstDate!: DateTime;
    dates!: DateItemValue[];
    crossings!: TimeTableCrossing[];
    message: MessageModel;
    companyName!: string;
    companyCVR!: string;
    companyPhone!: string;
 
    constructor( private router: Router, private timeTableApi: TimeTableApi, private i18n: I18N, private dateConverter: DateFormatValueConverter,
        private messageApi: MessageApi,private appConfigApi: AppConfigApi) {
        this.message = new MessageModel('hide', undefined, undefined)
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    async activate(params: {firstDate: string, date: string }) {

        this.message.status = "hide";

        const ferryMonitor_FerryWebAppTimeTableMessage = await this.messageApi.getMessage("FerryMonitor","FerryWebAppTimeTable");

        if(ferryMonitor_FerryWebAppTimeTableMessage.text){
            this.message.status = 'warning';
            this.message.text = ferryMonitor_FerryWebAppTimeTableMessage.text;
        }
        else{
            if (!params.firstDate) {

                //  First time we show message
                this.message.status = "info";
                this.message.text = this.i18n.tr("global:timetable.message.available-warning.text");
            }
        }

        const today = DateTime.local().startOf("day");

        this.selectedDate = params.date ? DateTime.fromISO(params.date) : today;

        if (params.firstDate) {
            this.firstDate = DateTime.fromISO(params.firstDate);
        }
        else if (this.selectedDate.isValid) {
            this.firstDate = this.selectedDate;
        }
        else {
            this.firstDate = today;
        }

        this.dates = getDateRange(this.firstDate, dateQty());

        // let monthText = this.dates[0].date.toLocaleString({ month: "long" });
        // const lastDateMonthText = this.dates[this.dates.length - 1].date.toLocaleString({ month: "long" });

        let monthText = this.dateConverter.toView(this.dates[0].date, "mo-short" );
        const lastDateMonthText = this.dateConverter.toView(this.dates[this.dates.length - 1].date, "mo-short");

        if (monthText !== lastDateMonthText) {
            monthText += "-" + lastDateMonthText;
        }

        if (this.dates[this.dates.length - 1].date.year !== today.year) {
            //  Next year present, we show next year
            monthText = monthText + " " + this.dates[this.dates.length - 1].date.year;
        }

        this.title = monthText;

        const timeTable = await this.timeTableApi.getTimeTable(this.selectedDate);
        this.crossings = timeTable;

        if (this.crossings.length === 0 && this.message.status !== "warning") {

            this.message.status = "info";

            if(today.toISODate() == this.selectedDate.toSQLDate()){
                this.message.text = this.i18n.tr("global:timetable.message.no-departures-today.text");
            }
            else{
                this.message.text = this.i18n.tr("global:timetable.message.no-departures.text");       
            }
        }

        const appConfig = await this.appConfigApi.get();

        this.companyName = appConfig.companyName;
        this.companyCVR = appConfig.companyCVR;
        this.companyPhone = appConfig.companyPhone;

    }

    step(direction: number) {
        //  1 or -1

        const dayStep = direction === 1 ? this.dates.length : -1*this.dates.length;

        const today = DateTime.local().startOf("day");

        this.firstDate = this.firstDate.plus({ days: dayStep });

        if(this.firstDate < today)
            this.firstDate = today;

        this.selectedDate = this.selectedDate.plus({ days: dayStep });

        if(this.selectedDate < today)
            this.selectedDate = today;   

        return this.router.navigateToRoute("timetable", { firstDate: this.firstDate.toISODate(), date: this.selectedDate.toISODate()});

    }

    async selectDate(item: DateItemValue) {
        if (!item.isDisabled) {

debugger;

            this.selectedDate = item.date;

            const today = DateTime.local().startOf("day");

            if(this.selectedDate == this.dates[0].date && this.selectedDate > today){

                this.firstDate = this.selectedDate.plus({days: -1})
            }
            if(this.selectedDate == this.dates[this.dates.length-1].date){

                this.firstDate = this.firstDate.plus({days: 1});
            }

            return this.router.navigateToRoute("timetable", { 
                firstDate: this.firstDate.toISODate(), 
                date: this.selectedDate.toISODate()});
        }
    }
}

function getDateRange(firstDate: DateTime, count: number) {
    const dates: DateItemValue[] = [];
    const today = DateTime.local().startOf("day");

    for (let dayOffset = 0; dayOffset < count; dayOffset++) {
        const loopDate = firstDate.plus({ days: dayOffset });

        dates.push({
            date: loopDate,
            isDisabled: false,
            note: ""
        });
    }

    return dates;
}

function dateQty(){
    return window.innerWidth > 767 ? 7 : window.innerWidth > 479 ? 4 : 3;
}

export interface DateItemValue {
    date: DateTime;
    isDisabled: boolean;
    note: string;
}