import { autoinject } from "aurelia-framework";
import { MessageModel } from "../../models/message-model";
import { I18N } from "aurelia-i18n";
import { LogApi } from "../../api/log-api";
import { OrdersApi } from "../../api/orders-api";
import { Redirect } from "aurelia-router";
import { AddInfoApi } from "../../api/add-info-api";
import { delay } from "../../utils";
import { AppConfigApi } from "../../api/app-config-api";

@autoinject()
export class ReceiptPaymentOnPayComponent {

    orderId!: number;
    editUrl!: string;
    editUrlText!: string;
    voucherUrl!: string;  
    addInfoUrl: string | undefined;
    addInfoText: string | undefined;
    message!: MessageModel;
    
    constructor(private i18n: I18N, private log: LogApi, 
        private ordersApi: OrdersApi, private addInfoApi: AddInfoApi, private appConfigApi: AppConfigApi) {
            this.message = new MessageModel('hide', undefined, undefined);
    }

    async canActivate(params: { packagekey: string, onpay_number: number, onpay_reference: string, onpay_acquirercode: string, onpay_errorcode: string}) {

     this.log.LogOrder(params.packagekey, 
        "Params: packagekey=" + params.packagekey 
        + ", onpay_number=" + params.onpay_number 
        + ", onpay_reference=" + params.onpay_reference         
        + ", onpay_acquirercode=" + params.onpay_acquirercode
        + ", onpay_errorcode=" + params.onpay_errorcode, 
        "receipt-payment-onpay", 1);

        const order = params.onpay_number > 0
            ? await this.getOrderWithRetry(params.packagekey)
            : await this.ordersApi.getBaseInfo(params.packagekey);

        let message = "";

        if(order === undefined){
            //  No package found on server at all
            message = "no-package";
            this.log.LogOrder(params.packagekey, "Params: packagekey=" + params.packagekey + " baseInfo returned order = undefined", "receipt-payment-onpay", 9);
        }
        else if (order.status == 'missing'){

            message = "no-package";
            this.log.LogOrder(params.packagekey, "Params: packagekey=" + params.packagekey + " returned status=" + order.status, "receipt-payment-onpay", 9);
        }
        else{

            if (params.onpay_number > 0){
                //  redirected with payment success by onpay accepturl

                if(order.status === "deleted"){
                    //  Payment took more than 30 minutes
                    message = "package-deleted";
                    this.log.LogOrder(params.packagekey, "Params: packagekey=" + params.packagekey + " was PAID but status=" + order.status, "receipt-payment-onpay", 6);
                }
                else if(order.status === "temp"){
                    //  Package not confirmed with onpay callbackurl
                    message = "package-temp";
                    this.log.LogOrder(params.packagekey, "Params: packagekey=" + params.packagekey + " was PAID but status=" + order.status, "receipt-payment-onpay", 6);
                }
            }
            else
            {
                message = "payment-error";
                this.log.LogOrder(params.packagekey, "Params: packagekey=" + params.packagekey + " returned onpay_number=0, status=" + order.status, "receipt-payment-onpay", 6);
            }
        }

        if(message === "no-package"){
            this.message = new MessageModel("error", 
            this.i18n.tr('checkout:receipt-payment.message.no-package.header'), 
            this.i18n.tr('checkout:receipt-payment.message.no-package.text'));
            this.log.LogOrder(params.packagekey, "no-package, Guest got message.header=" + this.message.header, "receipt-payment-onpay", 6);            
        }
        else if(message === "package-deleted"){
            this.message = new MessageModel("error", 
            this.i18n.tr('checkout:receipt-payment.message.package-deleted.header'), 
            this.i18n.tr('checkout:receipt-payment.message.package-deleted.text'));
            this.log.LogOrder(params.packagekey, "package-deleted, Guest got message.header=" + this.message.header, "receipt-payment-onpay", 6);    
        }
        else if(message === "package-temp"){
            this.message = new MessageModel("warning", 
            this.i18n.tr('checkout:receipt-payment.message.package-temp.header'), 
            this.i18n.tr('checkout:receipt-payment.message.package-temp.text'));
            this.log.LogOrder(params.packagekey, "package-temp, Guest got message.header=" + this.message.header, "receipt-payment-onpay", 6);  
        }
        else if(message === "payment-error"){
            this.message = new MessageModel("warning", 
            this.i18n.tr('checkout:receipt-payment.message.payment-error.header'), 
            this.i18n.tr('checkout:receipt-payment.message.payment-error.text'));
            this.log.LogOrder(params.packagekey, "payment-error, Guest got message.header=" + this.message.header, "receipt-payment-onpay", 6);  
        }
        else{   
            
            this.log.LogOrder(params.packagekey, "Payment succesfully registered with status=" + order.status, "receipt-payment-onpay", 1);

            const addInfoStatus = await this.addInfoApi.getStatus(params.packagekey);

            if(addInfoStatus.isOnTickets){

                const url = "/my-booking/bookings/" + params.packagekey + "/add-info?lang=" + this.i18n.getLocale();

                if(addInfoStatus.isFullFilled){

                    this.addInfoUrl = url;
                    this.addInfoText = this.i18n.tr("checkout:receipt-no-payment.add-info-lnk"); 
                }
                else{
                    return new Redirect(url);
                }
            }

            const appConfig = await this.appConfigApi.get();

            if(appConfig.receiptRedirectUrl){
                this.message = new MessageModel("info",
                this.i18n.tr('checkout:receipt-no-payment.message.redirect-info.header'),
                this.i18n.tr('checkout:receipt-no-payment.message.redirect-info.text'));

                let redirectUrl = appConfig.receiptRedirectUrl.replace("key=", "key=" + params.packagekey).replace("mail=", "mail=" + order.mail);

                window.setTimeout(
                    function(){
                        window.location.replace(redirectUrl);
                    }, 3000);
            }

            this.orderId = order.orderId;
            this.editUrl = "/my-booking/bookings/" + params.packagekey 

            this.editUrlText = this.i18n.tr("checkout:receipt-payment.edit-lnk");              

            this.voucherUrl = order.voucherUrl;

        }
    }

    async activate(params: { packagekey: string }){

        const ecommercePurchase = await this.ordersApi.getEcommercePurchase(params.packagekey);

        if(ecommercePurchase)
        {             
            const dataLayer = (<any>window).dataLayer;   
       
            if(dataLayer){
                //  Test in Console: dataLayer [Enter]
                dataLayer.push({
                    'event': 'eec.purchase',
                    'ecommerce': {
                        'purchase': ecommercePurchase
                                }
                            }); 
            }           
        }
    }

    private async getOrderWithRetry(packageKey: string) {
        let bypassCache = false;
        let trial = 1;
        while (true) {
            const order = await this.ordersApi.getBaseInfo(packageKey, bypassCache);
            if (!order || order.status !== "temp" || trial === 5) {
                return order;
            }

            await delay(1000);
            bypassCache = true;
            trial++;
        }
    }
}
