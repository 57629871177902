import { FrameworkConfiguration, PLATFORM } from "aurelia-framework";
import * as utils from "./utils";
import * as intlTelInput from "intl-tel-input";

export const DefaultOptions = Symbol("intl-tel-input default options");

export { utils };

export function configure(
    aurelia: FrameworkConfiguration,
    defaultOptions?: intlTelInput.Options
) {
    aurelia.globalResources([PLATFORM.moduleName("./attribute")]);

    if (!defaultOptions) {
        defaultOptions = {};
    }
    defaultOptions.utilsScript = require("intl-tel-input/build/js/utils");
    aurelia.container.registerInstance(DefaultOptions, defaultOptions);
}
