import { ITicketWizard, TicketWizardKey } from "../ticket-designer/ticket-wizard";
import { autoinject, inject } from "aurelia-framework";

import { TicketConfigAccessor } from "../ticket-designer/ticket-config";
import { BookingFactory } from "../booking-factory";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";
import { AppConfigApi } from "../api/app-config-api";
import { NavigationInstruction, RedirectToRoute } from "aurelia-router";
import { BookingSerializer } from "../booking-serializer";
import { MessageApi } from "../api/message-api";


@autoinject()
export class FlowGroup {
    flowGroupIds!: string[];
    message!: MessageModel;
    companyName!: string;
    companyCVR!: string;
    companyPhone!: string;
    showCookie!: boolean;
    showStatisticCookie!: boolean;
    useStatisticCookies!: boolean;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingFactory: BookingFactory, private bookingSerializer: BookingSerializer, private config: TicketConfigAccessor, 
    private appConfigApi: AppConfigApi, private i18n: I18N, private messageApi: MessageApi) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async canActivate(navigationInstruction: NavigationInstruction) {

        this.flowGroupIds = this.config.value.flowGroups.map(x => x.id);

        if(this.flowGroupIds.length == 1){
            const booking = this.bookingFactory.create(1);
            booking.askForFlow = true;

            return new RedirectToRoute("departure-date", { ticketIndex: 0, booking: this.bookingSerializer.serialize(booking), flowGroup: this.flowGroupIds[0]});
        }

        const ferryMonitor_FerryWebAppFlowGroupMessage = await this.messageApi.getMessage("FerryMonitor","FerryWebAppFlowGroup");

        if(ferryMonitor_FerryWebAppFlowGroupMessage.text){
            this.message.status = 'warning';
            this.message.text = ferryMonitor_FerryWebAppFlowGroupMessage.text;
        }
        else{
            const infoMessage =  this.i18n.tr('portal-text:FerryWebAppFlowGroupInfoMessage');
        
            //  Key is returned if value is missing
            if(infoMessage && infoMessage.indexOf(" ") > 0){
                this.message.status = 'info';
                this.message.text = infoMessage;
            }
        }

        const appConfig = await this.appConfigApi.get();

        this.companyName = appConfig.companyName;
        this.companyCVR = appConfig.companyCVR;
        this.companyPhone = appConfig.companyPhone;

        this.showCookie = appConfig.showCookieSelect;
        this.useStatisticCookies = true;
        this.showStatisticCookie = appConfig.showStatisticCookie;

        return true;
    }

    async toggleUseStatisticCookies(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.useStatisticCookies = !this.useStatisticCookies;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    // getImageUrl(flowGroupId: string) {
    //     return require(`../ticket-designer/resources/flow-groups/${flowGroupId}.png`);
    // }

    async setCookie() {

        this.showCookie = false;
    }

    selectFlowGroup(flowGroupId: string) {
        const booking = this.bookingFactory.create(1);

        booking.askForFlow = true;

        this.wizard.navigateToNextStep(booking, { flowGroup: flowGroupId });
    }
}