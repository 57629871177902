import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { Booking } from "../booking";
import { ApiTicket } from "./api-ticket";

@autoinject()
export class ContactConfirmApi {
    constructor(private client: ApiClient) {
    }

    async getConfirmMessage(booking: Booking, editPackageKey?: string) {
        
        const dto: {tickets: ApiTicket[],
            mail: string | undefined,
            carRegistration: string | undefined,
            couponCode: string | undefined,
            note: string | undefined} = {
            tickets: booking.getTickets().map(x => {
                return {
                    transportId: x.transportId,
                    trailerId: x.trailerId,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers,
                    departureId: x.departureId,
                    disabled: x.disabled ? x.disabled : false,
                    cancelled: x.cancelled ? x.cancelled : false,
                    waitListPriority: x.waitListPriority
                };
            }),
            mail: booking.email,
            carRegistration: booking.carRegistration,
            couponCode: booking.couponCode,
            note: booking.note
        };

        let url = '/contact/confirm/';
        if(editPackageKey)
            url = url + "?editpackagekey=" + editPackageKey;

        const response = await this.client.post(url, JSON.stringify(dto));

        if (response.status != 200) {
            throw Error("Error fetching additionalFields");
        }

        const additionalFields = await response.json() as ContactConfirmMessage;

        return additionalFields;
    }
}

export interface ContactConfirmMessage {
    status: string;
    text: string;
    forceNote: boolean;
    showClipAsPrice0: boolean;
    forceCVR: boolean;
}