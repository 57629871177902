import * as JsBarcode from "jsbarcode";

import { bindable } from "aurelia-framework";

export class EanBarcodeCustomElement {
    svg!: SVGElement;

    @bindable()
    value!: string | number;

    bind() {
        JsBarcode(this.svg, this.value.toString(),
        { margin: 20, textMargin: 10 });
    }
}