import { BindingEngine, Disposable, autoinject, inject } from 'aurelia-framework';
import { ITicketWizard, TicketWizardKey } from './ticket-wizard';

import { TicketConfigAccessor } from './ticket-config';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PassengerCountChanged } from './notifications/passenger-count-changed';
import { DeparturesApi } from '../api/departures-api';
import lowercase from '../lowercase';

@autoinject()
export class Passengers {
    title!: string;
    booking!: Booking;
    private ticketIndex!: number;
    groups!: GroupViewModel[];
    passengerCount!: { [passengerId: number]: number };
    private disposables!: Disposable[];
    canSubmit = false;
    packageKey!: string | undefined;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer,
        private config: TicketConfigAccessor, private bindingEngine: BindingEngine, private eventAggregator: EventAggregator, private departureApi: DeparturesApi) {
    }

    activate(params: {packagekey?: string, booking: string, ticketIndex: string }) {

        this.packageKey = params.packagekey;

        this.booking = this.bookingSerializer.deserialize(params.booking);
        this.ticketIndex = parseInt(params.ticketIndex);

        const ticket = this.booking.getTicket(this.ticketIndex);

        this.title = this.wizard.i18n.tr("ticket-designer:passengers.title", { fromRegionId: ticket.fromRegionId });

        if (!ticket.transportId) {
            throw new Error("There is no transport specified for the ticket");
        }

        const transport = this.config.value.getTransport(ticket.transportId);

        this.groups = [];
        this.passengerCount = {}
        for (const passengerId of transport.enabledPassengerIds) {
            const passenger = this.config.value.getPassenger(passengerId);

            if(passenger.visible){

                const group = this.groups.find(x => x.id === passenger.groupId);

                if (group) {
                    group.passengerIds.push(passengerId);
                }
                else {
                    this.groups.push({
                        id: passenger.groupId,
                        passengerIds: [passengerId]
                    });
                }

                // this.passengerCount[passengerId] = ticket.passengers ? ticket.passengers[passengerId] || 0 : 0;

                this.passengerCount[passengerId] = ticket.passengers ? ticket.passengers[passengerId] || passenger.minQty : passenger.minQty;
            }
        }

        // this.groups = groups

        this.canSubmit = this.computeCanSubmit();

        // Listen for count changes
        this.disposables = [];
        for (const passengerId in this.passengerCount) {
            this.disposables.push(this.bindingEngine.propertyObserver(this.passengerCount, passengerId).subscribe(() => this.countChanged(parseInt(passengerId))));
        }
    }

    deactivate() {
        for (const disposable of this.disposables) {
            disposable.dispose();
        }
    }

    private countChanged(id: number) {
        this.canSubmit = this.computeCanSubmit();

        this.eventAggregator.publish(new PassengerCountChanged(this.ticketIndex, id, this.passengerCount[id]));
    }

    private computeCanSubmit() {
        const ticket = this.booking.getTicket(this.ticketIndex);

        if (!ticket.transportId) {
            throw new Error("There is no transport specified for the ticket");
        }

        return this.config.value.arePassengersValid(ticket.transportId, this.passengerCount);
    }

    async next() {
        const ticket = this.booking.getTicket(this.ticketIndex);

        ticket.passengers = this.passengerCount;

        if (ticket.departureId && ticket.transportId && !ticket.waitListPriority) {

            const checkAvailability = await this.departureApi.checkAvailability(ticket.departureId, ticket.transportId, ticket.trailerId, ticket.totalWeights, ticket.passengers, this.packageKey);

            if (!checkAvailability.isAvailable) {
                ticket.departureId = undefined;
            }
        }

        return this.wizard.navigateToNextStep(this.booking);
    }
}

interface GroupViewModel {
    id: string;
    passengerIds: number[];
}