import { autoinject } from "aurelia-framework";
import { RedirectToRoute } from "aurelia-router";
import { DeparturesApi } from "../api/departures-api";
import { BookingFactory } from "../booking-factory";
import { BookingSerializer } from "../booking-serializer";
import lowercase from "../lowercase";
import { TicketConfigAccessor } from "../ticket-designer/ticket-config";


@autoinject()
export class Init {
    constructor(private departureApi: DeparturesApi, private bookingFactory: BookingFactory, private bookingSerializer: BookingSerializer,
        private ticketConfig: TicketConfigAccessor) {
    }

    async canActivate(params: {departureid: number}) {
        params = lowercase(params);

        if (params.departureid) {

            try {

                const checkAvailability = await this.departureApi.checkAvailability(params.departureid, 0, undefined, undefined, undefined, undefined);

                if(!checkAvailability.isAvailable){
                    return new RedirectToRoute("flow-group");
                }
                else{

                    const departure = await this.departureApi.getDepartureBaseInfo(params.departureid);

                    const booking = this.bookingFactory.create(0);
                    booking.askForFlow = true;

                    const ticket = booking.addTicket();

                    ticket.setDeparture(departure);

                    let regionIds: string[] = [];
                    regionIds.push(departure.fromRegionId);
                    regionIds.push(departure.toRegionId);

                    const flowGroup = this.ticketConfig.value.getFlowGroupFromRegionSequence(regionIds);

                    if(flowGroup){

                        return new RedirectToRoute("transport", { ticketIndex: 0, booking: this.bookingSerializer.serialize(booking), flowGroup: flowGroup.id});
                    }
                }
            }
            catch {
                return new RedirectToRoute("flow-group");
            }
        }
    }
}