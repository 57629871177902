import { customAttribute, bindable, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { defaults } from "lodash-es";
import nameof from "../../nameof";

@autoinject()
@customAttribute("wizard-href")
export class WizardHref {
    @bindable({ changeHandler: nameof<WizardHref>("processChange"), primaryProperty: true })
    route?: string;

    @bindable({ changeHandler: nameof<WizardHref>("processChange") })
    params?: any;

    @bindable()
    attribute = "href";

    constructor(private element: Element, private router: Router) {
    }

    async processChange() {
        await this.router.ensureConfigured();

        const params = defaults({ booking: this.router.currentInstruction.queryParams.booking }, this.params);
        const href = this.route ? this.router.generate(this.route, params) : "#";

        const element = this.element as Element & { au: any };

        if (element.au.controller) {
            element.au.controller.viewModel[this.attribute] = href;
        }
        else {
            element.setAttribute(this.attribute, href);
        }
    }
}