import { autoinject } from "aurelia-framework";
import { CouponCodesApi, CouponCodeDto, CouponEntryDto, CouponVolumeDiscountDto } from "../api/coupons-api";
import { I18N } from "aurelia-i18n";
import { MessageModel } from "../models/message-model";
import { Router } from "aurelia-router";

@autoinject()
export class CouponDetails {

    message!: MessageModel;
    couponCode!: CouponCodeDto;
    nameText!: string | undefined;
    couponEntries!: CouponEntryDto[];
    couponVolumeDiscounts!: CouponVolumeDiscountDto[];
    currency!: string;

    constructor(private router: Router, private couponCodeApi: CouponCodesApi, private i18n: I18N) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async activate(params: {id: number}) {

        this.couponCode = await this.couponCodeApi.getById(params.id);

        if(!this.couponCode.couponCodeName){
            this.message = new MessageModel("info",
            this.i18n.tr('my-booking:coupon-details.message.coupon-name-missing.title'),
            this.i18n.tr('my-booking:coupon-details.message.coupon-name-missing.text'));
        }

        const couponCodeEntries = await this.couponCodeApi.getEntries(params.id);
        this.couponEntries = couponCodeEntries.couponEntries;

        this.couponVolumeDiscounts = await this.couponCodeApi.getVolumeDiscounts(params.id);

        this.currency = couponCodeEntries.currency;
    }

    async nameChanged(value: string){

        if(value){
            this.couponCode.couponCodeName = value.substr(0, 200);

            this.message = new MessageModel("success", undefined,
            this.i18n.tr('my-booking:coupon-details.message.coupon-name-entered.text', {name: this.couponCode.couponCodeName}));
        }
        else{
            this.couponCode.couponCodeName = "";

            this.message = new MessageModel("warning", undefined,
            this.i18n.tr('my-booking:coupon-details.message.coupon-name-deleted.text'));
        }

        await this.couponCodeApi.updateName(this.couponCode.couponCodeId, this.couponCode.couponCodeName);
    }

    async cancelSubscription(){

        await this.couponCodeApi.cancelSubscription(this.couponCode.couponCodeId);

        return this.router.navigateToRoute("dashboard");
    }
}