import { autoinject } from "aurelia-framework";
import { MessageModel } from "../../models/message-model";
import { I18N } from "aurelia-i18n";
import { LogApi } from "../../api/log-api";
import { OrdersApi } from "../../api/orders-api";
import { Router, Redirect } from "aurelia-router";
import { BookingFactory } from "../../booking-factory";
import { BookingSerializer } from "../../booking-serializer";
import lowercase from "../../lowercase";
import { AddInfoApi } from "../../api/add-info-api";
import { AppConfigApi } from "../../api/app-config-api";

@autoinject()
export class ReceiptNoPaymentComponent {
    orderId!: number;
    editUrl!: string;
    editUrlText!: string;
    voucherUrl!: string;
    addInfoUrl: string | undefined;
    addInfoText: string | undefined;
    message!: MessageModel;

    constructor(private i18n: I18N, private log: LogApi, private ordersApi: OrdersApi, private appConfigApi: AppConfigApi, 
        private addInfoApi: AddInfoApi, private router: Router, private bookingFactory: BookingFactory, private bookingSerializer: BookingSerializer) {
        this.message = new MessageModel('hide', undefined, undefined);
    }

    async canActivate(params: { packagekey: string }) {
        params = lowercase(params);
        
        const order = await this.ordersApi.getBaseInfo(params.packagekey);

        if (order === undefined || order.status === 'missing' || order.status === 'deleted' || order.status === 'temp') {
            this.message = new MessageModel("error",
                this.i18n.tr('checkout:receipt-no-payment.message.no-package.header'),
                this.i18n.tr('checkout:receipt-no-payment.message.no-package.text'));
        }
        else {

            const addInfoStatus = await this.addInfoApi.getStatus(params.packagekey);

            if(addInfoStatus.isOnTickets){

                const url = "/my-booking/bookings/" + params.packagekey + "/add-info?lang=" + this.i18n.getLocale();

                if(addInfoStatus.isFullFilled){

                    this.addInfoUrl = url;
                    this.addInfoText = this.i18n.tr("checkout:receipt-no-payment.add-info-lnk"); 
                }
                else{
                    return new Redirect(url);
                }
            }

            const appConfig = await this.appConfigApi.get();

            if(appConfig.receiptRedirectUrl){
                this.message = new MessageModel("info",
                this.i18n.tr('checkout:receipt-no-payment.message.redirect-info.header'),
                this.i18n.tr('checkout:receipt-no-payment.message.redirect-info.text'));

                let redirectUrl = appConfig.receiptRedirectUrl
                    .replace("key=", "key=" + params.packagekey)
                    .replace("status=", "status=" + order.status) // reserved | confirmed | paid | cancelled
                    .replace("mail=", "mail=" + order.mail);

                window.setTimeout(
                    function(){
                        window.location.replace(redirectUrl);
                    }, 3000);
            }

            this.orderId = order.orderId;
            this.editUrl = "/my-booking/bookings/" + params.packagekey + "?lang=" + this.i18n.getLocale();

            this.editUrlText = this.i18n.tr("checkout:receipt-no-payment.edit-lnk");              

            this.voucherUrl = order.voucherUrl;

            this.log.LogOrder(params.packagekey, "No-Payment succesfully viewed with status=" + order.status, "receipt-no-payment", 1);
        }
    }

    goUrl(url: string){
        location.href = url;
    }

    async activate(params: { packagekey: string }){

        const order = await this.ordersApi.getBaseInfo(params.packagekey);

        const ecommercePurchase = await this.ordersApi.getEcommerceGA4Purchase(params.packagekey);

        if(ecommercePurchase && order.status !== undefined && (order.status === "confirmed"|| order.status === "paid" ))
        {
           
            const dataLayer = (<any>window).dataLayer;   
       
            if(dataLayer){
                //  Test in Console: Indtast 'dataLayer' [Enter]
                dataLayer.push({
                    'event': 'purchase',
                    'ecommerce': ecommercePurchase
                            }); 
            }            
        }
    }
}