export class AddNumberValueConverter {
    toView(value: number, addNumber: number) {
        return value !== undefined ? value + addNumber : "";
    }
  }

  export class FixedDecimalValueConverter {
    toView(value: number, decimals: number) {
            return Number(value).toFixed(decimals);
    }
 }