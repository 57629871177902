import { autoinject } from "aurelia-framework";
import { OrdersApi, OrderDto } from '../api/orders-api';
import { Router, activationStrategy } from 'aurelia-router';
import { OpenIdConnect } from "aurelia-open-id-connect";
import { LogApi } from "../api/log-api";
import { GuestDto, GuestApi } from "../api/guest-api";

@autoinject()
export class Bookings {
    bookings!: OrderDto[];
    show!: string;
    user!: GuestDto;

    constructor(private router: Router, private oidc: OpenIdConnect, private guestApi: GuestApi, private ordersApi: OrdersApi, private logApi: LogApi) {
    }

    // Ensure that activate is run when navigateToRoute from selectTransportGroup
    determineActivationStrategy() {
        return activationStrategy.replace;
    } 

    async activate(params: {show: string}) {

        const user = await this.oidc.getUser();

        //const logText = user ? "user.access_token=" + user.access_token : "user=" + user;
        const logText = user && user.access_token.length > 100 ? "user.access_token(first 10)=" + user.access_token.substring(0,99) : "user=" + user;
        await this.logApi.LogSession(logText, "Client_Bookings", 3);

        if(user && user.access_token){

            const guest = await this.guestApi.getCurrentUser();

            if(guest && guest.mail){
                //  User exist in database
                this.user = guest;

                this.show = params.show ? params.show : 'future';

                if(this.show === 'future'){                    
                    this.bookings = await this.ordersApi.getFutureOrders();
                }
                else if(this.show === 'previous'){
                    this.bookings = await this.ordersApi.getPreviousOrders();
                }
                else{
                    throw Error("Unsupported show" + this.show);
                }

                await this.logApi.LogSession(this.bookings.length + " bookings found OK to user", "Client_Bookings", 3);
            }
        }
        else{
            await this.logApi.LogSession("Redirect to login", "Client_Bookings", 3);
            return this.oidc.login();

        }
    }

    selectBooking(selectedBooking: OrderDto){

        return this.router.navigateToRoute("", { packagekey: selectedBooking.packagekey});
    }
}

