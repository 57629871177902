import { DateTime } from "luxon";
import {autoinject} from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject()
export class DateFormatValueConverter {
   constructor(private i18n: I18N) {
   }

   toView(value: DateTime, options: string) {

      if(value){

         switch (options) {
            case "d": return value.day.toString();
            case "H:mm": {
               return value.hour.toString() + ":" + intToChar2(value.minute);
            }
            case "wd-short": return this.i18n.tr(`datetime:weekdays_short.${value.weekday}`);
            case "wd-long": return this.i18n.tr(`datetime:weekdays_long.${value.weekday}`);
            case "mo-short": return this.i18n.tr(`datetime:months_short.${value.month}`);
            case "mo-long": return this.i18n.tr(`datetime:months_long.${value.month}`);
            case "wd-short d": return this.i18n.tr(`datetime:weekdays_short.${value.weekday}`) + " " + value.day.toString();
            case "DATE_SHORT": return value.day.toString() + ". " + this.i18n.tr(`datetime:months_short.${value.month}`) + " " + value.year.toString();
            case "DATE_FULL": return value.day.toString() + ". " + this.i18n.tr(`datetime:months_long.${value.month}`) + " " + value.year.toString();
            case "cccc DD H:mm": return this.i18n.tr(`datetime:weekdays_long.${value.weekday}`) + " " + value.day.toString() + ". " + this.i18n.tr(`datetime:months_long.${value.month}`) + " " + value.year.toString()            
                                          + " " + value.hour.toString() + ":" + intToChar2(value.minute); 
         }

         alert("Not supported:" + options);
         return "?";
      }
      else{
         return"";
      }

   }
}
   function intToChar2(i: number) {

      const str = "0" + i.toString();

      return str.substr(str.length - 2, str.length);
   }

      // if(value && value.isValid && options){

      //    const uas = navigator.userAgent.replace(/\s/g, "");

      //    var ie = navigator.appName == 'Microsoft Internet Explorer' 
      //       || (navigator.appName == "Netscape" && navigator.appVersion.indexOf('Trident') > 0)
      //       || (navigator.platform == "iPad" && uas.indexOf('OS9_3') > 0)
      //       || (navigator.platform == "MacIntel" 
      //          && ( uas.indexOf('OSX10.6') > 0 || uas.indexOf('OSX10.7') > 0 || uas.indexOf('OSX10.8') > 0  || uas.indexOf('OSX10_8') > 0 || uas.indexOf('OSX10_9') > 0
      //             || uas.indexOf('Chrome/50') > 0 || uas.indexOf('Chrome/51') > 0 || uas.indexOf('Chrome/52') > 0 || uas.indexOf('Chrome/53') > 0 || uas.indexOf('Chrome/54') > 0
      //             || uas.indexOf('Chrome/55') > 0 || uas.indexOf('Chrome/56') > 0 || uas.indexOf('Chrome/57') > 0 || uas.indexOf('Chrome/58') > 0 || uas.indexOf('Chrome/59') > 0
      //             || uas.indexOf('Chrome/60') > 0 || uas.indexOf('Chrome/61') > 0 || uas.indexOf('Chrome/62') > 0 || uas.indexOf('Chrome/63') > 0 || uas.indexOf('Chrome/64') > 0
      //             || uas.indexOf('Chrome/65') > 0 || uas.indexOf('Chrome/66') > 0 || uas.indexOf('Chrome/67') > 0 || uas.indexOf('Chrome/68') > 0 || uas.indexOf('Chrome/69') > 0
      //             || uas.indexOf('Chrome/70') > 0))
      //        || (navigator.platform == "Win32" 
      //             && ( uas.indexOf('Edge/18') > 0));


      //    var  ios = iosHasLocaleError();

      //    if((ie === true || ios === true) && options !== 'd' && options != 'month')
      //    {  // What a hack - due to polyfill error 
      //       value = value.plus({ days: 1 });
      //    }

      //    const dateOnly = value.startOf("day");

      //    let dateString = "";
         
      //    if(options === 'd'){
      //       dateString = value.day.toString();
      //    }
      //    else if(options == 'H:mm'){
      //       dateString = value.toFormat("H:mm");
      //    }
      //    else if(options == 'weekday'){
      //       dateString = dateOnly.toLocaleString({weekday: "long"});   
      //    }        
      //    else if(options == 'wd-short d'){
      //       dateString = dateOnly.toLocaleString({ weekday: "short" }) + ". " + dateOnly.toLocaleString({ day: "numeric" })
      //    }            
      //    // else if(options == 'month'){
      //    //    dateString = monthString(ie, ios, dateOnly);
      //    // }  
      //    else if(options == 'DATE_FULL'){
      //       dateString = dateOnly.toLocaleString(DateTime.DATE_FULL);
      //    }         
      //    else if(options == 'cccc DD H:mm'){
      //       dateString = dateOnly.toLocaleString({weekday: "long"}) + " " + dateOnly.toLocaleString(DateTime.DATE_FULL) + " " + value.toFormat("H:mm");
      //    }
      //    else{
      //       dateString = value.toLocaleString(options);
      //    }

      //    if(ie || ios){  // Just for fault finding
      //       return dateString + ".";
      //    }
      //    else{
      //       return dateString;
      //    }

      // }
      // else{
      //    return "";
      // }

//  function iosHasLocaleError() {
//    if (/iP(hone|od|ad)/.test(navigator.platform)) {
//       var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);

//       if(v !== null){
//          if(parseInt(v[1], 10) === 10 && parseInt(v[2], 10) == 3){
//             return true;
//          }
//          else{
//             return false;
//          }
//       }
//       else{
//          return false;
//       }
//    }
//    else{
//       return false;
//    }
// }
