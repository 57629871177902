import { Container, PLATFORM, autoinject } from "aurelia-framework";
import { RouteConfig, Router, RouterConfiguration } from 'aurelia-router';

import { EditBookingWizard } from './edit/wizard';
import { TicketWizardKey } from "../../ticket-designer/ticket-wizard";
import { getParameter } from "../../query-string";

const routes: RouteConfig[] = [
    {
        route: "/",
        name: "details",
        moduleId: PLATFORM.moduleName("./booking-details")
    },
    {
        route: "tickets/:ticketIndex/departure-date",
        name: "departure-date",
        moduleId: PLATFORM.moduleName("../../ticket-designer/departure-date")
    },
    {
        route: "tickets/:ticketIndex/transport/:groupId?",
        name: "transport",
        moduleId: PLATFORM.moduleName("../../ticket-designer/transport")
    },
    {
        route: "tickets/:ticketIndex/trailer/:groupId?",
        name: "trailer",
        moduleId: PLATFORM.moduleName("../../ticket-designer/trailer")
    },
    {
        route: "tickets/:ticketIndex/transport-extra/:groupId?",
        name: "transport-extra",
        moduleId: PLATFORM.moduleName("../../ticket-designer/transport-extra")
    },
    {
        route: "tickets/:ticketIndex/passengers",
        name: "passengers",
        moduleId: PLATFORM.moduleName("../../ticket-designer/passengers")
    },
    {
        route: "tickets/:ticketIndex/departure",
        name: "departure",
        moduleId: PLATFORM.moduleName("../../ticket-designer/departure")
    },
    {
        route: "summary",
        name: "summary",
        moduleId: PLATFORM.moduleName("./edit/summary")
    },
    {
        route: "meals",
        name: "meals",
        moduleId: PLATFORM.moduleName("../../ticket-designer/meals")
    },
    {
        route: "contact",
        name: "contact",
        moduleId: PLATFORM.moduleName("./edit/contact")
    },
    {
        route: "terms",
        name: "terms",
        moduleId: PLATFORM.moduleName("./edit/terms")
    },
    {
        route: "contact-confirm",
        name: "contact-confirm",
        moduleId: PLATFORM.moduleName("./edit/contact-confirm")
    },
    {
        route: "basket",
        name: "basket",
        moduleId: PLATFORM.moduleName("./edit/basket")
    },
    {
        route: "pay",
        name: "pay",
        moduleId: PLATFORM.moduleName("./edit/pay")
    },
    {
        route: "payment-error-onpay",
        name: "payment-error-onpay",
        moduleId: PLATFORM.moduleName("./payment-error-onpay")
    },
    {
        route: "receipt-no-payment",
        name: "receipt-no-payment",
        moduleId: PLATFORM.moduleName("./receipt-no-payment")
    },
    {
        route: "receipt-payment",
        name: "receipt-payment",
        moduleId: PLATFORM.moduleName("./receipt-payment")
    },
    {
        route: "receipt-payment-onpay",
        name: "receipt-payment-onpay",
        moduleId: PLATFORM.moduleName("./receipt-payment-onpay")
    },
    {
        route: "add-info",
        name: "add-info",
        moduleId: PLATFORM.moduleName("./add-info")
    }
];

@autoinject()
export class BookingRouter {
    get bookingJson() {
        const parameter = getParameter("booking");

        if (parameter) {
            return atob(decodeURIComponent(parameter));
        }
    }
    
    constructor(private container: Container) {
    }

    async configureRouter(config: RouterConfiguration, router: Router) {
        config.map(routes);

        this.container.registerTransient(EditBookingWizard, EditBookingWizard);
        this.container.registerTransient(TicketWizardKey, () => this.container.get(EditBookingWizard));
    }
}