import { ITicketWizard, TicketWizardKey } from './ticket-wizard';
import { Router, activationStrategy, RouteConfig, RedirectToRoute, NavigationInstruction } from 'aurelia-router';
import { autoinject, inject } from 'aurelia-framework';
import { MessageModel } from "../models/message-model";
import { DateTime } from 'luxon';
import { DeparturesApi, Departure } from '../api/departures-api';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';
import { defaults, flatten, groupBy, orderBy } from 'lodash-es';
import { DepartureItemValue } from './resources/departure-item';
import { CrossingsComputer } from '../crossings-builder';
import { AppConfigApi } from '../api/app-config-api';
import { OpenIdConnect } from 'aurelia-open-id-connect';
import { GuestApi } from '../api/guest-api';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class DepartureStep {
    waitListInfoPriority: number | undefined;
    waitListInfo: string | undefined; 
    prevDate?: DateTime;
    nextDate?: DateTime;
    booking!: Booking;
    private ticketIndex!: number;
    date!: DateTime;
    departures!: DepartureItemValue[];
    selectedDepartureId: number | undefined;
    currency!: string;
    showDeparturePrice: boolean = true;
    message: MessageModel;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private router: Router, 
        private appConfigApi: AppConfigApi, private departuresApi: DeparturesApi, private crossingsComputer: CrossingsComputer,
        private oidc: OpenIdConnect, private guestApi: GuestApi, private i18n: I18N) {
            this.message = new MessageModel('hide', undefined, undefined)
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    async canActivate(params: {packagekey?: string, booking: string, ticketIndex: string, flowGroup?: string}, _config: RouteConfig, navigationInstruction: NavigationInstruction) {
        const ticketIndex = parseInt(params.ticketIndex);
        if (params.flowGroup && ticketIndex >= 1) {
            const flowGroup = this.wizard.config.value.getFlowGroup(params.flowGroup);
            if (flowGroup.autoFillNextDepart) {
                const booking = this.bookingSerializer.deserialize(params.booking);
                const ticket = booking.getTicket(ticketIndex);
                if (!ticket.departureId) {
                    const previousTicket = booking.getTicket(ticketIndex - 1);
                    if (previousTicket.departureId) {
                        const previousDeparture = await this.departuresApi.getDepartureBaseInfo(previousTicket.departureId);
                        const date = ticket.departureDate;
                        const departures = await this.getDepartures(booking, ticketIndex, date, params.flowGroup, params.packagekey);
                        const nextDeparture = departures.find(x => x.departs >= previousDeparture.arrives);
                        if (nextDeparture) {
                            const status = nextDeparture.status;
                            if (status === "ok" || status === "warning") {
                                ticket.setDeparture(nextDeparture);
                                const [route, params] = this.wizard.getNextStep(booking, navigationInstruction);
                                return new RedirectToRoute(route, params);
                            }
                        }
                    }
                }
            }
        }
             
        return true;
    }

    async activate(params: {packagekey?: string, booking: string; ticketIndex: string, date?: string, flowGroup?: string }, _config: RouteConfig) {

        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppDepartureInfoMessage');

        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }

        const user = await this.oidc.getUser();
        let guestWaitListPriority = undefined;
        this.waitListInfoPriority = undefined;
        this.waitListInfo = undefined;
        
        if (user && user.profile && user.profile.email && user.profile.email_verified) {

            const guest = await this.guestApi.getCurrentUser();

            if(guest && guest.mail && guest.name){
                guestWaitListPriority = guest.waitListPriority;
            }
        }

        this.booking = this.bookingSerializer.deserialize(params.booking);
        this.ticketIndex = parseInt(params.ticketIndex);

        const ticket = this.booking.getTicket(this.ticketIndex);
        const prevTicket = this.ticketIndex > 0 ? this.booking.getTicket(this.ticketIndex - 1) : undefined;
        const nextTicket = this.booking.getTicket(this.ticketIndex + 1);

        this.selectedDepartureId = ticket.departureId;

        const transportId = ticket.transportId;
        const passengers = ticket.passengers;
        if (!ticket.departureDate.isValid || !transportId) {
            throw new Error("There are missing parameters for departure selection");
        }

        this.date = params.date ? DateTime.fromISO(params.date) : ticket.departureDate;

        const today = DateTime.local().startOf("day");

        //  Just to set arrows
        if (!(prevTicket && prevTicket.departureDate.isValid && this.date <= prevTicket.departureDate)
            && this.date > today) {

            this.prevDate = this.date.plus({ days: -1 });
        }
        else {
            this.prevDate = undefined;
        }

        if (!(nextTicket && nextTicket.departureDate.isValid && this.date >= nextTicket.departureDate)) {

            this.nextDate = this.date.plus({ days: 1 });
        }
        else {
            this.nextDate = undefined;
        }

        this.departures = await this.getDepartures(this.booking, this.ticketIndex, this.date, params.flowGroup, params.packagekey);

        let prevArrivalDateTime;
        if (prevTicket !== undefined && prevTicket.departureId !== undefined) {
            //  Find arrival from previous departure
            let prevDeparture = await this.departuresApi.getDepartureBaseInfo(prevTicket.departureId);
            prevArrivalDateTime = prevDeparture.arrives;
        }


        let nextDepartDateTime;
        if (nextTicket !== undefined && nextTicket.departureId !== undefined) {
            //  Find departs from next departure
            let nextDeparture = await this.departuresApi.getDepartureBaseInfo(nextTicket.departureId);
            nextDepartDateTime = nextDeparture.departs;
        }

        const appConfig = await this.appConfigApi.get();

        for (let dep of this.departures) {

            if ((prevArrivalDateTime !== undefined && prevArrivalDateTime > dep.departs)
                || (nextDepartDateTime !== undefined && nextDepartDateTime < dep.arrives)
                || dep.status === 'ticketsblocked'
                || dep.status === 'blocked'
                || dep.status === 'closed'
                || dep.status === 'full'
            ) {
                dep.disabled = true;
            }

            if(dep.status === 'full' && dep.waitListQty !== null){
                //  dep.waitListQty != undefined from server if Gust has waitListPriority or priority on loadPlan is 99 (public)

                dep.waitListPriority = guestWaitListPriority ? guestWaitListPriority : 99;
                dep.disabled = false;

                //  We have one possible WaitListDeparture, show info
                this.waitListInfoPriority = dep.waitListPriority;
            }

            if(appConfig.showPortsAsRegions === true){
                dep.fromPortId = "";
                dep.toPortId = "";
            }
            else{
                if(prevTicket && prevTicket.departureId && prevTicket.toRegionId !== dep.fromRegionId){
                    //  Different fromregion from prev toRegion
                    dep.fromRegionWarning = true;
                }

                if(nextTicket && nextTicket.departureId && nextTicket.fromRegionId !== dep.toRegionId){
                    //  Different toRegion from next fromRegion
                    dep.toRegionWarning = true;
                }

                //  
                if(this.ticketIndex > 0 && this.ticketIndex === this.booking.length -1){
                    //  last ticket in flow with more than one ticket
                    const firstTicket = this.booking.getTicket(0);
                    if(firstTicket && firstTicket.departureId && firstTicket.fromRegionId !== dep.toRegionId){
                        //  Different toRegion from first fromregion
                        dep.toRegionWarning = true;
                    }
                }
            }
        }

        if(this.waitListInfoPriority){
            this.waitListInfo =  this.i18n.tr('portal-text:FerryWebAppDepartureWaitListInfo');
        }

        if(params.flowGroup){

            const flowGroup = this.wizard.config.value.getFlowGroup(params.flowGroup);

            this.showDeparturePrice = !flowGroup.hideDeparturePrice;
        }

    }

    private async getDepartures(booking: Booking, ticketIndex: number, date: DateTime, flowGroup?: string, packagekey?: string) {
        // Find possible crossings
        const crossings = this.crossingsComputer.getCrossings(booking, ticketIndex, flowGroup);
        const ticket = booking.getTicket(ticketIndex);

        // Find departures from crossings
        const crossingDepartures: Departure[][] = [];
        for (const crossing of crossings) {
            crossingDepartures.push(await this.departuresApi.getDepartures(flowGroup, crossing.fromRegionId, crossing.toRegionId, date, ticket.transportId!, ticket.trailerId, ticket.totalWeights, ticket.passengers, packagekey));
        }

        return flatten(crossingDepartures).sort((a,b) => a.departs < b.departs ? -1 : a.departs > b.departs ? 1 : 0);
    }

    step(days: number) {
        const params = defaults({
            date: this.date.plus({ days: days }).toISODate()
        }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);

        return this.router.navigateToRoute("departure", params);
    }

    selectDeparture(item: DepartureItemValue) {
        if (!item.disabled) {
 
            const ticket = this.booking.getTicket(this.ticketIndex);

            ticket.setDeparture(item);

            ticket.waitListPriority = item.waitListPriority;

            return this.wizard.navigateToNextStep(this.booking);
        }
    }
}

