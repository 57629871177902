import { autoinject, bindable, computedFrom } from "aurelia-framework";

import { Router } from "aurelia-router";
import { defaults } from "lodash-es";

@autoinject()
export class TransportExtraSummaryCustomElement {
    @bindable()
    totalWeights!: number;

    @bindable()
    weightIconUrl?: string;

    @bindable()
    href?: string;

    @bindable()
    ticketIndex?: number;

    @bindable()
    selected = false;

    constructor(private router: Router) {
    }   

    attached() {
        if (!this.href && typeof this.ticketIndex === "number" && this.ticketIndex >= 0) {
            const params = defaults({
                ticketIndex: this.ticketIndex
            }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
        
            this.href = this.router.generate("transport-extra", params);
        }
    }

}