import { autoinject } from "aurelia-framework";
import { LocaleId } from "../locales";
import { ApiClient } from "./api-http-client";

@autoinject()
export class GuestApi {
    constructor(private client: ApiClient) {
    }

    async createGuest(name: string, postalCode: string, city: string, country: string, phone: string ) {
        const command: {
            name: string | undefined,
            postalCode: string | undefined,
            city: string | undefined,
            country: string | undefined,
            phone: string | undefined} = {
            name: name,
            postalCode: postalCode,
            city: city,
            country: country,
            phone: phone
        };

        const url = '/guests/create/';
        const response = await this.client.post(url, JSON.stringify(command));

        if (response.status != 200) {
            throw Error("Error creating order");
        }

        const createGuestDto = await response.json() as CreateGuestDto;

        return createGuestDto;
    }

    async updateGuest(language: string) {
        const command: {
            language: string | undefined} = {
            language: language
        };

        const url = '/guests/update/';
        const response = await this.client.post(url, JSON.stringify(command));

        if (response.status != 200) {
            throw Error("Error creating order");
        }

        await response;
    }

    async getCurrentUser() {
        //  First check if user is logget in

        const url = '/guests/currentuser';
        const response = await this.client.get(url);            

        if (response.status != 200) {
            throw Error("Error getting current user");
        }

        //  If not found in database we return only email address from user
        const guestDto = await response.json() as GuestDto;

        return guestDto;
    }

    async newPasswordRequest(mail: string, phone: string, orderId: number) {

        const url = '/guests/newpasswordrequest?mail=' + mail + '&phone=' + phone + '&orderid=' + orderId;
        const response = await this.client.get(url);            

        if (response.status != 200) {
            throw Error("Error getting order baseinfo");
        }

        const status = await response.json() as PasswordRequestDto;

        return status;
    }
}

export interface CreateGuestDto {
    guestId?: number;
    text: string;
    status: string;
}

export interface GuestDto {
    name: string;
    zip: string;
    city: string;
    country: string;
    mail: string;
    phone: string;
    carRegistration: string;
    language: LocaleId;
    waitListPriority?: number;
    key: string;
    ferryPostingsReportUrl: string;
    newsLetter: boolean;
}

export interface PasswordRequestDto {
    guestId?: number;
    guestName?: string;
    status: string;
}



