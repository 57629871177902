export function getParameter(name: string) {
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const match = regex.exec(window.location.href);
    if (match) {
        if (match[1].length > 0) {
            return decodeURIComponent(match[2]);
        }
        else {
            return null;
        }
    }
}