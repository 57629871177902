import { autoinject } from "aurelia-framework";
import { DateTime } from "luxon";
import { ApiClient } from "./api-http-client";

@autoinject()
export class AppConfigApi {
    constructor(private client: ApiClient) {

    }
    async get() {
        const response = await this.client.get("/appconfig");
        const json = await response.json();

        const config = new AppConfig(json);

        return config;
    }
}

export class AppConfig {
    vendorAlias!: string;
    oidcTenantId!: string;
    oidcClientId!: string;
    showPortsAsRegions!: boolean;
    ticketIsValidAllDay!: boolean;
    showCookieSelect!: boolean;
    showStatisticCookie!: boolean;
    ticketIconUrl!: string;
    couponValueSaleUrl!: string;
    couponDiscountSaleUrl!: string;
    couponSubscriptionSaleUrl!: string;
    receiptRedirectUrl!: string;
    paymentWindow!: string;
    defaultPayMethod!: string;
    homePageUrl!: string;
    companyHeader!: string;
    companyName!: string;
    companyCVR!: string;
    companyPhone!: string;
    currency!: string;

    countries!: {
        id: string;
        name: string;
    }[];

    usePostalCode!: boolean;
    useCity!: boolean;

    constructor(init: Partial<AppConfig>) {
        Object.assign(this, init);
    }
}

