import { Booking } from "./booking";
import { autoinject } from "aurelia-framework";
import { TicketConfigAccessor } from "./ticket-designer/ticket-config";
import { DateTime } from "luxon";

@autoinject()
export class BookingSerializer {
    constructor(private config: TicketConfigAccessor) {
    }

    serialize(booking: Booking) {
        const value: SerializedBooking = {
            tickets: booking.getTickets().map(x => {
                return {
                    departureDate: x.departureDate && x.departureDate.isValid ? x.departureDate.toISODate() : undefined,
                    transportId: x.transportId ? x.transportId : undefined,
                    trailerId: x.trailerId ? x.trailerId : undefined,
                    totalWeights: x.totalWeights,
                    passengers: x.passengers ? x.passengers : undefined,
                    meals: x.meals,
                    fromRegionId: x.fromRegionId,
                    toRegionId: x.toRegionId,
                    departureId: x.departureId ? x.departureId : undefined,
                    disabled: x.disabled,
                    cancelled: x.cancelled,
                    waitListPriority: x.waitListPriority
                };
            }),
            email: booking.email,
            name: booking.name,
            postalCode: booking.postalCode,
            city: booking.city,
            country: booking.country,
            phone: booking.phone,
            carRegistration: booking.carRegistration,
            couponCode: booking.couponCode,
            newsLetter: booking.newsLetter,
            termsAccepted: booking.termsAccepted,
            note: booking.note,
            clipAsPrice0: booking.clipAsPrice0,
            cvr: booking.cvr,
            onlyReservation: booking.onlyReservation,
            onlyOnAccount: booking.onlyOnAccount,
            askForFlow: booking.askForFlow
        };

        return encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(value))));
    }

    deserialize(serialized: string) {
        const value: SerializedBooking = JSON.parse(decodeURIComponent(atob(decodeURIComponent(serialized))));
        const tickets = value.tickets.map(x => {
            return {
                departureDate: x.departureDate ? DateTime.fromISO(x.departureDate) : undefined,
                transportId: x.transportId,
                trailerId: x.trailerId,
                totalWeights: x.totalWeights,
                passengers: x.passengers,
                meals: x.meals || {},
                fromRegionId: x.fromRegionId,
                toRegionId: x.toRegionId,
                departureId: x.departureId,
                disabled: x.disabled,
                cancelled: x.cancelled,
                waitListPriority: x.waitListPriority
            };
        });

        const booking = new Booking(this.config, ...tickets);
        booking.email = value.email;
        booking.name = value.name;
        booking.postalCode = value.postalCode;
        booking.city = value.city;
        booking.country = value.country;
        booking.phone = value.phone;
        booking.carRegistration = value.carRegistration;
        booking.couponCode = value.couponCode;
        booking.newsLetter = value.newsLetter;
        booking.termsAccepted = value.termsAccepted;
        booking.note = value.note;
        booking.clipAsPrice0 = value.clipAsPrice0;
        booking.cvr = value.cvr;        
        booking.onlyReservation = value.onlyReservation;
        booking.onlyOnAccount = value.onlyOnAccount;
        booking.askForFlow = value.askForFlow;
        
        return booking;
    }
}

export interface SerializedBooking {
    tickets: SerializedTicket[];
    email?: string;
    name?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    carRegistration?: string;
    couponCode?: string;
    newsLetter?: boolean;
    termsAccepted?: boolean;
    note?: string;
    clipAsPrice0?: boolean;
    cvr?: string;
    onlyReservation?: boolean;
    onlyOnAccount?: boolean;
    askForFlow: boolean;
}

export interface SerializedTicket {
    departureDate: string | undefined;
    transportId: number | undefined;
    trailerId: number | undefined;
    totalWeights: number | undefined;
    passengers: { [id: string]: number } | undefined;
    meals: { [id: string]: number };
    fromRegionId: string | undefined;
    toRegionId: string | undefined;
    departureId: number | undefined;
    disabled: boolean | undefined;
    cancelled: boolean | undefined;
    waitListPriority: number | undefined;
}