import {autoinject} from 'aurelia-framework';
import { TermsApi } from '../api/terms-api';
import { Booking } from '../booking';
import { NewBookingWizard } from './wizard';
import { BookingSerializer } from '../booking-serializer';
import lowercase from '../lowercase';

@autoinject()
export class TermsComponent {

    termsText!: string;
    private booking!: Booking;
    
    constructor (private termsApi: TermsApi, private wizard: NewBookingWizard, private bookingSerializer: BookingSerializer) {}

    async activate(params: {booking: string}) {  

        params = lowercase(params);
         
        if(params.booking !== undefined){
            this.booking = this.bookingSerializer.deserialize(params.booking);  
        }

        const terms = await this.termsApi.getTerms();

        this.termsText = terms.text;

    }

    acceptTerms() {
        this.booking.termsAccepted  = true;
        return this.wizard.navigateToRoute("contact", this.booking);
    }
}