import { Container, PLATFORM, autoinject, inject } from "aurelia-framework";
import { RouteConfig, Router, RouterConfiguration } from "aurelia-router";

import { NewBookingWizard } from "./wizard";
import { TicketWizardKey } from "../ticket-designer/ticket-wizard";
import { getParameter } from "../query-string";

const routes: RouteConfig[] = [
    {
        route: "/",
        name: "flow-group",
        moduleId: PLATFORM.moduleName("./flow-group")
    },
    {
        route: ":ticketIndex/departure-date",
        name: "departure-date",
        moduleId: PLATFORM.moduleName("../ticket-designer/departure-date")
    },
    {
        route: ":ticketIndex/transport/:groupId?",
        name: "transport",
        moduleId: PLATFORM.moduleName("../ticket-designer/transport")
    },
    {
        route: ":ticketIndex/trailer/:groupId?",
        name: "trailer",
        moduleId: PLATFORM.moduleName("../ticket-designer/trailer")
    },
    {
        route: ":ticketIndex/transport-extra",
        name: "transport-extra",
        moduleId: PLATFORM.moduleName("../ticket-designer/transport-extra")
    },
    {
        route: ":ticketIndex/passengers",
        name: "passengers",
        moduleId: PLATFORM.moduleName("../ticket-designer/passengers")
    },
    {
        route: ":ticketIndex/departure",
        name: "departure",
        moduleId: PLATFORM.moduleName("../ticket-designer/departure")
    },
    {
        route: "flow",
        name: "flow",
        moduleId: PLATFORM.moduleName("../ticket-designer/flow")
    },
    {
        route: "summary",
        name: "summary",
        moduleId: PLATFORM.moduleName("./summary")
    },
    {
        route: "meals",
        name: "meals",
        moduleId: PLATFORM.moduleName("../ticket-designer/meals")
    }, 
    {
        route: "contact",
        name: "contact",
        moduleId: PLATFORM.moduleName("./contact")
    },
    {
        route: "terms",
        name: "terms",
        moduleId: PLATFORM.moduleName("./terms")
    },
    {
        route: "contact-confirm",
        name: "contact-confirm",
        moduleId: PLATFORM.moduleName("./contact-confirm")
    },
    {
        route: "basket",
        name: "basket",
        moduleId: PLATFORM.moduleName("./basket")
    },
    {
        route: "init",
        name: "init",
        moduleId: PLATFORM.moduleName("./init")
    }
];

@autoinject()
export class NewBookingRouter {
    get bookingJson() {
        const parameter = getParameter("booking");

        if (parameter) {
            return atob(decodeURIComponent(parameter));
        }
    }


    constructor(private container: Container) {
    }

    async configureRouter(config: RouterConfiguration, router: Router) {
        config.map(routes);

        this.container.registerTransient(NewBookingWizard, NewBookingWizard);
        this.container.registerTransient(TicketWizardKey, () => this.container.get(NewBookingWizard));
    }
}