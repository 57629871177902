import { autoinject, bindable, computedFrom } from "aurelia-framework";

import { TicketConfigAccessor } from "../ticket-config";

@autoinject()
export class TrailerItemCustomElement {
    @bindable()
    value!: number;

    @bindable()
    selected = false;

    icon?: string;
    height?: number | [number, number];
    length?: number | [number, number];

    constructor(private config: TicketConfigAccessor) {
    }

    valueChanged() {
        const trailer = this.config.value.getTrailer(this.value);

        this.icon = trailer.icon;
        this.height = trailer.height;
        this.length = trailer.length;
    }
}