import { I18N } from "aurelia-i18n";
import { Booking } from "../booking";
import { NewBookingWizard } from "./wizard";
import { MessageModel } from "../models/message-model";
import { OrdersApi, BasketDto } from "../api/orders-api";
import { autoinject, Disposable } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { BookingSerializer } from "../booking-serializer";
import { OnPayApi } from "../api/onpay-api";
import { AppConfigApi } from "../api/app-config-api";
import { EA_I18N_LOCALE_CHANGED } from "../locale-service";

@autoinject()
export class basket {
    booking!: Booking;
    basket!: BasketDto;
    message: MessageModel;
    showOnlyReservation!: boolean;
    showOnlyOnAccount!: boolean;
    onlyReservation!: boolean;
    onlyOnAccount!: boolean;
    hasPaymentGateway!: boolean;
    hasMobilePay!: boolean;
    showContent: Boolean = true;
    onlyReservationInfo!: string;
    showOnlyReservationInfo: boolean = false;
    private disposables!: Disposable[];

    constructor(private wizard: NewBookingWizard, private bookingSerializer: BookingSerializer, private i18n: I18N,
        private ordersApi: OrdersApi, private router: Router, private onPayApi: OnPayApi, private appConfigApi: AppConfigApi, private eventAggregator: EventAggregator){
        this.message = new MessageModel('hide', undefined, undefined);
    }
    
    async activate(params: { booking: string }) {   
        
        const url = params.booking.length;

        this.booking = this.bookingSerializer.deserialize(params.booking);


        const appConfig = await this.appConfigApi.get();
        this.hasPaymentGateway = appConfig.paymentWindow.length > 0;
        this.hasMobilePay = appConfig.defaultPayMethod == "Select" || appConfig.defaultPayMethod == "MobilePay";

        await this.loadBasket();

        // //  Get from booking if already set
        this.onlyReservation = this.booking.onlyReservation !== undefined ? this.booking.onlyReservation : false;

        this.onlyOnAccount = this.booking.onlyOnAccount !== undefined ? this.booking.onlyOnAccount : false;

        this.onlyReservationInfo =  this.i18n.tr('portal-text:FerryWebAppBasketOnlyReservationInfo');

        //  Key is returned if value is missing
        this.showOnlyReservationInfo = this.onlyReservationInfo.indexOf(" ") > 0;

        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppBasketInfoMessage');

        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }
    }

    bind() {
        this.disposables = [
            this.eventAggregator.subscribe(EA_I18N_LOCALE_CHANGED, () => this.loadBasket()),
        ];
    }

    unbind() {
        for (const disposable of this.disposables) {
            disposable.dispose();
        }
    }

    private async loadBasket() {
        this.basket = await this.ordersApi.preview(this.booking, undefined)

        this.showOnlyReservation = this.basket.totalOnAccount === 0 
        && this.basket.totalNet > 0 && this.basket.totalCoupon === 0
        && this.hasPaymentGateway;

        //  We dont support OnAccount when working with CouponCode
        this.showOnlyOnAccount  = this.basket.totalOnAccount < this.basket.totalNet 
        && this.basket.totalOnAccount > 0 && this.basket.totalCoupon === 0;
    }

    async toggleOnlyReservation(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.onlyReservation = !this.onlyReservation;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    async toggleOnlyOnAccount(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.onlyOnAccount = !this.onlyOnAccount;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    async goNext() {

        this.showContent = false;

        this.booking.onlyReservation = this.onlyReservation;
        this.booking.onlyOnAccount = this.onlyOnAccount;

        const noPayment = !(!this.onlyReservation && this.basket.totalNet > 0 && this.hasPaymentGateway);

        let newOrder = await this.ordersApi.create(this.booking, noPayment, this.basket.currency);

        if (newOrder === "fullybooked") {
            this.message.header = this.i18n.tr("new-booking:order.message.booking-fullybooked.header");
            this.message.text = this.i18n.tr("new-booking:order.message.booking-fullybooked.text");            
            this.message.status = "error";

            this.showContent = true;
        }
        else if(newOrder === "closed"){
            this.message.header = this.i18n.tr("new-booking:order.message.booking-closed.header");
            this.message.text = this.i18n.tr("new-booking:order.message.booking-closed.text");            
            this.message.status = "error";

            this.showContent = true;
        }
        else {

            if (noPayment) {

                const newUrl = "https://" + window.location.host + "/my-booking/bookings/" + newOrder.packagekey + "/receipt-no-payment?lang=" + this.i18n.getLocale();

                window.location.href = newUrl;
            }
            else {
                const appConfig = await this.appConfigApi.get();

                if(appConfig.paymentWindow == "OnPay"){
                  
                    // const amountCurrency = this.booking.onlyOnAccount ? this.basket.totalOnAccount : this.basket.totalNet;
                    // const amountCurrencyInt = Math.round(amountCurrency * 100);
                    // await this.onPayApi.PostOnPay(newOrder.packagekey, amountCurrencyInt, undefined);

                    await this.onPayApi.PostOnPay(newOrder.packagekey, this.booking.onlyOnAccount, undefined);
                }
            }
        }
    }
}
