import { RouteConfig, RouterConfiguration } from "aurelia-router";
import { PLATFORM } from "aurelia-framework";

const routes: RouteConfig[] = [
    {
        route: "",
        name: "dispatch",
        moduleId: PLATFORM.moduleName("./dispatch")
    },
    {
        route: "unverified",
        name: "unverified",
        moduleId: PLATFORM.moduleName("./unverified")
    },
    {
        route: "create",
        name: "create",
        moduleId: PLATFORM.moduleName("./create")
    },
    {
        route: "coupon-code-accept",
        name: "coupon-code-accept",
        moduleId: PLATFORM.moduleName("./coupon-code-accept")
    }
]

export class AccountRouter {
    configureRouter(config: RouterConfiguration) {
        config.map(routes);
    }
}