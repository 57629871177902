import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { DateTime } from "luxon";

@autoinject()
export class TimetableDateItemCustomElement {
    @bindable()
    value!: DateTime;

    @bindable()
    note?: string;

    @bindable()
    selected = true;

    @bindable()
    disabled = false;

    @bindable()
    hasdeparture?: boolean;

    @computedFrom("value")
    get isToday() {
        if (this.value) {
            return this.value.equals(DateTime.local().startOf("day"));
        }
    }

    click(event: Event) {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
}

