import { bindable, computedFrom } from "aurelia-framework";

export class RegionSummaryCustomElement {
    @bindable()
    regionId!: string;

    @bindable()
    selected = false;

    @computedFrom("regionId")
    get imageUrl() {
        if (this.regionId) {
            return "https://tbferry.blob.core.windows.net/icons/routes/default/" + this.regionId + ".png";
            // return require(`./regions/${this.regionId}.png`);
        }
    }
}