import { autoinject } from "aurelia-framework";
import { Router, Redirect } from 'aurelia-router';
import { OpenIdConnect } from "aurelia-open-id-connect";


@autoinject()
export class Login {

    constructor(private router: Router, private oidc: OpenIdConnect, ) {
    }

    mail!: string;
    orderId!: string;

    async activate(){

    }

    login(){
        return this.oidc.login();
    }
}