export function isValid(value: string) {
    return value && value.charAt(0) === "+";
}

export function fromMsisdn(value: number | undefined) {
    if (!value) {
        return "";
    }

    return "+" + value;
}

export function getMsisdn(value: string) {
    if (!isValid(value)) {
        return;
    }

    // The value is valid, remove '+'
    return Number(value.slice(1));
}
