import {autoinject, DOM} from 'aurelia-framework';
import { ApiClient } from './api-http-client';

export interface PaymentFormOnPay {
    onpay_gatewayid: string;
    onpay_currency: string;
    onpay_amount: string;       
    onpay_reference: string;         
    onpay_hmac_sha1: string;
    onpay_accepturl: string;
    onpay_type: string;
    onpay_method:string;
    onpay_language: string;
    onpay_declineurl: string;
    onpay_callbackurl: string;         
}

@autoinject()
export class OnPayApi {

    constructor(private client: ApiClient) {
    }
    
    async PostOnPay(packagekey: string, onlyOnAccount: boolean, payMethod: string | undefined) {
        let url = '/orders/' + packagekey + '/paymentform/onpay?onlyonaccount=' + onlyOnAccount;

        if(payMethod != undefined)
            url += '&paymethod=' + payMethod;

        const response = await this.client.get(url);

        if (response.status != 200) {
            throw Error("Error fetching onpay paymentform");
        }

        const paymentForm = await response.json() as PaymentFormOnPay;

        let form = DOM.createElement('form') as HTMLFormElement;
        form.method = 'POST';
        form.action = 'https://onpay.io/window/v3/';
        form.acceptCharset = 'UTF-8';

        form.appendChild(formField('onpay_gatewayid', paymentForm.onpay_gatewayid));
        form.appendChild(formField('onpay_currency', paymentForm.onpay_currency));
        form.appendChild(formField('onpay_amount', paymentForm.onpay_amount));
        form.appendChild(formField('onpay_reference', paymentForm.onpay_reference));
        form.appendChild(formField('onpay_hmac_sha1', paymentForm.onpay_hmac_sha1));
        form.appendChild(formField('onpay_accepturl', paymentForm.onpay_accepturl));
        form.appendChild(formField('onpay_type', paymentForm.onpay_type));
        form.appendChild(formField('onpay_method', paymentForm.onpay_method));
        form.appendChild(formField('onpay_language', paymentForm.onpay_language));
        form.appendChild(formField('onpay_declineurl', paymentForm.onpay_declineurl));
        form.appendChild(formField('onpay_callbackurl', paymentForm.onpay_callbackurl));

        DOM.appendNode(form);
        form.submit();

    }
}

    function formField(name: string, value: string) {
        let field = DOM.createElement('input') as HTMLInputElement;
        field.type = 'hidden';
        field.name = name;
        field.value = value;
        return field;
      }