import { ITicketWizard, TicketWizardKey } from './ticket-wizard';
import { Router, activationStrategy, RedirectToRoute, RouteConfig } from 'aurelia-router';
import { autoinject, inject } from 'aurelia-framework';
import { defaults, uniq, extend } from 'lodash-es';

import { TicketConfigAccessor } from './ticket-config';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';
import { DeparturesApi } from '../api/departures-api';
import { WizardI18N } from './wizard-i18n';
import { TransportsApi } from '../api/transports-api';
import { CrossingsComputer } from '../crossings-builder';

@autoinject()
export class Transport {
    title!: string;
    booking!: Booking;
    private ticketIndex!: number;
    groupIds!: string[];
    selectedGroupId!: string;
    selectedTransportId: number | undefined;
    transports!: TransportViewModel[];
    ticketsFullSelected: boolean = false;
    packageKey!: string | undefined;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer,
       private transportApi: TransportsApi, private departureApi: DeparturesApi, private router: Router, private i18n: WizardI18N, private config: TicketConfigAccessor, private crossingsComputer: CrossingsComputer) {
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    async canActivate(params: {packagekey?: string, booking: string, ticketIndex: string, flowGroup?: string, groupId?: string}, routeConfig: RouteConfig) {

        this.packageKey = params.packagekey;

        this.booking = this.bookingSerializer.deserialize(params.booking);
        this.ticketIndex = parseInt(params.ticketIndex);
        const ticket = this.booking.getTicket(this.ticketIndex);

        const crossings = this.crossingsComputer.getCrossings(this.booking, this.ticketIndex, params.flowGroup);
        const transportsValidForCrossings = await this.transportApi.getTransportsByFlowSteps(crossings);

        let transportsNotBlocked = this.config.value.transports
        .filter(x => x.visible)
        .filter(x => x.blockedPeriods.find(bp => ticket.departureDate >= bp.startDate && ticket.departureDate <= bp.endDate) === undefined)
        .filter(x => transportsValidForCrossings.indexOf(x.id) > -1);

        this.transports = transportsNotBlocked.map(x => {
            return {
                id: x.id,
                groupId: x.groupId
            };
        });

        if (!params.groupId) {
            let groupId: string;

            if (ticket.transportId) {
                groupId = this.config.value.getTransport(ticket.transportId).groupId;
            }
            else if (params.flowGroup) {
                // TODO: Use above transports based on crossings and set groupId to the first of the transports

                //  Take first visible group
                groupId = this.transports[0].groupId;                    

            }
            else {
                throw new Error("Unable to find a transport to derive the group from");
            }

            if (routeConfig.name) {
                return new RedirectToRoute(routeConfig.name, extend(params, {
                    groupId: groupId
                }));
            }
        }

        return true;
    }

    async activate(params: { tickets: string, ticketIndex: string, groupId: string }) {

        const ticket = this.booking.getTicket(this.ticketIndex);

        this.title = this.i18n.tr("ticket-designer:transport.title", {
            fromRegionId: ticket.fromRegionId,
        });

        this.selectedGroupId = params.groupId;

        this.groupIds = uniq(this.transports.map(x => x.groupId));

        this.transports = this.transports.filter(x => x.groupId === this.selectedGroupId);

        this.ticketsFullSelected = this.booking.areFullSelected();

        this.selectedTransportId = ticket.transportId;

    }

    async selectTransport(transportId: number) {
        const ticket = this.booking.getTicket(this.ticketIndex);

        const transportHasChanged = ticket.transportId !== transportId;

        if(transportHasChanged){
            ticket.setTransportId(transportId);

            ticket.totalWeights = undefined;

            if(ticket.departureId && ticket.transportId && !ticket.waitListPriority) {

                const checkAvailability = await this.departureApi.checkAvailability(ticket.departureId, ticket.transportId, ticket.trailerId, ticket.totalWeights, ticket.passengers, this.packageKey);

                if(!checkAvailability.isAvailable){
                    ticket.departureId = undefined;
                }

                const transport = this.config.value.getTransport(ticket.transportId);

                if(transport && transport.weightId){
                    ticket.departureId = undefined;
                }

            }
        }

        return this.wizard.navigateToNextStep(this.booking, {
            groupId: this.selectedGroupId
        });
    }

    goNext(){

        return this.wizard.navigateToNextStep(this.booking, {
            groupId: this.selectedGroupId
        });
    }

    getGroupRouteParams(groupId: string) {
        return defaults({
            groupId: groupId
        }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
    }
}

export interface TransportViewModel {
    id: number;
    groupId: string;
}
