import { Router, NavigationInstruction } from "aurelia-router";

export class WizardParams {
    constructor(private router: Router) {
    }

    getFlowGroup(navigationInstruction?: NavigationInstruction): string | undefined {
        return (navigationInstruction || this.router.currentInstruction).queryParams.flowGroup;
    }

    getFinalFlow(navigationInstruction?: NavigationInstruction): number | undefined {
        const flow = (navigationInstruction || this.router.currentInstruction).queryParams.flow;

        if (flow) {
            return Number(flow);
        }
    }

    // setFinalFlow(flowId: number) {
    //     this.router.currentInstruction.queryParams.flow = flowId;
    // }

    // clearFinalFlow() {
    //     delete this.router.currentInstruction.queryParams.flow;
    // }
}