const map: {[groupId: string]: string} = {
    "standard": "default",
    "coupon": "clip",
    "trucks": "commercial"
}

export class TransportGroupClassValueConverter {
    toView(groupId: string, kind: "title" | "button") {
        switch (kind) {
            case "title": return `thumbnails-global-title-${map[groupId]}`;
            case "button": return `button-${map[groupId]}`
        }
    }
}