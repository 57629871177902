import { autoinject, computedFrom } from "aurelia-framework";
import { OpenIdConnect } from "aurelia-open-id-connect";
import { AppConfigApi } from "../api/app-config-api";
import { GuestApi } from "../api/guest-api";
import { MessageModel } from "../models/message-model";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";

@autoinject()
export class CreateAccount {
    userIsAuthenticated: boolean = false;

    email: string = "";
    name: string = ""
    postalCode: string = "";
    city: string = "";
    country: string = "";
    phone: string = "";

    countries!: Country[];
    selectedCountryId!: string;

    nameError: boolean = false;
    postalCodeError: boolean = false;
    cityError: boolean = false;
    phoneError: boolean = false;

    message: MessageModel;

    @computedFrom('phone')
    get phonePrefixValid() {
        console.log('phonePrefixValid');
        if (this.phone !== undefined && this.phone.length > 0 && this.phone.substring(0, 1) !== '+') {
            return false;
        }
        else {
            return true;
        }
    }

    constructor(private oidc: OpenIdConnect, private appConfigApi: AppConfigApi, private guestApi: GuestApi, private i18n:I18N, private router: Router) {
        this.message = new MessageModel('hide', undefined, undefined)
    }

    async activate() {

        const user = await this.oidc.getUser();

        if (user && !user.expired && user.access_token) {
            this.userIsAuthenticated = true;
            console.log("User is authenticated");
        }

        const guest = await this.guestApi.getCurrentUser();
        //  No name if guest not found in database
        if(guest){
            if(guest.name){
                //  User exists already
                this.message.status = "error";
                this.message.header = this.i18n.tr("account:create-guest.message.guest-create-error.header");
                this.message.text = this.i18n.tr("account:create-guest.message.guest-create-error.text");
            }
            else if(guest.mail){
                this.email = guest.mail
            }
        }

        const appConfig = await this.appConfigApi.get();
        this.countries = appConfig.countries;
    }

    async submit() {

        let ok: boolean = true;
        this.nameError = false;
        this.postalCodeError = false;
        this.cityError = false;
        this.phoneError = false;

        if (this.name === undefined || this.name.length < 3) {
            this.nameError = true;
            ok = false;
        }

        if (this.postalCode === undefined || this.postalCode.length < 4) {
            this.postalCodeError = true;
            ok = false;
        }

        if (this.city === undefined || this.city.length < 2) {
            this.cityError = true;
            ok = false;
        }

        if (this.phone !== undefined) {
            this.phone = this.phone.replace(/[^+\d]/g, '').replace("+00", "+").replace("+0", "+");
        }
        if (this.phone === undefined || this.phone.length < 10 || this.phone.substring(0, 1) !== '+') {
            this.phoneError = true;
            ok = false;
        }

        this.country = this.selectedCountryId;

        if (ok) {

            const newGuest = await this.guestApi.createGuest(this.name, this.postalCode, this.city, this.country, this.phone);

            if(newGuest.status === "ok"){
                // this.message.status = "success";
                // this.message.header = this.i18n.tr("my-booking:create-guest.message.guest-created-ok.header");
                // this.message.text = this.i18n.tr("my-booking:create-guest.message.guest-created-ok.text");

                this.router.navigate("/my-booking");
            }
            else if (newGuest.status === 'error'){
                this.message.status = "error";
                this.message.header = this.i18n.tr("account:create-guest.message.guest-create-error.header");
                this.message.text = this.i18n.tr("account:create-guest.message.guest-create-error.text");
            }
            else{
                this.message.status = "hide";
            }
        }
    }
}

export interface Country {
    id: string;
    name: string;
}