import { autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { CouponCodeDto, CouponCodesApi } from "../api/coupons-api";
import { OpenIdConnect } from "aurelia-open-id-connect";
import { LogApi } from "../api/log-api";
import { GuestDto, GuestApi } from "../api/guest-api";
import { AppConfigApi } from "../api/app-config-api";
import { I18N } from "aurelia-i18n";

@autoinject()
export class CouponCodes {
    couponCodes!: CouponCodeDto[];
    user!: GuestDto;
    couponValueSaleUrl!: string;
    couponDiscountSaleUrl!: string;
    couponSubscriptionSaleUrl!: string;
    customCouponsValueTitle!: string | undefined;
    customCouponsValueDescription!: string | undefined;
    customCouponsDiscountTitle!: string | undefined;
    customCouponsDiscountDescription!: string | undefined;

    constructor(i18n: I18N, private router: Router, private oidc: OpenIdConnect, private appConfigApi: AppConfigApi, private guestApi: GuestApi, private couponCodesApi: CouponCodesApi, private logApi: LogApi) {
        this.customCouponsValueTitle = i18n.tr('portal-text:FerryWebAppCouponsValueTitle', {
            defaultValue: i18n.tr('my-booking:coupons.buy-value.title')
        });
        this.customCouponsValueDescription = i18n.tr('portal-text:FerryWebAppCouponsValueDescription', {
            defaultValue: i18n.tr('my-booking:coupons.buy-value.description')
        });
        this.customCouponsDiscountTitle = i18n.tr('portal-text:FerryWebAppCouponsDiscountTitle', {
            defaultValue: i18n.tr('my-booking:coupons.buy-discount.title')
        });
        this.customCouponsDiscountDescription = i18n.tr('portal-text:FerryWebAppCouponsDiscountDescription', {
            defaultValue: i18n.tr('my-booking:coupons.buy-discount.description')
        });  
    }

    async activate() {

        const user = await this.oidc.getUser();

        //const logText = user ? "user.access_token=" + user.access_token : "user=" + user;
        const logText = user && user.access_token.length > 100 ? "user.access_token(first 10)=" + user.access_token.substring(0,99) : "user=" + user;
        await this.logApi.LogSession(logText, "Client_CouponCodes", 3);

        if (user && user.access_token) {
            const guest = await this.guestApi.getCurrentUser();

            if (guest && guest.mail && guest.key) {
                //  User exist in database
                this.user = guest;

                const appConfig = await this.appConfigApi.get();

                this.couponValueSaleUrl = appConfig.couponValueSaleUrl ? appConfig.couponValueSaleUrl + "&guestKey=" + guest.key : "";
                this.couponDiscountSaleUrl = appConfig.couponDiscountSaleUrl ? appConfig.couponDiscountSaleUrl + "&guestKey=" + guest.key : "";
                this.couponSubscriptionSaleUrl = appConfig.couponSubscriptionSaleUrl ? appConfig.couponSubscriptionSaleUrl + "&guestKey=" + guest.key : "";
              
                const couponCodes = await this.couponCodesApi.getByCurrentUser();

                if (couponCodes) {
                    this.couponCodes = couponCodes;
                    await this.logApi.LogSession(couponCodes.length + " couponCodes returned OK to user", "Client_CouponCodes", 3);
                }
                else {
                    await this.logApi.LogSession("Error getting couponCodes, redirect to login", "Client_CouponCodes", 3);
                    await this.oidc.login();
                }
            }
        }
    }

    isValidBarcode(code: string) {
        return code && encodeURIComponent(code) === code;
    }
}