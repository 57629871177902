import { Booking } from "./booking";
import { DateTime } from "luxon";
import { TicketConfigAccessor } from "./ticket-designer/ticket-config";
import { autoinject } from "aurelia-framework";

@autoinject()
export class BookingFactory {
    constructor(private config: TicketConfigAccessor) {
    }

    create(noOfTickets: number) {
        const booking = new Booking(this.config);

        for (let i = 0; i < noOfTickets; i++) {
            booking.addTicket();
        }

        return booking;
    }

    load(tickets: { departureDate: DateTime, transportId: number, trailerId: number, totalWeights: number, passengers: { [id: string]: number }, meals: { [id: string]: number },  fromRegionId: string, toRegionId: string, departureId: number, disabled?: boolean, cancelled?: boolean, waitListPriority?: number }[]) {
        return new Booking(this.config, ...tickets);
    }
}