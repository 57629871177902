import { bindable, bindingMode, computedFrom, autoinject } from "aurelia-framework";
import { Passengers } from "../passengers";
import { TicketConfigAccessor } from "../ticket-config";

@autoinject()
export class PassengerInputCustomElement {
    @bindable()
    id!: number;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    value!: number;

    icon?: string;
    groupId?: string;
    minQty?: number;
    maxQty?: number;


    constructor(private config: TicketConfigAccessor) {
    }

    step(amount: number) {
        this.value += amount;
    }

    idChanged() {
        const passenger = this.config.value.getPassenger(this.id);

        this.icon = passenger.icon;
        this.groupId = passenger.groupId;
        this.minQty = passenger.minQty;
        this.maxQty = passenger.maxQty;
    }
}