import {
    bindable,
    bindingMode,
    customAttribute,
    inject,
} from "aurelia-framework";
import * as intlTelInput from "intl-tel-input";
import { DefaultOptions } from ".";
import "intl-tel-input/build/css/intlTelInput.css";

@customAttribute("intl-tel-input")
export class IntlTelInputCustomAttribute {
    private element: HTMLInputElement;
    private instance!: intlTelInput.Plugin;

    @bindable({ primaryProperty: true, defaultBindingMode: bindingMode.twoWay })
    value?: string;

    @bindable()
    options?: intlTelInput.Options;

    constructor(
        @inject(Element) element: Element,
        @inject(DefaultOptions) private defaultOptions: intlTelInput.Options
    ) {
        this.element = element as HTMLInputElement;
        this.handleChange = this.handleChange.bind(this);
    }

    bind() {
        this.instance = intlTelInput(
            this.element,
            this.options || this.defaultOptions
        );
        if (this.element.classList.contains("form-control")) {
            this.element.parentElement!.classList.add("iti-with-form-control");
        }

        if (this.value) {
            this.instance.setNumber(this.value);
        }

        this.element.addEventListener("countrychange", this.handleChange);
        this.element.addEventListener("keyup", this.handleChange);
        this.element.addEventListener("blur", this.handleChange);
    }

    unbind() {
        this.element.removeEventListener("countrychange", this.handleChange);
        this.element.removeEventListener("keyup", this.handleChange);
        this.element.removeEventListener("blur", this.handleChange);
        this.instance.destroy();
    }

    valueChanged() {
        this.instance.setNumber(this.value!);
    }

    private handleChange() {
        this.value = this.instance.getNumber();
    }
}
