import { isArray } from "lodash-es";

export class MassValueConverter {
    toView(value: number | [number, number] | undefined) {
        if (value === undefined) {
            return "";
        }

        if (isArray(value) && value[0] === -1) {
            value = value[1];
        }

        if (isArray(value)) {
            return `${value[0]}-${value[1]}kg`;
        }
        else {
            return `Op til ${value}kg`;
        }
    }
}