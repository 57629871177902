import { DateTime } from "luxon";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { jsonProperty } from "ur-json";
import { luxonConverter } from "./converters/luxon-converter";
import { Http } from "ur-http";

@autoinject()
export class DeparturesApi {
    constructor(private client: ApiClient) {
    }

    async getDepartures(flowGroup: string | undefined, fromRegion: string, toRegion: string | undefined, date: DateTime, transportId: number, trailerId: number | undefined, totalWeights: number | undefined, passengers: { [id: number]: number } | undefined, editPackageKey: string | undefined) {

        let url = '/departures/days/?flowgroup=' + (flowGroup || "") + '&fromregion=' + fromRegion + '&toregion=' + (toRegion || '') + '&date=' + date.toISODate()
            + '&transportid=' + transportId 
            
            if(trailerId)
                url += '&trailerId=' + trailerId;

            if(totalWeights)
                url += '&totalWeights=' + totalWeights;

            if(passengers){
                for (var key in passengers) {
                    if (passengers.hasOwnProperty(key)) {
                        url = url + '&passengers.' + key + '=' + passengers[key];
                    }
                }
            }

            if(editPackageKey){
                url = url + '&editPackageKey=' + editPackageKey;
            }

        return Http.get(url)
        .expectJsonArray(Departure)
        .transfer();
    }

    async getDepartureBaseInfo(id: number) {
        return Http.get('/departures/' + id + '/baseinfo')
        .expectJson(DepartureBaseInfo)
        .transfer();

    }

    async getMeals(id: number) {

        let url = '/departures/' + id + '/meals';

        return Http.get(url)
        .expectJsonArray(Meal)
        .transfer();
    }

    async checkAvailability(departureId: number, transportId: number, trailerId: number | undefined, totalWeights: number | undefined,  passengers: { [id: string]: number } | undefined, editPackageKey?: string | undefined)  {
        let url = '/departures/' + departureId + '/checkavailability' + '?transportid=' + transportId

        if(trailerId)
            url += '&trailerId=' + trailerId;

        if(totalWeights)
            url += '&totalWeights=' + totalWeights;

        if(passengers){
            for (var key in passengers) {
                if (passengers.hasOwnProperty(key)) {
                    url = url + '&passengers.' + key + '=' + passengers[key];
                }
            }
        }

        if(editPackageKey){
            url = url + '&editPackageKey=' + editPackageKey;
        }

        const response = await this.client.get(url);

        return await response.json() as CheckAvailability;    
    }
}

export class Departure {
    @jsonProperty()
    public id!: number;
    @jsonProperty()
    public group!: string;
    @jsonProperty()
    public fromRegionId!: string;
    @jsonProperty()
    public toRegionId!: string;
    @jsonProperty()
    public header!: string;
    @jsonProperty()
    public icon!: string;
    @jsonProperty()
    public fromPortId!: string;
    @jsonProperty()
    public toPortId!: string;
    @jsonProperty({converter: luxonConverter})
    public departs!: DateTime;
    @jsonProperty({converter: luxonConverter}) 
    public arrives!: DateTime;
    @jsonProperty()
    public note!: string;
    @jsonProperty()
    public amount!: number;
    @jsonProperty()
    public currency!: string;
    @jsonProperty()
    public status!: DepartureStatus;
    @jsonProperty()
    public waitListQty!: number;    
    @jsonProperty()
    public fromRegionWarning: boolean = false;
    @jsonProperty()
    public toRegionWarning: boolean = false;
}

export type DepartureStatus = "ticketsblocked" | "blocked" | "closed" | "warning" | "ok" | "full";

export class DepartureBaseInfo {
    @jsonProperty()
    public id!: number;
    @jsonProperty()
    public fromPortId!: string;
    @jsonProperty()
    public toPortId!: string;
    @jsonProperty()
    public fromRegionId!: string;
    @jsonProperty()
    public toRegionId!: string;
    @jsonProperty({converter: luxonConverter})
    public departs!: DateTime;
    @jsonProperty({converter: luxonConverter})
    public arrives!: DateTime;
    @jsonProperty()
    public icon!: string;
    @jsonProperty()
    public crossing!: string;
}

export interface CheckAvailability{
    isAvailable: boolean;
}

export class Meal{
    @jsonProperty()
    mealId!: number;
    @jsonProperty()
    txt!: string;
    @jsonProperty()
    description!: string;
    @jsonProperty()
    icon!: string;
    @jsonProperty()    
    price!: number; 
    @jsonProperty()    
    possibleIncrease!: boolean;        
}

