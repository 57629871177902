import { I18N } from "aurelia-i18n";
import { Booking } from "../../../booking";
import { MessageModel } from "../../../models/message-model";
import { OrdersApi, BasketDto } from "../../../api/orders-api";
import { autoinject, Disposable } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { BookingSerializer } from "../../../booking-serializer";
import { EditBookingWizard } from "./wizard";
import lowercase from "../../../lowercase";
import config from "../../../../webpack.config";
import { AppConfigApi } from "../../../api/app-config-api";
import { OnPayApi } from "../../../api/onpay-api";
import { EA_I18N_LOCALE_CHANGED } from "../../../locale-service";

@autoinject()
export class basket {
    title!: string;
    booking!: Booking;
    basket!: BasketDto;
    message: MessageModel;
    showOnlyReservation!: boolean;
    showOnlyOnAccount!: boolean;
    onlyReservation!: boolean;
    onlyOnAccount!: boolean;
    hasPaymentGateway!: boolean;
    hasMobilePay!: boolean;
    packageKey!: string;
    nextButtonText!: string;

    showContent: Boolean = true;

    private disposables!: Disposable[];

    constructor(private wizard: EditBookingWizard, private bookingSerializer: BookingSerializer, private i18n: I18N,
        private ordersApi: OrdersApi, private router: Router, private onPayApi: OnPayApi, private appConfigApi: AppConfigApi, private eventAggregator: EventAggregator){
        this.message = new MessageModel('hide', undefined, undefined);
    }
    
    async activate(params: {packagekey: string, booking: string }) {
        params = lowercase(params);
        
        this.packageKey = params.packagekey;

        this.booking = this.bookingSerializer.deserialize(params.booking);

        const baseInfo = await this.ordersApi.getBaseInfo(this.packageKey);
        this.title = this.i18n.tr("my-booking:order.title", { orderId: baseInfo.orderId });

        //this.basket = await this.ordersApi.preview(this.booking, params.packagekey)

        const appConfig = await this.appConfigApi.get();
        this.hasPaymentGateway = appConfig.paymentWindow.length > 0;
        this.hasMobilePay = appConfig.defaultPayMethod == "Select" || appConfig.defaultPayMethod == "MobilePay";

        await this.loadBasket();

        //  Get from booking if already set
        this.onlyReservation = this.booking.onlyReservation !== undefined ? this.booking.onlyReservation : false;

        this.onlyOnAccount = this.booking.onlyOnAccount !== undefined ? this.booking.onlyOnAccount : false;

        this.setButtonText();
    }

    bind() {
        this.disposables = [
            this.eventAggregator.subscribe(EA_I18N_LOCALE_CHANGED, () => this.loadBasket()),
        ];
    }

    unbind() {
        for (const disposable of this.disposables) {
            disposable.dispose();
        }
    }

    private async loadBasket() {
        this.basket = await this.ordersApi.preview(this.booking, this.packageKey)

       //  if something payed with coupon or card the rest have to be payed too due til BEAS ticket terminals
       this.showOnlyReservation = this.basket.totalOnAccount === 0 && this.basket.totalNet > 0 
       && this.basket.totalCoupon === 0 && this.basket.totalPrePayed === 0
       && this.hasPaymentGateway;

       //  We dont support OnAccount when working with CouponCode
       this.showOnlyOnAccount  = this.basket.totalOnAccount < this.basket.totalNet 
       && this.basket.totalOnAccount > 0 && this.basket.totalCoupon === 0;
    }



    async toggleOnlyReservation(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.onlyReservation = !this.onlyReservation;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    async toggleOnlyOnAccount(e: any) {

        if(e.code == "Space" || e.code == "Enter"){
            this.onlyOnAccount = !this.onlyOnAccount;           
        }
        else if(e.code == "Tab")
        {   //  loose focus
            e.srcElement.blur();;
        }

        return true;
    }

    onlyReservationChanged(value: boolean){
        this.setButtonText();
    }

    async goNext() {

        this.showContent = false;

        this.booking.onlyReservation = this.onlyReservation;
        this.booking.onlyOnAccount = this.onlyOnAccount;

        const noPayment = !(!this.onlyReservation && this.basket.totalNet > 0 && this.hasPaymentGateway);

        let updatedOrder = await this.ordersApi.update(this.booking, noPayment, this.basket.currency, this.packageKey);

        if (updatedOrder === "fullybooked") {
            this.message.header = this.i18n.tr("my-booking:order.message.booking-fullybooked.header");
            this.message.text = this.i18n.tr("my-booking:order.message.booking-fullybooked.text");            
            this.message.status = "error";

            this.showContent = true;
        }
        else if(updatedOrder === "closed"){
            this.message.header = this.i18n.tr("my-booking:order.message.booking-closed.header");
            this.message.text = this.i18n.tr("my-booking:order.message.booking-closed.text");            
            this.message.status = "error";

            this.showContent = true;
        }
        else {
            if (noPayment) {

                const newUrl = "https://" + window.location.host + "/my-booking/bookings/" + updatedOrder.packagekey + "/receipt-no-payment" + "?lang=" + this.i18n.getLocale();

                window.location.href = newUrl;
            }
            else {
                const appConfig = await this.appConfigApi.get();

                if(appConfig.paymentWindow == "OnPay"){

                    // const amountCurrency = this.booking.onlyOnAccount ? this.basket.totalOnAccount : this.basket.totalNet;
                    // const amountCurrencyInt = Math.round(amountCurrency * 100);

                    await this.onPayApi.PostOnPay(updatedOrder.packagekey, this.booking.onlyOnAccount, undefined);
                }
            }
        }
    }

    setButtonText(){

        const tickets = this.booking.getTickets();

        if(!this.onlyReservation && this.basket.totalNet > 0 && this.hasPaymentGateway){
            this.nextButtonText = this.i18n.tr("my-booking:order.next-button-pay");
        }
        else if(tickets.length === (tickets.filter(x => x.cancelled).length + tickets.filter(x => x.disabled).length)){
            this.nextButtonText = this.i18n.tr("my-booking:order.next-button-cancel");
        }
        else{
            this.nextButtonText = this.i18n.tr("my-booking:order.next-button-confirm");
        }
    }

}
