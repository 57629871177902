import { autoinject, bindable, computedFrom } from "aurelia-framework";

import { Router } from "aurelia-router";
import { TicketConfigAccessor } from "../ticket-config";
import { defaults } from "lodash-es";

@autoinject()
export class TransportSummaryCustomElement {
    @bindable()
    transportId!: number;

    @bindable()
    href?: string;

    @bindable()
    ticketIndex?: number;

    @bindable()
    selected = false;

    icon?: string;
    height?: number | [number, number];
    length?: number | [number, number];
    weight?: number;

    constructor(private router: Router, private config: TicketConfigAccessor) {
    }

    attached() {
        // Autogenerate url href
        if (!this.href && typeof this.ticketIndex === "number" && this.ticketIndex >= 0) {
            const params = defaults({
                ticketIndex: this.ticketIndex
            }, this.router.currentInstruction.params, this.router.currentInstruction.queryParams);
        
            this.href = this.router.generate("transport", params);
        }
    }

    transportIdChanged() {
        const transport = this.config.value.getTransport(this.transportId);

        this.icon = transport.icon;
        this.height = transport.height;
        this.length = transport.length;
        this.weight = transport.weight;
    }
}