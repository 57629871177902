import { TicketConfig } from "../ticket-designer/ticket-config";
import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";

@autoinject()
export class TicketConfigApi {
    constructor(private client: ApiClient) {

    }
    async get() {
        const response = await this.client.get("/ticketconfig");
        const json = await response.json();

        const config = new TicketConfig(json);

        return config;
    }
}