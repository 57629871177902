import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { Crossing } from "../crossings-builder";

@autoinject()
export class TransportsApi {
    constructor(private client: ApiClient) {
    }

    async getTransportsByFlowSteps(crossings: Crossing[]) {

        const query: {
            crossings: Crossing[]} = {
            crossings: crossings
            };

        let url = '/transports/byflowsteps';

        const response = await this.client.post(url, JSON.stringify(query));

        if (response.status != 200) {
            throw Error("Error fetching additionalFields");
        }

        const resp = await response.json() as number[];

        return resp;
    }

    async getTransportsByFlowStep(fromRegionId: string, toRegionId: string) {

        let url = '/transports/byflowstep?fromRegionId=' + fromRegionId + '&toRegionId=' + toRegionId;

        const response = await this.client.get(url);

        if (response.status != 200) {
            throw Error("Error fetching additionalFields");
        }

        const resp = await response.json() as number[];

        return resp;
    }
   
}



