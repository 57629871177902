import { DateTime } from "luxon";
import { EditBookingWizard } from "./wizard";
import { Booking } from "../../../booking";
import { BookingFactory } from '../../../booking-factory';
import { autoinject } from "aurelia-framework";
import { TicketsSummaryApi } from "../../../api/tickets-summary-api";
import { TicketConfigApi } from "../../../api/ticket-config-api";
import { RouteConfig, NavigationInstruction, Router} from "aurelia-router";
import { DeparturesApi } from "../../../api/departures-api";
import { OrdersApi } from "../../../api/orders-api";
import { I18N } from "aurelia-i18n";
import { BookingSerializer } from "../../../booking-serializer";
import lowercase from "../../../lowercase";
import { TicketConfig } from "../../../ticket-designer/ticket-config";
import { MessageModel } from "../../../models/message-model";
import { AppConfigApi } from "../../../api/app-config-api";


@autoinject()
export class Summary {
    title!: string;
    private booking!: Booking;
    private ticketConfig!: TicketConfig;
    tickets: TicketsSummaryViewModel[] = [];
    orderId!: number;
    packagekey!: string;
    totalAmount!: number;
    currency!: string;
    ticketIsValidAllDay!: boolean;
    message: MessageModel;

    constructor(private wizard: EditBookingWizard, private bookingFactory: BookingFactory, private router: Router, private i18n: I18N,
        private ordersApi: OrdersApi, private ticketSummaryApi: TicketsSummaryApi, private ticketConfigApi: TicketConfigApi, private departuresApi: DeparturesApi, 
        private bookingSerializer: BookingSerializer, private appConfigApi: AppConfigApi ) {
            this.message = new MessageModel('hide', undefined, undefined)
    }

    async activate(params: { packagekey: string, booking: string }, _config: RouteConfig, navigationInstruction: NavigationInstruction) {
        params = lowercase(params);

        const infoMessage =  this.i18n.tr('portal-text:FerryWebAppSummaryInfoMessage');

        //  Key is returned if value is missing
        if(infoMessage && infoMessage.indexOf(" ") > 0){
            this.message.status = 'info';
            this.message.text = infoMessage;
        }
        
        this.booking = this.bookingSerializer.deserialize(params.booking);
        const selectedTickets = this.booking.getTickets();
        this.packagekey = params.packagekey;

        const baseInfo = await this.ordersApi.getBaseInfo(this.packagekey);

        if (!baseInfo || baseInfo.status == 'deleted' || baseInfo.status == 'temp') {
            throw Error("Baseinfo not found from packagekey");
        }

        this.title = this.i18n.tr("my-booking:summary.title", { orderId: baseInfo.orderId });
        this.orderId = baseInfo.orderId;

        const appConfig = await this.appConfigApi.get();
        this.ticketIsValidAllDay = appConfig.ticketIsValidAllDay;

        this.ticketConfig = await this.ticketConfigApi.get();

        const summary = await this.ticketSummaryApi.getTicketsSummary(this.booking, params.packagekey);

        for (let ticket of selectedTickets) {

            const ticketSummary = summary.ticketsSummary.find(x => x.departureId === ticket.departureId);

            if (ticket.departureId) {

                const departureBaseInfo = await this.departuresApi.getDepartureBaseInfo(ticket.departureId);

                if (ticket.fromRegionId && ticket.transportId && (ticket.trailerId || ticket.passengers) && ticket.departureId) {

                    this.tickets.push({
                        departureDate: ticket.departureDate,
                        transportId: ticket.transportId,
                        trailerId: ticket.trailerId,
                        totalWeights: ticket.totalWeights,
                        passengers: ticket.passengers,
                        fromRegionId: ticket.fromRegionId,
                        toRegionId: ticket.toRegionId,
                        departureId: ticket.departureId,
                        crossingIcon: departureBaseInfo.icon,
                        amount: ticketSummary ? ticketSummary.amount : 0,
                        disabled: !!ticket.disabled,
                        cancelled: !!ticket.cancelled,
                        isMenuOnly: this.ticketConfig.getTransport(ticket.transportId).isMenuOnly
                    });
                }
            }
        }

        let totalAmount = 0;
        summary.ticketsSummary.forEach(element => {
            totalAmount += element.amount;
        });

        this.totalAmount = totalAmount;
        this.currency = summary.currency;
    }

    showTrailer(ticketIndex: number){
        if (ticketIndex >= 0) {

            const ticket = this.tickets[ticketIndex];

            return this.ticketConfig.getTransport(ticket.transportId).enabledTrailerIds.length > 1;
        }

        return false;
    }

    cancel(ticketIndex: number) {
        this.tickets[ticketIndex].cancelled = !this.tickets[ticketIndex].cancelled;
    }

    async confirm() {

        const contactInfo = await this.ordersApi.getContactInfo(this.packagekey);

        if (contactInfo) {

            this.booking.email = contactInfo.mail;
            this.booking.name = contactInfo.name;
            this.booking.postalCode = contactInfo.postalCode;
            this.booking.city = contactInfo.city;
            this.booking.country = contactInfo.country;
            this.booking.phone = contactInfo.phone;
            this.booking.carRegistration = contactInfo.carRegistration;
            this.booking.couponCode = contactInfo.couponCode;
            this.booking.note = contactInfo.note;
  
            return this.wizard.navigateToRoute("meals", this.booking);
        }
    }

    isToday(dateTime: DateTime) {
        return dateTime.hasSame(DateTime.local(), "day");
    }
}

export interface TicketsSummaryViewModel {
    departureDate: DateTime;
    transportId: number;
    trailerId: number | undefined;
    totalWeights: number | undefined;
    passengers: { [id: string]: number } | undefined;
    fromRegionId: string;
    toRegionId: string | undefined;
    departureId: number;
    crossingIcon: string;
    amount: number;
    isMenuOnly: boolean;
    disabled: boolean;
    cancelled: boolean;
}
