import { autoinject, bindable } from "aurelia-framework";
import { AppConfigApi } from "../../api/app-config-api";

import { TicketConfigAccessor } from "../ticket-config";

@autoinject()
export class TransportItemCustomElement {
    @bindable()
    value!: number;
    
    @bindable()
    selected = false;

    @bindable()
    disabled = false;

    icon?: string;
    height?: number | [number, number];
    length?: number | [number, number];
    weight?: number;

    constructor(private config: TicketConfigAccessor, private appConfigApi: AppConfigApi) {
    }

    valueChanged() {
        const transport = this.config.value.getTransport(this.value);

        this.icon = transport.icon;
        this.height = transport.height;
        this.length = transport.length;
        this.weight = transport.weight;
    }

    click(event: Event) {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
}