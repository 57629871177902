import { ApiClient } from "./api-http-client";
import { autoinject } from "aurelia-framework";
import { jsonProperty } from "ur-json";
import { Http } from "ur-http";

@autoinject()
export class MessageApi {

    constructor(private client: ApiClient) { }

    async getMessage(group: string, alias: string) {
        const url =  '/messages/group/' + group + '/alias/'+ alias;

        return Http.get(url)
            .expectJson(MessageDto)
            .transfer();
    }
}

export class MessageDto {
    @jsonProperty()
    text!: string;
}