import { autoinject } from "aurelia-framework";
import { ApiClient } from "./api-http-client";
import { DateTime } from "luxon";
import { jsonProperty } from "ur-json";
import { Http } from "ur-http";
import { luxonConverter } from "./converters/luxon-converter";



@autoinject()
export class AddInfoApi {
    constructor(private client: ApiClient) {
    }
        async getFields(packagekey: string) {
            return Http.get('/addinfo/' + packagekey + '/fields')
                .expectJson(AddInfoFieldsDto)
                .transfer();
        }

        async updateTxt(addInfoFieldTxts: AddInfoFieldTxt[], packagekey: string) {

            const command: {
                addInfoFieldTxts: AddInfoFieldTxt[]} = {
                    addInfoFieldTxts: addInfoFieldTxts
            };
            const url = '/addinfo/' + packagekey + '/update';
            const response = await this.client.post(url, JSON.stringify(command));
        }

        async getStatus(packagekey: string) {
            return Http.get('/addinfo/' + packagekey + '/status')
                .expectJson(AddInfoStatusDto)
                .transfer();
        }
    }
    
    export class AddInfoField {
        @jsonProperty()
        fieldId!: number;
        @jsonProperty()
        abbrev!: string;      
        @jsonProperty()
        minLength!: number;
        @jsonProperty()
        isReadOnly!: boolean;
        @jsonProperty()
        txt: string | undefined;
        hasError: boolean = false;
    } 

    export class AddInfoFieldGroup{
        @jsonProperty()
        fields!: AddInfoField[];
    }

    export class AddInfoDirection {
        @jsonProperty()
        fromPort!: string;
        @jsonProperty()
        toPort!: string;      
        @jsonProperty({converter: luxonConverter})
        depart!: DateTime;
        @jsonProperty({type: AddInfoFieldGroup})
        fieldGroups!: AddInfoFieldGroup[];
    } 

    export class AddInfoFieldsDto{
        @jsonProperty({type: AddInfoDirection})
        directions!: AddInfoDirection[];
    }

    export class AddInfoStatusDto {
        @jsonProperty()
        isOnTickets!: boolean;
        @jsonProperty()
        isFullFilled!: boolean;      
    } 

    export class AddInfoFieldTxt {
        fieldId!: number;
        txt!: string | undefined;
    }