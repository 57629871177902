import { ITicketWizard, TicketWizardKey } from './ticket-wizard';
import { autoinject, inject } from 'aurelia-framework';
import { Booking } from '../booking';
import { BookingSerializer } from '../booking-serializer';
import { Router } from 'aurelia-router';
import { DeparturesApi } from '../api/departures-api';
import { TicketConfigApi } from '../api/ticket-config-api';

@autoinject()
export class TransportExtra {

    booking!: Booking;
    private ticketIndex!: number;    
    minWeight!: number;
    weightId!: number | undefined;
    weightIconUrl: string | undefined;
    totalWeights!: number;
    canSubmit = false;
    packageKey!: string | undefined;

    constructor(@inject(TicketWizardKey) private wizard: ITicketWizard, private bookingSerializer: BookingSerializer, private router: Router,
        private departureApi: DeparturesApi, private ticketConfigApi: TicketConfigApi) {
    }

    async activate(params: {packagekey?: string, booking: string, ticketIndex: string }) {

        this.packageKey = params.packagekey;

        this.booking = this.bookingSerializer.deserialize(params.booking);
        this.ticketIndex = parseInt(params.ticketIndex);
        const ticket = this.booking.getTicket(this.ticketIndex);

        if (!ticket.transportId) {
            throw new Error("There is no transport specified for the ticket");
        }

        const ticketConfig = await this.ticketConfigApi.get();

        this.minWeight = ticketConfig.getTransport(ticket.transportId).minWeight;
        this.weightId = ticketConfig.getTransport(ticket.transportId).weightId;
        this.weightIconUrl = "https://tbferry.blob.core.windows.net/icons/tickets/default/" + ticketConfig.getTransport(ticket.transportId).weightIcon + "1-10.svg";

        //  We only reach this page if minWeight > -1
        this.totalWeights = ticket.totalWeights !== undefined ? ticket.totalWeights : this.minWeight;

        //  Not nescessary!!!!!!!!!!!!!
        this.canSubmit = true; // this.totalWeights > -1;

    }

    step(value: number){
        this.totalWeights += value;
        if(this.totalWeights < this.minWeight)
            this.totalWeights = this.minWeight;

        this.canSubmit = this.totalWeights >= this.minWeight;
    }

    async next(){

        const ticket = this.booking.getTicket(this.ticketIndex);

        const totalWeightHasChanged = ticket.totalWeights !== this.totalWeights;

        ticket.totalWeights = this.totalWeights;

        if (totalWeightHasChanged && ticket.departureId && ticket.transportId && !ticket.waitListPriority) {

            const checkAvailability = await this.departureApi.checkAvailability(ticket.departureId, ticket.transportId, ticket.trailerId, ticket.totalWeights, ticket.passengers, this.packageKey);

            if (!checkAvailability.isAvailable) {
                ticket.departureId = undefined;
            }
        }

        return this.wizard.navigateToNextStep(this.booking);
    }

}
