import { OpenIdConnectConfiguration } from "aurelia-open-id-connect";
import { PLATFORM } from "aurelia-framework";
import { WebStorageStateStore, Log } from "oidc-client";

const appHost = PLATFORM.location.origin;
const postLogoutRedirectUri = `${appHost}/signout-oidc`;

export default function getConfig(tenantId: string, clientId: string): OpenIdConnectConfiguration {
    return {
        loginRedirectRoute: "/account",
        logoutRedirectRoute: "/",
        unauthorizedRedirectRoute: "/",
        logLevel: Log.DEBUG,
        userManagerSettings: {
            authority: `https://${tenantId}.auth0.com`,
            client_id: clientId,
            response_type: "id_token token",
            scope: "openid profile email",
            redirect_uri: `${appHost}/signin-oidc`,
            post_logout_redirect_uri: postLogoutRedirectUri,
            loadUserInfo: false,
            stateStore: new WebStorageStateStore({
                prefix: `oidc-${tenantId}`,
                store: window.localStorage
            }),

            // Custom metadata is needed because auth0 does not include end_session_endpoint in .well-known
            // We therefore have to set all the endpoints manually
            metadata: {
                issuer: `https://${tenantId}.auth0.com/`,
                authorization_endpoint: `https://${tenantId}.auth0.com/authorize`,
                userinfo_endpoint: `https://${tenantId}.auth0.com/userinfo`,
                end_session_endpoint: `https://${tenantId}.auth0.com/v2/logout?returnTo=${encodeURIComponent(postLogoutRedirectUri)}`,
                jwks_uri: `https://${tenantId}.auth0.com/.well-known/jwks.json`,
            },

            extraQueryParams: {
                audience: "api://ferry"
            },
            
            silent_redirect_uri: `${appHost}/signin-oidc`,
            automaticSilentRenew: true,
            monitorSession: true
        }
    };
}