import { bindable, autoinject } from "aurelia-framework";

@autoinject()
export class FlowItemCustomElement {
    @bindable()
    value?: number;

    get imageUrl() {
        // return require(`./flows/${this.value}.png`);
        return "https://tbferry.blob.core.windows.net/icons/routes/default/" + this.value + ".png";
    }
}