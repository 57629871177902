import { autoinject } from "aurelia-framework";
import { OpenIdConnect } from "aurelia-open-id-connect";
import { Redirect, RedirectToRoute } from "aurelia-router";
import { GuestApi } from "../api/guest-api";
import { LogApi } from "../api/log-api";
import { LocaleService } from "../locale-service";

@autoinject()
export class Dispatch {
    constructor(private oidc: OpenIdConnect, private guestApi: GuestApi, private logApi: LogApi, private localeService: LocaleService) {
    }

    async canActivate() {

        const user = await this.oidc.getUser();

        if (!user || !user.profile) {
            console.log("Redirect to login");
            await this.logApi.LogSession("!user || !user.profile, Redirect to login", "Client_Dispatch", 3);
            return this.oidc.login();
        }

        if (!user.profile.email) {
            await this.logApi.LogSession("!user.profile.email, Throw error: There is no email in the profile", "Client_Dispatch", 5);
            throw new Error("There is no email in the profile");
        }

        if (!user.profile.email_verified) {
            console.log("Redirect to unverified");
            await this.logApi.LogSession("!user.profile.email_verified, Redirect to unverified", "Client_Dispatch", 3);
            return new RedirectToRoute("unverified");
        }

        const guest = await this.guestApi.getCurrentUser();

        //  Mail = "" if user.email not found on server! -> logout
        //  Name = "" if guest not found in database
        if(guest && guest.mail && guest.name){
            console.log("User exist in database, Redirect to my-booking");
            await this.logApi.LogSession("guest && guest.mail && guest.name, User exist in database, Redirect to my-booking", "Client_Dispatch", 3);

            this.localeService.setLanguage(guest.language);

            return new Redirect("/my-booking");
        }
        else if(guest && !guest.mail){
            await this.logApi.LogSession("guest && !guest.mail, redirect to logout", "Client_Dispatch", 3);
            return this.oidc.logout();
        }
        else{
            console.log("User don't exist, Redirect to create");
            await this.logApi.LogSession("User don't exist, Redirect to create", "Client_Dispatch", 3);
            return new RedirectToRoute("create");
        }
    }
}
